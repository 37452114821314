export interface ICenttripAccount {
  id: string;
  name: string;
}

export interface ICenttripAccountBalance {
  currency: string;
  amount: number;
}

const getAccounts = () =>
  fetch(`${process.env.REACT_APP_BACKEND_URL}/centtrip/accounts`, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: { "Content-Type": "application/json" },
  }).then((res) => (res.ok ? res.json() : Promise.reject(res)));

const getAccountBalances = (accountId: string) =>
  fetch(
    `${process.env.REACT_APP_BACKEND_URL}/centtrip/accounts/${accountId}/balances`,
    {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    },
  ).then((res) => (res.ok ? res.json() : Promise.reject(res)));

const payInvoice = (accountId: string, invoiceId: number) =>
  fetch(
    `${process.env.REACT_APP_BACKEND_URL}/centtrip/accounts/${accountId}/invoices/${invoiceId}/pay`,
    {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    },
  ).then((res) => (res.ok ? res.json() : Promise.reject(res)));

export { getAccounts, getAccountBalances, payInvoice };
