import React from "react";
import ContactsList from "../../components/entity/contacts/ContactsList";

import { Default } from "../../layouts";

const Contacts = () => {
  return (
    <Default>
      <ContactsList />
    </Default>
  );
};

export default Contacts;
