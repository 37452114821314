export interface IRoles {
  value: string;
  label: string;
}

const roles: { content: Array<IRoles> } = {
  content: [
    { value: "Contact Person", label: "Contact Person" },
    { value: "Legal Representative", label: "Legal Representative" },
    { value: "Shareholder", label: "Shareholder" },
    { value: "Beneficial Owner", label: "Beneficial Owner" },
    { value: "Investor Representative", label: "Investor Representative" },
    { value: "Other", label: "Other" },
  ],
};

export const getRoles: () => Promise<{ content: Array<IRoles> }> = () =>
  Promise.resolve(roles);
