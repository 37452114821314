import React, { useReducer, useMemo, useEffect, useContext } from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";

import { getCurrentUserDetails } from "../../services/user";
import Loader from "../Loader";

const UserContext: React.Context<any> = React.createContext(null);

const ACTION_LOADED = Symbol("Loaded");

interface UserContextProps {
  loaded: boolean;
  user: { email: string; fullname: string } | null;
  tenant: { name: string } | null;
}

const initialState: UserContextProps = {
  loaded: false,
  user: null,
  tenant: null,
};

const reducer = (
  state: UserContextProps,
  action: { type: symbol; payload: Omit<UserContextProps, "loaded"> },
): UserContextProps => {
  switch (action.type) {
    case ACTION_LOADED:
      return {
        ...action.payload,
        loaded: true,
      };
    default:
      return state;
  }
};

const UserProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const contextValue = useMemo(() => {
    const { user, tenant } = state;

    return { user, tenant };
  }, [state]);

  useEffect(() => {
    getCurrentUserDetails()
      .then((res) => {
        if (res?.user && res?.tenant) {
          dispatch({
            type: ACTION_LOADED,
            payload: res,
          });
        } else {
          dispatch({
            type: ACTION_LOADED,
            payload: {
              user: null,
              tenant: null,
            },
          });
        }
      })
      .catch(() => {
        dispatch({
          type: ACTION_LOADED,
          payload: {
            user: null,
            tenant: null,
          },
        });
      });

    return () => undefined;
  }, []);

  return (
    <UserContext.Provider value={contextValue}>
      {state.loaded ? children : <Loader />}
    </UserContext.Provider>
  );
};

interface PrivateRouteProps extends RouteProps {
  roles: Array<string>;
}

const PrivateRoute = (props: PrivateRouteProps) => {
  const { user } = useContext(UserContext);

  const { roles, component, ...rest } = props;
  const Component = component as React.ComponentType<any>;

  return (
    <Route
      {...rest}
      render={() =>
        user ? (
          roles.length === 0 || roles.includes(user.role || "Authenticated") ? (
            <Component />
          ) : (
            <Redirect to={{ pathname: "/forbidden" }} />
          )
        ) : (
          <Redirect to={{ pathname: "/" }} />
        )
      }
    ></Route>
  );
};

interface HasRolesProps {
  roles: string | Array<string>;
}

const HasRole: React.FC<HasRolesProps> = ({ roles, children }) => {
  const { user } = useContext(UserContext);

  if (!user) {
    return null;
  }

  const hasRole = Array.isArray(roles)
    ? roles.includes(user.role)
    : user.role === roles;

  return hasRole ? <>{children}</> : null;
};

export { UserContext, UserProvider, PrivateRoute, HasRole };
