import { criteriaQuery } from "../utils/query";
import {
  IDataSourceFetchFunc,
  IDataSourceRequest,
} from "./../hooks/useDataSource";

export interface IBank {
  id?: string;
  name?: string;
  tags?: string;
  comment?: string;
  address?: string;
  country?: string;
  bicSwift?: string;
  coBankAccountName?: string;
  coBicSwift?: string;
  groups?: string;
  noOfBankAccounts?: number;
}

export const getBanks: IDataSourceFetchFunc<IBank> = (
  req: IDataSourceRequest,
) =>
  fetch(`${process.env.REACT_APP_BACKEND_URL}/banks/?${criteriaQuery(req)}`, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: { "Content-Type": "application/json" },
  }).then((res) => (res.ok ? res.json() : Promise.reject(res)));

export const createBank: (banks: any) => Promise<IBank> = (bank) =>
  fetch(`${process.env.REACT_APP_BACKEND_URL}/banks/`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    mode: "cors",
    credentials: "include",
    body: JSON.stringify(bank),
  }).then((res) => (res.ok ? res.json() : Promise.reject(res)));

export const saveBank: (bank: IBank) => Promise<IBank> = (bank) =>
  fetch(`${process.env.REACT_APP_BACKEND_URL}/banks/${bank.id}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    mode: "cors",
    credentials: "include",
    body: JSON.stringify(bank),
  }).then((res) => (res.ok ? res.json() : Promise.reject(res)));

export const deleteBank: (company: IBank) => Promise<void> = (bank) =>
  fetch(`${process.env.REACT_APP_BACKEND_URL}/banks/${bank.id}/delete`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    mode: "cors",
    credentials: "include",
  }).then((res) => (res.ok ? Promise.resolve() : Promise.reject(res)));

export const exportCsv = () =>
  fetch(`${process.env.REACT_APP_BACKEND_URL}/banks/csv`, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: { "Content-Type": "application/octet-stream" },
  }).then((res) => (res.ok ? res.arrayBuffer() : Promise.reject(res)));
