export interface IScope {
  label: string;
  value: string;
}

export const getScopes: () => Promise<any> = () =>
  Promise.resolve({
    content: [
      { label: "Validation", value: "validation" },
      { label: "Information", value: "information" },
      { label: "Execution", value: "execution" },
    ],
  });
