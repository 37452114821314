import { Button, Space, Tooltip } from "antd";
import {
  AuditOutlined,
  CheckOutlined,
  CloseOutlined,
  DiffOutlined,
  PauseOutlined,
  PlayCircleOutlined,
  SearchOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const transitions: Record<string, Array<string | null>> = {
  validate: [null, "pre_approved", "on_hold", "rejected", null],
  submit_validate: [null, "pre_approved", "on_hold", "rejected", null],
  pre_approved: [null, "approved", "on_hold", "rejected", null],
  approved: [null, "payment_executed", null, "payment_rejected", null],
  payment_sent: [null, "payment_executed", null, null, null],
  payment_executed: [null, null, null, null, null],
  automatic: [null, null, null, null, null],
  on_hold: [null, "validate", "under_investigation", "rejected", null],
  under_investigation: [
    "under_investigation",
    "validate",
    null,
    "rejected",
    null,
  ],
  rejected: [null, "validate", "on_hold", null, "duplicate"],
  payment_rejected: [null, "validate", "on_hold", null, "duplicate"],
  duplicate: [null, "validate", "on_hold", null, "duplicate"],
};

const InvoiceActions = ({
  row,
  onAction,
  onAudit,
  onDelete = () => {},
  gridType,
}: {
  row: any;
  onAction: (row: any, action: string) => void;
  onAudit: (row: any) => void;
  onDelete?: (row: any) => void;
  gridType: string;

  statusList: any;
}) => {
  const [action1, action2, action3, action4, action5] =
    transitions[row.status] || [];
  const { t } = useTranslation();

  return (
    <>
      <Space size="small">
        {action1 && (
          <Tooltip placement="bottomRight" title={t(`${gridType}.${action1}`)}>
            <Button
              disabled={row.status === "under_investigation"}
              aria-label={t(`${gridType}.${action1}`)}
              onClick={() => onAction(row, action1)}
              icon={
                row.status === "under_investigation" ? (
                  <SearchOutlined />
                ) : (
                  <PlayCircleOutlined />
                )
              }
            />
          </Tooltip>
        )}

        {action2 && (
          <Tooltip placement="bottomRight" title={t(`${gridType}.${action2}`)}>
            <Button
              type="text"
              aria-label={t(`${gridType}.${action2}`)}
              onClick={() => onAction(row, action2)}
              icon={<CheckOutlined />}
              style={{
                color: "#06D6A0",
              }}
            />
          </Tooltip>
        )}

        {action3 && (
          <Tooltip placement="bottomRight" title={t(`${gridType}.${action3}`)}>
            <Button
              type="text"
              aria-label={t(`${gridType}.${action3}`)}
              onClick={() => onAction(row, action3)}
              icon={<PauseOutlined />}
              style={{
                color: "#FFD166",
              }}
            />
          </Tooltip>
        )}

        {action4 && (
          <Tooltip placement="bottomRight" title={t(`${gridType}.${action4}`)}>
            <Button
              type="text"
              aria-label={t(`${gridType}.${action4}`)}
              onClick={() => onAction(row, action4)}
              icon={<CloseOutlined />}
              style={{
                color: "#ff6f69",
              }}
            />
          </Tooltip>
        )}

        {action5 && (
          <Tooltip placement="bottomRight" title={t(`${gridType}.${action5}`)}>
            <Button
              type="text"
              aria-label={action5}
              onClick={() => onAction(row, action5)}
              icon={<DiffOutlined />}
            />
          </Tooltip>
        )}

        {row.status === "new" && (
          <Tooltip placement="bottomRight" title="Delete">
            <Button
              type="text"
              aria-label="Delete"
              onClick={() => onDelete(row.id)}
              icon={<DeleteOutlined />}
            />
          </Tooltip>
        )}

        <Tooltip placement="bottomRight" title="Audit">
          <Button
            type="text"
            aria-label="Audit"
            onClick={() => onAudit(row.id)}
            icon={<AuditOutlined />}
          />
        </Tooltip>
      </Space>
    </>
  );
};

export default InvoiceActions;
