import React, { useState, useCallback, useContext } from "react";

import { Button, Collapse, PageHeader } from "antd";

import useRefresh from "../../../hooks/useRefresh";
import { DownloadOutlined } from "@ant-design/icons";

import {
  IInvoice,
  processInvoice,
  exportCsv,
} from "../../../services/invoices";

import ApprovedInvoices from "./ApprovedInvoices";
import OnHoldInvoices from "./OnHoldInvoices";
import RejectedInvoices from "./RejectedInvoices";
import SendMailForm from "../../SendMailForm";
import { TableRowSelection } from "antd/lib/table/interface";
import { UserContext } from "../../../components/auth";
import { currentDateAsString, hasPermission } from "../../../utils/helpers";
import { DictsContext } from "../../../hooks/useDictionaries";
import { IContact } from "../../../services/contacts";
import { IEmailTemplate } from "../../../services/email-templates";

interface ValidatedInvoicesListProps {}

const StatusProgressInvoicesList: React.FC<ValidatedInvoicesListProps> = () => {
  const { dicts } = useContext(DictsContext);

  const { user } = useContext(UserContext);
  const { role } = user;
  // const [selectedInvoice] = useState<IInvoice | null>(null);
  const [token, refresh] = useRefresh();
  // const { reload } = useDatasource<IInvoice>(getInvoicesByStatus("validate"));
  // const [, modalProps] = useModal();
  const { contacts, templates } = dicts;

  const { Panel } = Collapse;
  const [selectedRows, setSelectedRows] = useState<Array<React.Key>>([]);

  const handleMailSent = useCallback(() => {
    Promise.allSettled(
      selectedRows.map((id) =>
        processInvoice({ id: id as number }, "submit_validate"),
      ),
    )
      .then(() => setSelectedRows([]))
      .then(refresh());
  }, [selectedRows, refresh]);

  const rowSelection: TableRowSelection<IInvoice> = {
    selectedRowKeys: selectedRows,
    columnTitle: <>Mail</>,
    onChange: (selectedRowKeys: React.Key[]) => {
      setSelectedRows(selectedRowKeys);
    },
  };

  const to = contacts.content.filter((contact: IContact) => {
    const roles = contact?.scope?.split(",");
    const acceptedRoles = roles.filter(
      (roleStr: string) => roleStr !== "validation",
    );
    return acceptedRoles.length > 0;
  });

  const cc = contacts.content.filter((contact: IContact) => {
    const roles = contact?.scope?.split(",");
    const acceptedRoles = roles.filter(
      (roleStr: string) => roleStr !== "validation",
    );
    return acceptedRoles.length > 0;
  });

  const filteredEmailTemplates = templates.content.filter(
    (emailTemplate: IEmailTemplate) => {
      const roles = emailTemplate?.scope?.split(",") as string[];
      const acceptedRoles = roles.filter(
        (roleStr: string) => roleStr !== "validation",
      );
      return acceptedRoles.length > 0;
    },
  );

  return (
    <>
      <PageHeader
        onBack={() => window.history.back()}
        title="Status Progress"
        extra={[
          <Button
            key="2"
            icon={<DownloadOutlined />}
            onClick={() =>
              exportCsv().then((buffer) => {
                const url = window.URL.createObjectURL(new Blob([buffer]));
                const link = window.document.createElement("a");
                link.href = url;
                link.setAttribute(
                  "download",
                  `${currentDateAsString}"invoices-as-csv.csv`,
                );
                window.document.body.appendChild(link);
                link.click();
                link.parentNode!.removeChild(link);
              })
            }
          >
            Download CSV
          </Button>,
        ]}
      >
        {hasPermission(role, ["ADMIN", "USER", "MANAGER"]) ? (
          <SendMailForm
            type="invoice"
            scope="payment"
            documents={selectedRows}
            onMailSent={handleMailSent}
            to={to}
            cc={cc}
            templates={filteredEmailTemplates}
            labelTo="Information or Execution"
            labelCC="Information or Execution"
          />
        ) : null}

        <Collapse>
          <Panel header="Approved" key="1">
            <ApprovedInvoices
              onAfterAction={refresh}
              shouldRefresh={token}
              rowSelection={rowSelection}
            />
          </Panel>
          <Panel header="On-hold" key="2">
            <OnHoldInvoices
              onAfterAction={refresh}
              shouldRefresh={token}
              rowSelection={rowSelection}
            />
          </Panel>
          <Panel header="Rejected" key="3">
            <RejectedInvoices
              onAfterAction={refresh}
              shouldRefresh={token}
              rowSelection={rowSelection}
            />
          </Panel>
        </Collapse>
      </PageHeader>
    </>
  );
};

export default StatusProgressInvoicesList;
