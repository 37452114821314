import React from "react";
import { Row } from "antd";

interface BankAccountsListProps {
  tenantData: any;
}

const TenantDashboard: React.FC<BankAccountsListProps> = ({ tenantData }) => {
  return (
    <>
      <Row justify="space-between" align="middle" style={{ marginTop: 20 }}>
        {tenantData.dashboardUrl ? (
          <iframe
            title={tenantData.tenant_name}
            src={tenantData.dashboardUrl}
            style={{ width: "100%", height: "320vh" }}
            frameBorder="0"
            allowFullScreen
          ></iframe>
        ) : (
          <></>
        )}
      </Row>
    </>
  );
};

export default TenantDashboard;
