import React, { useContext } from "react";
import { Redirect } from "react-router-dom";

import { Row, Col, Tabs } from "antd";

import { Empty } from "../layouts";

import { UserContext } from "../components/auth";
import LoginForm from "../components/auth/LoginForm";

const Login = () => {
  const { user } = useContext(UserContext);

  return (
    <Empty>
      {user ? (
        <Redirect to="/dashboard" />
      ) : (
        <>
          <Row style={{ paddingTop: "280px" }}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 16, offset: 4 }}
              md={{ span: 12, offset: 6 }}
              lg={{ span: 8, offset: 8 }}
              xl={{ span: 6, offset: 9 }}
            >
              <Tabs defaultActiveKey="1">
                <Tabs.TabPane tab="Login" key="login">
                  <LoginForm />
                </Tabs.TabPane>
              </Tabs>
            </Col>
          </Row>
        </>
      )}
    </Empty>
  );
};

export default Login;
