import { useCallback, useEffect, useReducer, useRef, Reducer } from "react";

import { TablePaginationConfig } from "antd";
import {
  FilterValue,
  SorterResult,
  TableCurrentDataSource,
} from "antd/lib/table/interface";

export interface IDataSourceRequest {
  criterias?: Record<string, any> | null;
  sort?: [string, string] | null;
  current?: number | null;
  pageSize?: number | null;
}

export interface IDataSourceResult<T> {
  content: Array<T>;
}

export type IDataSourceFetchFunc<T> = (
  req: IDataSourceRequest,
) => Promise<IDataSourceResult<T>>;

interface IDataSourcePagination {
  current: number;
  pageSize: number;
  total: number;
}

interface IDataSourceState<T> {
  content: Array<T>;
  pagination: IDataSourcePagination;
  criterias: Record<string, any> | null;
  filter?: Record<string, any> | null;
  sort: [string, string] | null;
  loading: boolean;
  error: boolean;
}

const ACTION_LOADING = Symbol("ACTION_LOADING");
const ACTION_LOADED = Symbol("ACTION_LOADED");
const ACTION_FAILED = Symbol("ACTION_FAILED");

interface IDataSourceAction<T> {
  type: typeof ACTION_LOADING | typeof ACTION_LOADED | typeof ACTION_FAILED;
  payload: {  
    content?: Array<T>;
    criterias?: Record<string, any> | null;
    sort?: [string, string] | null;
    current?: number;
    pageSize?: number;
    pageNumber?: number;
    totalElements?: number;
    pagination?: IDataSourcePagination;
  };
}

const initialState: IDataSourceState<any> = {
  content: [],
  pagination: {
    current: 1,
    pageSize: 10,
    total: 0,
  },

  criterias: null,
  filter: null,
  sort: null,

  loading: false,
  error: false,
};

const reducer: Reducer<IDataSourceState<any>, IDataSourceAction<any>> = (
  state,
  action,
) => {
  switch (action.type) {
    case ACTION_LOADING:
      return {
        ...state,

        criterias: action.payload.criterias || state.criterias,
        sort: action.payload.sort || state.sort,
        pagination: {
          ...state.pagination,
          current:
            action.payload.pagination?.current || state.pagination.current,
          pageSize:
            action.payload.pagination?.pageSize || state.pagination.pageSize,
        },

        loading: true,
        error: false,
      };

    case ACTION_LOADED:
      return {
        ...state,
        content: action.payload.content || initialState.content,
        pagination: {
          current: action.payload.pageNumber || initialState.pagination.current,
          pageSize: action.payload.pageSize || initialState.pagination.pageSize,
          total: action.payload.totalElements || initialState.pagination.total,
        },
        error: false,
        loading: false,
      };

    case ACTION_FAILED:
      return {
        ...initialState,
        error: true,
      };

    default:
      return state;
  }
};

const reduceFilters = (filters: Record<string, any> | null) =>
  filters
    ? Object.keys(filters)
        .filter((key) => Array.isArray(filters[key]) && filters[key].length > 0)
        .reduce(
          (acc, key) => ({
            ...acc,
            [key]: filters[key],
          }),
          {} as Record<string, any>,
        )
    : null;

const reduceSorter = (sorter: SorterResult<any> | SorterResult<any>[]) => {
  if (!sorter) {
    return null;
  }

  const { order, field } = Array.isArray(sorter) ? sorter[0] : sorter;

  if (!order) {
    return null;
  }

  return [field!, order === "ascend" ? "asc" : "desc"] as [string, string];
};

export default function useDatasource<T>(
  fetcher: IDataSourceFetchFunc<T>,
  options = { pagination: {} },
) {
  const safeguard = useRef(0);

  const { pagination: optPagination } = options;

  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    pagination: {
      ...initialState.pagination,
      ...optPagination,
    },
  });

  const reload = useCallback(() => {
    dispatch({
      type: ACTION_LOADING,
      payload: {},
    });
  }, []);

  const handleChange = useCallback(
    (
      pagination: TablePaginationConfig,
      filters: Record<string, FilterValue | null>,
      sorter: SorterResult<T> | SorterResult<T>[],
      _extra: TableCurrentDataSource<T>,
    ) => {
      dispatch({
        type: ACTION_LOADING,
        payload: {
          criterias: reduceFilters(filters),
          sort: reduceSorter(sorter),
          pagination: pagination as IDataSourcePagination,
        },
      });
    },
    [],
  );

  useEffect(() => {
    dispatch({ type: ACTION_LOADING, payload: {} });
  }, []);

  useEffect(() => {
    if (state.loading) {
      safeguard.current += 1;
      const pointintime = safeguard.current;

      const {
        criterias,
        sort,
        pagination: { current, pageSize },
      } = state;

      fetcher({
        criterias,
        sort,
        current,
        pageSize,
      })
        .then((res) => {
          if (pointintime === safeguard.current) {
            dispatch({ type: ACTION_LOADED, payload: res });
          }
        })
        .catch(() => {
          if (pointintime === safeguard.current) {
            dispatch({ type: ACTION_FAILED, payload: {} });
          }
        });
    }
    return () => {};
  }, [fetcher, state]);

  return {
    content: state.content,
    loading: state.loading,
    invalid: state.error,
    pagination: state.pagination,

    filter: state.filter,
    sort: state.sort,

    handleChange,
    reload,
  };
}
