import { Form, Input, Row, Col, Button, notification } from "antd";
import { resetPassword } from "../../services/user";
import { useHistory } from "react-router-dom";

const PasswordResetEmailForm = () => {
  const [resetPasswordEmailForm] = Form.useForm();
  //@ts-ignore
  const history = useHistory();

  const onResetPasswordEmail = (values: any) => {
    resetPassword(values.email).then(() => {
      history.push("/");
      notification.success({
        message: "Please check your email for reset link",
      });
    });
  };

  return (
    <div style={{ paddingTop: "280px" }}>
      <Form
        form={resetPasswordEmailForm}
        id="resetPasswordEmailForm"
        labelCol={{ span: 18 }}
        wrapperCol={{ span: 20 }}
        onFinish={onResetPasswordEmail}
      >
        <Row>
          <Col span={14}>
            <Form.Item
              rules={[{ required: true, message: "Required Field" }]}
              label="E-mail"
              name="email"
            >
              <Input placeholder="Email" />
            </Form.Item>
          </Col>
          <Col span={14}>
            <Form.Item wrapperCol={{ offset: 18, span: 20 }}>
              <Button htmlType="submit" type="primary">
                Send reset request
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default PasswordResetEmailForm;
