export interface IVatType {
  label: string;
  value: string;
}

const vatTypes = {
  content: [
    { label: "International", value: "international" },
    { label: "National", value: "national" },
    {
      label: "European Economic Community",
      value: "european_economic_community",
    },
  ],
};

export const getVatTypes: () => Promise<{
  content: Array<IVatType>;
}> = () => Promise.resolve(vatTypes);
