import React from "react";
import LoansList from "../../components/documents/loans/LoansList";

import { Default } from "../../layouts";

const Loans = () => {
  return (
    <Default>
      <LoansList />
    </Default>
  );
};

export default Loans;
