import { criteriaQuery } from "../utils/query";

import {
  IDataSourceFetchFunc,
  IDataSourceRequest,
} from "./../hooks/useDataSource";
import { IFile } from "./files";

export interface IDocument {
  id?: number;
  dateCreated?: number;
  userCreated?: string;
  tags?: string;
  comments?: string;
  purpose?: string;
  status?: string;
  expiryDate?: number;
  nextImportantDate?: number;
  otherParties?: string;
  kycType?: string;
  entityActingAs?: string;
  documentFrom?: { id: number; name: string };
  documentTo?: { id: number; name: string };
  company?: { id: number; name: string };
  files?: Array<IFile>;
}

export const getDocumentsByStatus =
  (status: string) => (req: IDataSourceRequest) =>
    fetch(
      `${
        process.env.REACT_APP_BACKEND_URL
      }/documents/documents/${status}?${criteriaQuery(req)}`,
      {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
      },
    ).then((res) => (res.ok ? res.json() : Promise.reject(res)));

export const getDocumentById = (id: number) =>
  fetch(`${process.env.REACT_APP_BACKEND_URL}/documents/documents/${id}`, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: { "Content-Type": "application/json" },
  }).then((res) => (res.ok ? res.json() : Promise.reject(res)));

export const getDocuments: IDataSourceFetchFunc<IDocument> = (
  req: IDataSourceRequest,
) =>
  fetch(
    `${process.env.REACT_APP_BACKEND_URL}/documents/documents/?${criteriaQuery(
      req,
    )}`,
    {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    },
  ).then((res) => (res.ok ? res.json() : Promise.reject(res)));

export const saveDocument: (document: IDocument) => Promise<IDocument> = (
  document,
) =>
  fetch(
    `${process.env.REACT_APP_BACKEND_URL}/documents/documents/${document.id}`,
    {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      mode: "cors",
      credentials: "include",
      body: JSON.stringify(document),
    },
  ).then((res) => (res.ok ? res.json() : Promise.reject(res)));

export const duplicateDocument: (document: IDocument) => Promise<IDocument> = (
  document,
) =>
  fetch(
    `${process.env.REACT_APP_BACKEND_URL}/documents/documents/${document.id}/duplicate`,
    {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      mode: "cors",
      credentials: "include",
      body: JSON.stringify(document),
    },
  ).then((res) => (res.ok ? res.json() : Promise.reject(res)));

export const downloadDocument: (document: IDocument) => Promise<IDocument> = (
  document,
) =>
  fetch(
    `${process.env.REACT_APP_BACKEND_URL}/documents/documents/${document.id}/download`,
    {
      method: "GET",
      mode: "cors",
      credentials: "include",
    },
  ).then((res) => (res.ok ? res.json() : Promise.reject(res)));

export const deleteDocument: (document: IDocument) => Promise<void> = (
  document,
) =>
  fetch(
    `${process.env.REACT_APP_BACKEND_URL}/documents/documents/${document.id}`,
    {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
      mode: "cors",
      credentials: "include",
    },
  ).then((res) => (res.ok ? Promise.resolve() : Promise.reject(res)));

export const exportCsv = () =>
  fetch(`${process.env.REACT_APP_BACKEND_URL}/documents/documents/csv`, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: { "Content-Type": "application/octet-stream" },
  }).then((res) => (res.ok ? res.arrayBuffer() : Promise.reject(res)));
