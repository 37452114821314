export interface IKycCategory {
  value: string;
  label: string;
}

const kycCategories = {
  content: [
    { label: "Entity", value: "entity" },
    { label: "3rd Party", value: "3rd_party" },
  ],
};

export const getKycCategories: () => Promise<{
  content: Array<IKycCategory>;
}> = () => Promise.resolve(kycCategories);
