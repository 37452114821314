export interface ICategory {
  value: string;
  label: string;
}

const categories: { content: Array<ICategory> } = {
  content: [
    { value: "Entity", label: "Entity" },
    { value: "Bank", label: "Bank" },
    { value: "Agent", label: "Agent" },
  ],
};

export const getCategories: () => Promise<{ content: Array<ICategory> }> = () =>
  Promise.resolve(categories);
