import { IDataSourceRequest } from "../hooks/useDataSource";

export const criteriaQuery = (fetchProps: IDataSourceRequest) => {
  const parts = [];

  if (fetchProps?.criterias) {
    parts.push(
      ...Object.entries(fetchProps.criterias).map(([key, value]) =>
        [key, Array.isArray(value) ? value.join(",") : value].join("="),
      ),
    );
  }

  if (fetchProps?.current && fetchProps?.current > 0) {
    parts.push(`_page=${fetchProps?.current}`);
  }
  if (fetchProps?.pageSize && fetchProps?.pageSize > 0) {
    parts.push(`_size=${fetchProps?.pageSize}`);
  }

  if (fetchProps?.sort) {
    const [field, sort] = fetchProps.sort;
    if (field) {
      parts.push(`_sort=${field}&_dir=${sort || "asc"}`);
    }
  }

  return parts.join("&");
};
