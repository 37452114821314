import React from "react";

import { Empty } from "../layouts";

const Forbidden = () => (
  <Empty>
    <>
      <h1>403</h1>
      <h2>Access denied</h2>

      <span>The page you're trying to access has restricted access. </span>
    </>
  </Empty>
);

export default Forbidden;
