import React from "react";
import { useParams } from "react-router";

import { Empty } from "../layouts";

const Reset = () => {
  const { token } = useParams<{ token: string | undefined }>();

  return (
    <Empty>
      <div>{token ? <>New password form</> : <>Reset password form</>}</div>
    </Empty>
  );
};

export default Reset;
