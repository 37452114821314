import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import {
  Modal,
  Form,
  Input,
  Row,
  Col,
  Select,
  InputNumber,
  Table,
  Button,
  Tooltip,
  Switch,
  notification,
} from "antd";
import {
  CheckOutlined,
  CloseOutlined,
  DownloadOutlined,
} from "@ant-design/icons";

import useModal, { ModalProps } from "../../../hooks/useModal";

import {
  getAllInvoiceGroups,
  getAllInvoiceTags,
  IInvoice,
  updateInvoice,
} from "../../../services/invoices";
import {
  downloadFile,
  setFileAttachable,
  IFile,
  setFileToShowInEmail,
  setFileToIncludeInAccounting,
} from "../../../services/files";

import { UserContext } from "../../../components/auth";
import UploadDropZone from "../../UploadDropZone";
import FileSizeLabel from "../../FileSizeLabel";
import DatePickerMillis from "../../DatePickerMillis";
import ThirdPartyInvoiceModal from "./ThirdPartyInvoiceModal";
import { DictsContext } from "../../../hooks/useDictionaries";
import SelectWithAddon from "../../SelectWithAddon";
interface InvoiceModalProps extends ModalProps {
  invoice: IInvoice | null;
}

const InvoiceModal: React.FC<InvoiceModalProps> = ({
  visible,
  onOk,
  onCancel,
  invoice,
}) => {
  const [form] = Form.useForm();
  const { user } = useContext(UserContext);
  const { role } = user;

  const { dicts: dictionaries, reload } = useContext(DictsContext);

  const [selectedThirdParty, setSelectedThirdParty] = useState();
  const [isThirdPartyNew, setIsThirdPartyNew] = useState(false);

  const [filteredBanks, setFilteredBanks] = useState([] as any);
  const [filteredThirdParties, setFilteredThirdParties] = useState({
    content: [] as any,
    loading: true,
  });
  const [selectedEntityGroup, setSelectedEntityGroup] = useState("");

  const [allInvoiceTags, setAllInvoiceTags] = useState([]);
  const [allInvoiceGroups, setAllInvoiceGroups] = useState([]);

  useEffect(() => {
    form.resetFields();

    const invoiceNatureValue = invoice?.invoiceNature || "debit";

    form.setFieldsValue({
      ...invoice,
      invoiceNature: invoiceNatureValue,
      tags: invoice?.tags?.map((t: any) => t.name),
      groups: invoice?.groups?.map((g: any) => g.name),
    });
    setIsThirdPartyNew(invoice?.documentFrom != null ? false : true);
    // @ts-ignore
    setSelectedThirdParty(invoice?.documentFrom);

    getAllInvoiceTags().then((tags: any) => {
      setAllInvoiceTags(tags);
    });

    getAllInvoiceGroups().then((groups: any) => {
      setAllInvoiceGroups(groups);
    });
  }, [form, invoice]);

  const {
    invoiceTypes,
    currencies,
    payingModes,
    thirdParties,
    companies,
    banks,
  } = dictionaries;

  const handleFinish = useCallback(
    (value) => {
      updateInvoice({ ...invoice, ...value })
        .then(
          (_res: IInvoice) => {
            onOk(null);
          },
          (err: any) => {
            console.error(err);
          },
        )
        .catch((err) => {
          console.error(err);
          notification.error({
            message:
              role === "OBSERVER"
                ? "Cannot modify record"
                : "Something went wrong",
          });
        });
    },
    [invoice, onOk, role],
  );
  const handleDownload = useCallback((file) => {
    downloadFile(file!).then((buffer) => {
      const url = window.URL.createObjectURL(new Blob([buffer]));
      const link = window.document.createElement("a");
      link.href = url;
      link.setAttribute("download", file!.fileName);
      window.document.body.appendChild(link);
      link.click();
      link.parentNode!.removeChild(link);
    });
  }, []);
  const handleChangeAttachmentTag = useCallback(
    (index: number, file: IFile, attachable: boolean) => {
      setFileAttachable(file, attachable).then(() => {
        form.setFieldsValue({
          files: form.getFieldValue("files").map((val: IFile, idx: number) => ({
            ...val,
            attachable: idx === index ? attachable : val.attachable,
          })),
        });
      });
    },
    [form],
  );
  const handleChangeShowInEmail = useCallback(
    (index: number, file: IFile, showInEmail: boolean) => {
      setFileToShowInEmail(file, showInEmail).then(() => {
        form.setFieldsValue({
          files: form.getFieldValue("files").map((val: IFile, idx: number) => ({
            ...val,
            showInEmail: idx === index ? showInEmail : !showInEmail,
          })),
        });
      });
    },
    [form],
  );
  const handleChangeIncludeInAccounting = useCallback(
    (index: number, file: IFile, includeInAccounting: boolean) => {
      setFileToIncludeInAccounting(file, includeInAccounting).then(() => {
        form.setFieldsValue({
          files: form.getFieldValue("files").map((val: IFile, idx: number) => ({
            ...val,
            includeInAccounting:
              idx === index ? includeInAccounting : val.includeInAccounting,
          })),
        });
      });
    },
    [form],
  );
  const fileColumns = useMemo(
    () => [
      {
        title: "File name",
        dataIndex: "fileName",
        key: "fileName",
      },
      {
        title: "File size",
        dataIndex: "fileSize",
        key: "fileSize",
        render: (size: number) => <FileSizeLabel size={size} />,
      },
      {
        title: "Created by",
        dataIndex: "createdBy",
        key: "createdBy",
      },
      {
        title: "Created at",
        dataIndex: "createdAt",
        key: "createdAt",
        render: (createdAt: string) => (
          <>{new Date(parseInt(createdAt, 10)).toLocaleDateString()}</>
        ),
      },
      {
        title: "Attach to email",
        dataIndex: "attachable",
        key: "attachable",
        //@ts-ignore
        shouldCellUpdate: (current: IFile, prev: IFile) => true,
        render: (value: boolean, record: IFile, index: number) => {
          return (
            <>
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                checked={value}
                // disabled={index === 0}
                onClick={(val) => handleChangeAttachmentTag(index, record, val)}
              />
            </>
          );
        },
      },
      {
        title: "Link to Validation",
        dataIndex: "showInEmail",
        key: "showInEmailTable",
        shouldCellUpdate: (current: IFile, prev: IFile) => true,
        render: (value: boolean, record: IFile, index: number) => {
          return (
            <>
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                checked={value}
                disabled={
                  !(
                    invoice!.status === "new" ||
                    invoice!.status === "validate" ||
                    invoice!.status === "submit_validate"
                  )
                }
                onClick={(val) => handleChangeShowInEmail(index, record, val)}
              />
            </>
          );
        },
      },
      {
        title: "Accounting document",
        dataIndex: "includeInAccounting",
        key: "includeInAccounting",
        shouldCellUpdate: (current: IFile, prev: IFile) => true,
        render: (value: boolean, record: IFile, index: number) => {
          return (
            <>
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                checked={value}
                disabled={
                  invoice!.status === "new" || invoice!.status === "validate"
                }
                onClick={(val) =>
                  handleChangeIncludeInAccounting(index, record, val)
                }
              />
            </>
          );
        },
      },
      {
        title: "Actions",
        dataIndex: "_actions",
        key: "_actions",
        render: (_value: string, record: IInvoice) => {
          return (
            <Tooltip title="Download file">
              <Button
                type="text"
                size="small"
                icon={<DownloadOutlined />}
                onClick={() => handleDownload(record)}
              />
            </Tooltip>
          );
        },
      },
    ],
    [
      handleChangeAttachmentTag,
      handleDownload,
      handleChangeShowInEmail,
      handleChangeIncludeInAccounting,
      invoice,
    ],
  );

  useEffect(() => {
    // an entity has only 1 group, not multiple groups
    const selectedEntityGroups = invoice?.documentTo?.groups?.split(",");
    //setting the selected entity group (ex: YACHT)
    setSelectedEntityGroup(invoice?.documentTo?.groups);

    // setting only the banks intersecting the same groups as selected entity
    const filteredBanksByGroup = banks.content.filter((bank) =>
      // looking for true condition (if bank groups array contains selected entity group)
      selectedEntityGroups?.some(
        (group: string) => bank.groups.split(",").indexOf(group) !== -1,
      ),
    );
    setFilteredBanks(filteredBanksByGroup);

    // setting only the thirdParties intersecting the same groups as selected entity
    const filteredThirdPartiesByGroup = thirdParties?.content.filter(
      (thirdParty) =>
        // looking for true condition (if third party groups array contains selected entity group)
        selectedEntityGroups?.some(
          (group: string) => thirdParty.groups.split(",").indexOf(group) !== -1,
        ),
    );
    if (filteredThirdPartiesByGroup.length > 0) {
      setFilteredThirdParties({
        content: [
          ...filteredThirdPartiesByGroup,
          // added a blank option, with id === "0" and sorted the list ASC
          { id: "0", name: "<blank>" },
        ].sort((a, b) => parseFloat(a.id) - parseFloat(b.id)),
        loading: false,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [banks.content, invoice?.documentTo?.groups, thirdParties?.content]);

  const onEntitySelect = (_label: any, option: any) => {
    const selectedEntity = companies?.content.filter(
      (c) => c.id === option.value,
    )[0];
    const selectedEntityGroups = selectedEntity?.groups?.split(",");
    setSelectedEntityGroup(selectedEntity.groups);

    // setting only the banks intersecting the same groups as selected entity
    const filteredBanksByGroup = banks.content.filter((b) =>
      // looking for true condition (if bank groups array contains selected entity group)
      selectedEntityGroups?.some(
        (g: string) => b.groups.split(",").indexOf(g) !== -1,
      ),
    );
    setFilteredBanks(filteredBanksByGroup);

    // setting only the thirdParty intersecting the same groups as selected entity
    const filteredThirdPartiesByGroup = thirdParties?.content.filter((tp) =>
      // looking for true condition (if third party groups array contains selected entity group)
      selectedEntityGroups?.some(
        (g: string) => tp.groups.split(",").indexOf(g) !== -1,
      ),
    );

    setFilteredThirdParties({
      content: [
        ...filteredThirdPartiesByGroup,
        // added a blank option, with id === "0" and sorted the list ASC
        { id: "0", name: "<blank>" },
      ].sort((a, b) => parseFloat(a.id) - parseFloat(b.id)),
      loading: false,
    });

    form.setFieldsValue({
      documentFrom: undefined,
      payingBank: undefined,
    });
  };

  const createThirdPartyOnTheFly = (createdThirdParty: any) => {
    form.setFieldsValue({
      ...invoice,
      documentFrom: createdThirdParty,
    });

    reload();

    setFilteredThirdParties({
      content: [...filteredThirdParties.content, createdThirdParty],
      loading: false,
    });
  };

  const [show, thirdPartyModalProps] = useModal();
  const handleAddThirdParty = useCallback(() => {
    show();
  }, [show]);
  const handleViewThirdParty = useCallback(() => {
    // @ts-ignore
    setSelectedThirdParty(selectedThirdParty);
    show();
  }, [selectedThirdParty, show]);

  // Limit to only 1 tag (as per requirements)
  const handleTagsFieldSelect = (value: string) => {
    if (value && value.length > 0) {
      form.setFieldsValue({
        tags: [value[0].toUpperCase()],
      });
    }
  };

  const handleGroupsFieldSelect = (values: string[]) => {
    if (values && values.length > 0) {
      form.setFieldsValue({
        groups: values.map((v) => v.toUpperCase()),
      });
    }
  };

  return (
    <>
      <ThirdPartyInvoiceModal
        {...thirdPartyModalProps}
        dictionaries={dictionaries}
        selectedEntityGroup={selectedEntityGroup}
        thirdParty={selectedThirdParty}
        createThirdPartyOnTheFly={createThirdPartyOnTheFly}
      />
      <Form
        onFinish={handleFinish}
        form={form}
        id="InvoiceModalForm"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 14 }}
      >
        <Modal
          title="Cash Flow Edit"
          visible={visible}
          okButtonProps={{ htmlType: "submit", form: "InvoiceModalForm" }}
          onCancel={onCancel}
          width={1600}
        >
          <Row>
            <Col span={12}>
              <Form.Item label="Document Date" name="documentDate">
                <DatePickerMillis />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Due/Payment Date" name="dueDate">
                <DatePickerMillis />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                rules={[{ required: true, message: "Required Field" }]}
                label="Entity"
                name={["documentTo", "id"]}
              >
                <Select loading={companies.loading} onSelect={onEntitySelect}>
                  {companies?.content.map(
                    ({ id, name }: { id: number; name: string }) => (
                      <Select.Option value={id} key={id}>
                        {name}
                      </Select.Option>
                    ),
                  )}
                </Select>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                rules={[{ required: true, message: "Required Field" }]}
                label="From"
                name={["documentFrom", "id"]}
                style={{ position: "relative" }}
              >
                <SelectWithAddon
                  loading={filteredThirdParties.loading}
                  showSearch
                  filterOption={(input: string, option: any) =>
                    option?.children
                      ?.toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  // onSelect={onThirdPartySelect}
                  // set button to display view / new Third Party functionality
                  withChoice={isThirdPartyNew}
                  addonLabel="New"
                  onAddonClick={handleAddThirdParty}
                  secondAddonLabel="View"
                  onSecondAddonClick={handleViewThirdParty}
                >
                  {(
                    filteredThirdParties?.content?.sort(
                      (a: { name: string }, b: { name: string }) =>
                        a.name.localeCompare(b.name),
                    ) || []
                  ).map(({ id, name }: { id: number; name: string }) => (
                    <Select.Option value={id} key={id}>
                      {name}
                    </Select.Option>
                  ))}
                </SelectWithAddon>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                rules={[{ required: true, message: "Required Field" }]}
                label="Invoice Type"
                name="documentType"
              >
                <Select loading={invoiceTypes.loading}>
                  {invoiceTypes?.content.map(
                    ({ value, label }: { value: string; label: string }) => (
                      <Select.Option value={value} key={value}>
                        {label}
                      </Select.Option>
                    ),
                  )}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                rules={[{ required: true, message: "Required Field" }]}
                label="Amount"
                name="amount"
                dependencies={["currency"]}
                shouldUpdate
              >
                <InputNumber
                  step={0.01}
                  min={0}
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  //@ts-ignore
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  precision={2}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label="Currency"
                name="currency"
                dependencies={["amount"]}
                rules={[{ required: true, message: "Required Field" }]}
              >
                <Select loading={currencies.loading}>
                  {currencies?.content.map(
                    ({ value, label }: { value: string; label: string }) => (
                      <Select.Option value={value} key={value}>
                        {label}
                      </Select.Option>
                    ),
                  )}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="VAT" name="vat">
                <Input />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item label="Installment" name="amountEur">
                <InputNumber
                  step={0.01}
                  min={0}
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  //@ts-ignore
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  precision={2}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                rules={[{ required: true, message: "Required Field" }]}
                label="Paying/Receiving Agent"
                name={["payingAgent", "id"]}
              >
                <Select loading={companies.loading}>
                  {companies?.content.map(
                    ({ id, name }: { id: number; name: string }) => (
                      <Select.Option value={id} key={id}>
                        {name}
                      </Select.Option>
                    ),
                  )}
                </Select>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                rules={[{ required: true, message: "Required Field" }]}
                label="Paying/Receiving Bank"
                name={["payingBank", "id"]}
              >
                <Select loading={banks.loading}>
                  {filteredBanks.map(
                    ({ id, name }: { id: number; name: string }) => (
                      <Select.Option value={id} key={id}>
                        {name}
                      </Select.Option>
                    ),
                  )}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                rules={[{ required: true, message: "Required Field" }]}
                label="Paying/Receiving Mode"
                name="payingMode"
              >
                <Select loading={payingModes.loading}>
                  {payingModes?.content.map(
                    ({ value, label }: { value: string; label: string }) => (
                      <Select.Option value={value} key={value}>
                        {label}
                      </Select.Option>
                    ),
                  )}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Invoice Ref" name="documentRef">
                <Input />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item label="Purpose" name="purpose">
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Group" name="tags">
                <Select
                  options={allInvoiceTags.map((tag: any) => ({
                    value: tag.name.toUpperCase(),
                    label: tag.name,
                  }))}
                  mode="tags"
                  onChange={handleTagsFieldSelect}
                  style={{ width: "100%" }}
                ></Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Categories" name="groups">
                <Select
                  options={allInvoiceGroups.map((group: any) => ({
                    value: group.name.toUpperCase(),
                    label: group.name,
                  }))}
                  mode="tags"
                  onChange={handleGroupsFieldSelect}
                  style={{ width: "100%" }}
                ></Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Debit/Credit" name="invoiceNature">
                <Select>
                  <Select.Option value="debit" key="debit">
                    Debit
                  </Select.Option>
                  <Select.Option value="credit" key="credit">
                    Credit
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label="Comments"
                name="comments"
                labelCol={{ span: 3 }}
                wrapperCol={{ span: 19 }}
              >
                <Input.TextArea rows={8} />
              </Form.Item>
            </Col>
          </Row>

          <UploadDropZone
            action={`/documents/invoices/${invoice?.id}/upload`}
          />

          <Form.Item
            shouldUpdate
            labelCol={{ span: 0 }}
            wrapperCol={{ span: 24 }}
          >
            {() => (
              <Table
                columns={fileColumns}
                rowKey="id"
                dataSource={form.getFieldValue("files") || []}
                className="pf-table-auto"
              />
            )}
          </Form.Item>
        </Modal>
      </Form>
    </>
  );
};

export default InvoiceModal;
