import React, { useState } from "react";
import { Button, Row, Col } from "antd";
import { useHistory } from "react-router-dom";

const LoginForm: React.FC = () => {
  const [, setEmail] = useState("");
  const history = useHistory();

  return (
    <>
      <form
        method="POST"
        action={`${process.env.REACT_APP_BACKEND_URL}/auth/local`}
      >
        <Row style={{ marginBottom: "10px" }}>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 12 }}
            lg={{ span: 8 }}
          >
            <label>Email address:</label>
          </Col>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 12 }}
            lg={{ span: 16 }}
          >
            <input
              onChange={(evt) => setEmail(evt.target.value)}
              type="email"
              name="email"
              className="ant-input"
              placeholder="Email Address"
              required
            />
          </Col>
        </Row>

        <Row style={{ marginBottom: "10px" }}>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 12 }}
            lg={{ span: 8 }}
          >
            <label>Password:</label>
          </Col>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 12 }}
            lg={{ span: 16 }}
          >
            <input
              type="password"
              name="password"
              className="ant-input"
              placeholder="Password"
              required
            />
          </Col>
        </Row>

        <Row style={{ marginBottom: "10px" }}>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 12, offset: 12 }}
            lg={{ span: 16, offset: 8 }}
          >
            <label>
              <input type="checkbox" name="remember" />
              &nbsp; Remember me
            </label>
          </Col>
        </Row>

        <Row style={{ marginBottom: "10px" }}>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 12, offset: 12 }}
            lg={{ span: 16, offset: 8 }}
          >
            <Button
              type="primary"
              htmlType="submit"
              style={{
                backgroundColor: "#3fea9c",
                color: "black",
                border: "none",
              }}
            >
              Sign in
            </Button>
            <Button
              onClick={() => history.push("/password-reset-email-form")}
              type="link"
              style={{ color: "black" }}
            >
              Forgot password
            </Button>
          </Col>
        </Row>
      </form>
    </>
  );
};

export default LoginForm;
