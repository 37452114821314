import React, { useCallback } from "react";

import { Card } from "antd";
import { DisconnectOutlined, LinkOutlined } from "@ant-design/icons";

interface SyncToGDriveProps {}

const SyncToGDrive: React.FC<SyncToGDriveProps> = () => {
  const handleConnect = useCallback(() => {
    console.log("Connect");
  }, []);

  const handleDisconnect = useCallback(() => {
    console.log("Disconnect");
  }, []);

  return (
    <Card
      actions={[
        <LinkOutlined onClick={handleConnect} disabled />,
        <DisconnectOutlined onClick={handleDisconnect} disabled />,
      ]}
    >
      <Card.Meta
        title="Storage"
        description="Synchronize all your documents to Storage"
        style={{ height: 120 }}
      />
    </Card>
  );
};

export default SyncToGDrive;
