export const setUpDrive = (email: string) =>
  fetch(`${process.env.REACT_APP_BACKEND_URL}/drive/init`, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email }),
  }).then((res) => {
    if (res.ok) {
      return Promise.resolve();
    } else {
      return res
        .json()
        .then((data) => Promise.reject(data.error || "Unknown error"));
    }
  });

export const syncWithDrive = () =>
  fetch(`${process.env.REACT_APP_BACKEND_URL}/drive/sync`, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: { "Content-Type": "application/json" },
  }).then((res) => {
    if (res.ok) {
      return Promise.resolve();
    } else {
      return res
        .json()
        .then((data) => Promise.reject(data.error || "Unknown error"));
    }
  });
