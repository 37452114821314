export interface IKycStatus {
  label: string;
  value: string;
}

const kycStatus = {
  content: [
    { label: "New", value: "new" },
    { label: "In Force", value: "in_force" },
    { label: "Archived", value: "closed" },
  ],
};

export const getKycStatus: () => Promise<{
  content: Array<IKycStatus>;
}> = () => Promise.resolve(kycStatus);
