import React, { useMemo, useCallback, useState } from "react";

import {
  Upload,
  message,
  Button,
  Divider,
  PageHeader,
  Popconfirm,
  Table,
  Tooltip,
} from "antd";
// import { TableRowSelection } from "antd/lib/table/interface";
import {
  DeleteOutlined,
  PlusCircleOutlined,
  DownloadOutlined,
  UploadOutlined,
} from "@ant-design/icons";

import useDatasource from "../../hooks/useDataSource";
import useModal from "../../hooks/useModal";
import useDictionaries from "../../hooks/useDictionaries";

import {
  getCompanies,
  deleteCompany,
  ICompany,
  createCompany,
  exportCsv,
} from "../../services/companies";
import { getGroups } from "../../services/groups";
import { getCountries } from "../../services/countries";

import DictionaryLabel from "../DictionaryLabel";
import CompanyModal from "./CompanyModal";
import { currentDateAsString } from "../../utils/helpers";

const dictionaries = {
  groups: getGroups,
  countries: getCountries,
};

interface CompaniesListProps {}

const CompaniesList: React.FC<CompaniesListProps> = () => {
  const { loading, pagination, content, handleChange, reload } =
    useDatasource<ICompany>(getCompanies);

  const csvProps = {
    name: "file",
    beforeUpload: (file: any) => {
      const isCsv = file.type === "text/csv";
      if (!isCsv) {
        message.error(`${file.name} is not a csv file`);
      }
      return isCsv || Upload.LIST_IGNORE;
    },
    withCredentials: true,
    action: `${process.env.REACT_APP_BACKEND_URL}/companies/csv`,
    showUploadList: false,
    maxCount: 1,
    onChange: reload,
  };
  const [dicts] = useDictionaries(dictionaries);
  const { countries } = dicts;
  const [selectedCompany, setSelectedCompany] = useState<ICompany | null>(null);

  const [show, modalProps] = useModal(reload);

  const handleDuplicate = useCallback(
    (company: ICompany) => {
      createCompany({
        ...company,
        id: undefined,
        name: "<duplicate>" + company.name,
      }).then(reload);
    },
    [reload],
  );

  const columns = useMemo(
    () => [
      {
        title: "Group",
        dataIndex: "groups",
        key: "groups",
        // render: (text: any, row: any) => (
        //   <DictionaryLabel
        //     dictionary={groups}
        //     value={text}
        //     keyField="name"
        //     labelField="name"
        //   />
        // ),
      },
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
      },
      // {
      //   title: "Legal Form",
      //   dataIndex: "legalForm",
      //   key: "legalForm",
      // },
      {
        title: "Trade Register",
        dataIndex: "tradeRegister",
        key: "tradeRegister",
      },

      {
        title: "VAT",
        dataIndex: "vat",
        key: "vat",
      },
      // {
      //   title: "TIN (Tax identification)",
      //   dataIndex: "tin",
      //   key: "tin",
      // },
      {
        title: "Address",
        dataIndex: "address",
        key: "address",
        width: 300,
      },
      // {
      //   title: "Other Permits",
      //   dataIndex: "otherPermits",
      //   key: "otherPermits",
      // },
      {
        title: "Country",
        dataIndex: "country",
        key: "country",
        render: (value: any) => (
          <DictionaryLabel
            dictionary={countries}
            value={value}
            keyField="value"
            labelField="label"
          />
        ),
      },
      // {
      //   title: "Tags",
      //   dataIndex: "tags",
      //   key: "tags",
      // },
      // {
      //   title: "Comment",
      //   dataIndex: "comment",
      //   key: "comment",
      // },
      {
        title: "Action",
        dataIndex: "",
        key: "x",
        width: 150,
        render: (text: any, row: any) => (
          <>
            <Tooltip placement="bottomRight" title="Duplicate">
              <Popconfirm
                placement="topLeft"
                title="Are you sure about duplicating this entity?"
                onConfirm={() => handleDuplicate(row)}
                okText="Yes"
                cancelText="No"
              >
                <Button type="text" icon={<PlusCircleOutlined />} />
              </Popconfirm>
            </Tooltip>
            <span> </span>
            <Tooltip placement="bottomRight" title="Delete">
              <Popconfirm
                placement="topLeft"
                title="Are you sure about deleting this entity?"
                onConfirm={() =>
                  deleteCompany(row).then(() => {
                    reload();
                  })
                }
                okText="Yes"
                cancelText="No"
              >
                <Button type="text" icon={<DeleteOutlined />} />
              </Popconfirm>
            </Tooltip>
          </>
        ),
      },
    ],
    [countries, handleDuplicate, reload],
  );

  // const [selectedRows, setSelectedRows] = useState<Array<React.Key>>([]);
  // const rowSelection: TableRowSelection<ICompany> = {
  //   selectedRowKeys: selectedRows,
  //   onChange: (selectedRowKeys: React.Key[]) => {
  //     setSelectedRows(selectedRowKeys);
  //   },
  // };

  const handleAddCompany = useCallback(() => {
    setSelectedCompany({} as ICompany);
    show();
  }, [show]);

  return (
    <>
      <PageHeader
        className="site-page-header-responsive"
        onBack={() => window.history.back()}
        title="Entities"
        extra={[
          <Button onClick={handleAddCompany} type="primary">
            New Entity
          </Button>,
          <Upload {...csvProps}>
            <Button icon={<UploadOutlined />}>Upload CSV</Button>
          </Upload>,
          <Button
            key="2"
            icon={<DownloadOutlined />}
            onClick={() =>
              exportCsv().then((buffer) => {
                const url = window.URL.createObjectURL(new Blob([buffer]));
                const link = window.document.createElement("a");
                link.href = url;
                link.setAttribute(
                  "download",
                  `${currentDateAsString}"entities-as-csv.csv`,
                );
                window.document.body.appendChild(link);
                link.click();
                link.parentNode!.removeChild(link);
              })
            }
          >
            Download CSV
          </Button>,
        ]}
      >
        <Table
          //@ts-ignore
          columns={columns}
          rowKey="id"
          loading={loading}
          pagination={pagination}
          dataSource={content}
          onChange={handleChange}
          // rowSelection={rowSelection}
          // scroll={{ x: 1500 }}
          onRow={(company: ICompany) => {
            return {
              onDoubleClick: (evt) => {
                evt.preventDefault();
                setSelectedCompany(company);
                show();
              },
            };
          }}
        />
        <Divider />

        <CompanyModal
          dictionaries={dicts}
          {...modalProps}
          company={selectedCompany}
        />
      </PageHeader>
    </>
  );
};

export default CompaniesList;
