import React from "react";
import BankAccountsList from "../../components/entity/BankAccountList";

import { Default } from "../../layouts";

const BankAccounts = () => {
  return (
    <Default>
      <BankAccountsList />
    </Default>
  );
};

export default BankAccounts;
