import React, {
  useMemo,
  useState,
  useEffect,
  useContext,
  useCallback,
} from "react";

import { Table } from "antd";

import useDatasource from "../../../hooks/useDataSource";
import useModal from "../../../hooks/useModal";

import {
  getInvoiceById,
  getInvoicesByStatus,
  IInvoice,
} from "../../../services/invoices";

import InvoiceModal from "./InvoiceModal";
import AuditModal from "../AuditModal";
import useInvoicesColumns from "./useInvoicesColumns";
import { DictsContext } from "../../../hooks/useDictionaries";

interface ApprovedInvoicesListProps {
  onAfterAction: Function;
  shouldRefresh: number;
  rowSelection: any;
}

const ApprovedInvoices: React.FC<ApprovedInvoicesListProps> = ({
  onAfterAction,
  shouldRefresh,
  rowSelection,
}) => {
  const { dicts } = useContext(DictsContext);

  const { loading, pagination, content, handleChange, reload } =
    useDatasource<IInvoice>(getInvoicesByStatus("pg"));

  useEffect(() => {
    if (shouldRefresh > 0) {
      reload();
    }
  }, [reload, shouldRefresh]);

  const [selectedInvoice, setSelectedInvoice] = useState<IInvoice | null>(null);

  const [show, modalProps] = useModal(reload);

  const [showAuditModal, auditModalProps] = useModal();
  const [auditModalParams, setAuditModalParams] = useState({
    id: null,
    documentType: null,
  });

  const [statusList, setStatusList] = useState([] as object[]);

  useEffect(() => {
    setStatusList(
      content.map((invoice: IInvoice) => ({ id: invoice.id, status: 0 })),
    );
  }, [content]);

  const onStatusChange = useCallback(
    (row) => {
      const { id } = row;

      const currentInvoice = statusList.find((inv: any) => inv.id === id);
      const filteredStatusList = statusList.filter((inv: any) => inv.id !== id);

      if (!currentInvoice) {
        return;
      }
      const updatedStatusList = [
        ...filteredStatusList,
        {
          ...currentInvoice,
          // @ts-ignore
          status: currentInvoice.status !== 2 ? currentInvoice.status + 1 : 0,
        },
      ];
      setStatusList(updatedStatusList);
    },
    [statusList],
  );

  const columnProps = useMemo(
    () => ({
      dictionaries: dicts,
      onPreviewDocument: (invoice: IInvoice) =>
        window.open(`/documents/invoice/preview/${invoice.id}`),
      onAfterAction,
      gridType: "invoiceStatus.statusProgress.approvedGrid",
      userRightsStatus: "approved",
      hiddenColumns: ["eyes"],
      fourEyesScope: "approved",
      showAuditModal,
      setAuditModalParams,
      statusList,
      onStatusChange,
    }),
    [dicts, onAfterAction, onStatusChange, showAuditModal, statusList],
  );

  const columns = useInvoicesColumns(columnProps);

  return (
    <>
      <Table
        scroll={{ x: 2000 }}
        columns={columns}
        rowKey="id"
        loading={loading}
        pagination={pagination}
        dataSource={content}
        onChange={handleChange}
        // scroll={{ x: 2000 }}
        // scroll={content.length > 0 ? { x: 2000 } : undefined}
        rowSelection={rowSelection}
        onRow={(record: IInvoice) => {
          return {
            onDoubleClick: (evt) => {
              evt.preventDefault();
              getInvoiceById(record.id!).then((res: IInvoice) => {
                setSelectedInvoice({
                  ...res,
                } as unknown as IInvoice);
                show();
              });
            },
          };
        }}
      />

      <InvoiceModal {...modalProps} invoice={selectedInvoice} />

      {auditModalParams?.id ? (
        <AuditModal {...{ ...auditModalProps, ...auditModalParams }} />
      ) : null}
    </>
  );
};

export default ApprovedInvoices;
