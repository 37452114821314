import React, { useMemo } from "react";
import { Tooltip } from "antd";

import "./MultiColorProgressBar.scss";

export interface MultiColorProgressBarProps {
  values: { color: string; value: number; label: string }[];
}

const MultiColorProgressBar: React.FC<MultiColorProgressBarProps> = ({
  values,
}) => {
  const total = useMemo(
    () => Math.min(values.reduce((sum, value) => sum + value.value, 0)),
    [values],
  );

  return (
    <div className="multicolor-progress-bar">
      <div
        className="bars"
        style={{
          backgroundColor: values[values.length - 1].color,
        }}
      >
        {values
          .filter((value) => value.value > 0)
          .map((value, index) => (
            <Tooltip
              title={
                <React.Fragment>
                  {value.label.split("\n").map((row) => (
                    <div key={Math.random()}>{row}</div>
                  ))}
                </React.Fragment>
              }
              key={Math.random()}
            >
              <div
                className="bar"
                style={{
                  backgroundColor: value.color,
                  width: (value.value * 100) / total + "%",
                }}
                key={index}
              >
                {((value.value * 100) / total).toFixed(0) + "%"}
              </div>
            </Tooltip>
          ))}
      </div>
    </div>
  );
};

export default MultiColorProgressBar;
