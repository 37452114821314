import { IDataSourceRequest } from "../hooks/useDataSource";

export interface IDocumentAudit {
  id?: number;
  createdAt?: number;
  createdBy?: string;
}

const getDocumentAudit =
  (documentType: string, id: number) => (req: IDataSourceRequest) =>
    fetch(`${process.env.REACT_APP_BACKEND_URL}/audit/${documentType}/${id}`, {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    }).then((res) => (res.ok ? res.json() : Promise.reject(res)));

export { getDocumentAudit };
