import React, {
  useMemo,
  useState,
  useCallback,
  useContext,
  useEffect,
} from "react";

import { PageHeader, Table, Button } from "antd";
import { TableRowSelection } from "antd/lib/table/interface";
import { DownloadOutlined } from "@ant-design/icons";

import useDatasource from "../../../hooks/useDataSource";
import useModal from "../../../hooks/useModal";

import {
  getInvoiceById,
  getInvoicesByStatus,
  IInvoice,
  processInvoice,
  exportCsv,
} from "../../../services/invoices";

import InvoiceModal from "./InvoiceModal";
import AuditModal from "../AuditModal";
import useInvoicesColumns from "./useInvoicesColumns";
import SendMailForm from "../../SendMailForm";

import { UserContext } from "../../../components/auth";
import { currentDateAsString, hasPermission } from "../../../utils/helpers";
import { DictsContext } from "../../../hooks/useDictionaries";
import { IContact } from "../../../services/contacts";
import { IEmailTemplate } from "../../../services/email-templates";

interface PaidInvoicesListProps {}

const PaidInvoices: React.FC<PaidInvoicesListProps> = () => {
  const { dicts } = useContext(DictsContext);

  const { user } = useContext(UserContext);
  const { role } = user;

  const { loading, pagination, content, handleChange, reload } =
    useDatasource<IInvoice>(getInvoicesByStatus("paid"));

  const [selectedInvoice, setSelectedInvoice] = useState<IInvoice | null>(null);

  const [show, modalProps] = useModal(reload);

  const [showAuditModal, auditModalProps] = useModal();
  const [auditModalParams, setAuditModalParams] = useState({
    id: null,
    documentType: null,
  });

  const [statusList, setStatusList] = useState([] as object[]);

  useEffect(() => {
    setStatusList(
      content.map((invoice: IInvoice) => ({ id: invoice.id, status: 0 })),
    );
  }, [content]);

  const onStatusChange = useCallback(
    (row) => {
      const { id } = row;

      const currentInvoice = statusList.find((inv: any) => inv.id === id);
      const filteredStatusList = statusList.filter((inv: any) => inv.id !== id);

      if (!currentInvoice) {
        return;
      }
      const updatedStatusList = [
        ...filteredStatusList,
        {
          ...currentInvoice,
          // @ts-ignore
          status: currentInvoice.status !== 2 ? currentInvoice.status + 1 : 0,
        },
      ];
      setStatusList(updatedStatusList);
    },
    [statusList],
  );

  const columnProps = useMemo(
    () => ({
      dictionaries: dicts,
      onPreviewDocument: (invoice: IInvoice) =>
        window.open(`/documents/invoice/preview/${invoice.id}`),
      gridType: "invoiceStatus.validation",
      userRightsStatus: "paid",
      fourEyesScope: "paid",
      hiddenColumns: ["eyes"],
      showAuditModal,
      setAuditModalParams,

      statusList,
      onStatusChange,
    }),
    [dicts, onStatusChange, showAuditModal, statusList],
  );
  const { contacts, templates } = dicts;

  const columns = useInvoicesColumns(columnProps);

  const [selectedRows, setSelectedRows] = useState<Array<React.Key>>([]);
  const rowSelection: TableRowSelection<IInvoice> = {
    selectedRowKeys: selectedRows,
    columnTitle: <>Mail</>,
    onChange: (selectedRowKeys: React.Key[]) => {
      setSelectedRows(selectedRowKeys);
    },
  };

  const handleMailSent = useCallback(() => {
    Promise.allSettled(
      selectedRows.map((id) =>
        processInvoice({ id: id as number }, "submit_validate"),
      ),
    )
      .then(() => setSelectedRows([]))
      .then(reload);
  }, [selectedRows, reload]);

  const to = contacts.content.filter((contact: IContact) => {
    const roles = contact?.scope?.split(",");
    const acceptedRoles = roles.filter(
      (roleStr: string) => roleStr === "information",
    );
    return acceptedRoles.length > 0;
  });

  const cc = contacts.content.filter((contact: IContact) => {
    const roles = contact?.scope?.split(",");
    const acceptedRoles = roles.filter(
      (roleStr: string) => roleStr === "information",
    );
    return acceptedRoles.length > 0;
  });

  const filteredEmailTemplates = templates.content.filter(
    (emailTemplate: IEmailTemplate) => {
      const roles = emailTemplate?.scope?.split(",") as string[];
      const acceptedRoles = roles.filter(
        (roleStr: string) => roleStr === "information",
      );
      return acceptedRoles.length > 0;
    },
  );

  return (
    <>
      <PageHeader
        onBack={() => window.history.back()}
        title="Paid Invoices"
        extra={[
          <Button
            key="2"
            icon={<DownloadOutlined />}
            onClick={() =>
              exportCsv().then((buffer) => {
                const url = window.URL.createObjectURL(new Blob([buffer]));
                const link = window.document.createElement("a");
                link.href = url;
                link.setAttribute(
                  "download",
                  `${currentDateAsString}"invoices-as-csv.csv`,
                );
                window.document.body.appendChild(link);
                link.click();
                link.parentNode!.removeChild(link);
              })
            }
          >
            Download CSV
          </Button>,
        ]}
      >
        {hasPermission(role, ["ADMIN", "USER", "MANAGER"]) ? (
          <SendMailForm
            type="invoice"
            scope="information"
            documents={selectedRows}
            onMailSent={handleMailSent}
            to={to}
            cc={cc}
            templates={filteredEmailTemplates}
            labelTo="Information"
            labelCC="Information"
          />
        ) : null}

        <Table
          scroll={{ x: 1800 }}
          columns={columns}
          rowKey="id"
          loading={loading}
          pagination={pagination}
          dataSource={content}
          onChange={handleChange}
          // scroll={{ x: 2000 }}
          rowSelection={rowSelection}
          onRow={(record: IInvoice) => {
            return {
              onDoubleClick: (evt) => {
                evt.preventDefault();
                getInvoiceById(record.id!).then((res: IInvoice) => {
                  setSelectedInvoice({
                    ...res,
                  } as unknown as IInvoice);
                  show();
                });
              },
            };
          }}
        />
      </PageHeader>

      <InvoiceModal {...modalProps} invoice={selectedInvoice} />
      {auditModalParams?.id ? (
        <AuditModal {...{ ...auditModalProps, ...auditModalParams }} />
      ) : null}
    </>
  );
};

export default PaidInvoices;
