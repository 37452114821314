import React from "react";

import "./Loader.scss";

const Loader: React.FC = () => (
  <svg width="100" height="100" viewBox="0 0 100 100" className="loader">
    <polyline points="50,100 100,100 100,0" strokeWidth="30" fill="none" />
  </svg>
);

export default Loader;
