import React from "react";

import TenantSettings from "../../components/admin/TenantSettings";

import { Default } from "../../layouts";
const Settings = () => {
  return <Default><TenantSettings /></Default>;
};

export default Settings;
