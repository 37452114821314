export interface IAlertPeriod {
  value: string;
  label: string;
}

const alertPeriods: { content: Array<IAlertPeriod> } = {
  content: [
    { label: "15 days", value: "15" },
    { label: "30 days", value: "30" },
    { label: "60 days", value: "60" },
    { label: "90 days", value: "90" },
    { label: "120 days", value: "120" },
  ],
};

export const getAlertPeriods: () => Promise<{
  content: Array<IAlertPeriod>;
}> = () => Promise.resolve(alertPeriods);
