import React, { useMemo, useCallback, useContext, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { Upload, message, Tabs } from "antd";

import { Default } from "../../layouts";

import { DictsProvider } from "../../hooks/useDictionaries";
import useRefresh from "../../hooks/useRefresh";

import { getGroups } from "../../services/groups";
import { getInvoiceTypes } from "../../services/invoice-types";
import { getCurrencies } from "../../services/currencies";
import { getPayingModes } from "../../services/paying-modes";
import { getCompanies } from "../../services/companies";
import { getThirdParties } from "../../services/third-parties";
import { getBanks } from "../../services/banks";
import { getContactsByType } from "../../services/contacts";
import { getCountries } from "../../services/countries";
import { getAccountTypes } from "../../services/account-types";
import { getVatTypes } from "../../services/vat-types";
import { getEmailTemplates } from "../../services/email-templates";
import { getInvoiceNatures } from "../../services/invoice-natuure";

import NewInvoicesList from "../../components/documents/invoices/NewInvoicesList";
import ValidatedInvoicesList from "../../components/documents/invoices/ValidatedInvoicesList";
import StatusProgressInvoicesList from "../../components/documents/invoices/StatusProgressInvoicesList";
import PaidInvoices from "../../components/documents/invoices/PaidInvoices";
import CenttripInvoices from "../../components/documents/invoices/CenttripInvoices";

import { UserContext } from "../../components/auth";
import { hasPermission } from "../../utils/helpers";

const dictionaries = {
  groups: getGroups,
  invoiceTypes: getInvoiceTypes,
  currencies: getCurrencies,
  payingModes: getPayingModes,
  thirdParties: getThirdParties,
  companies: getCompanies,
  banks: getBanks,
  contacts: getContactsByType("all"),
  templates: getEmailTemplates,
  invoiceNature: getInvoiceNatures,
  countries: getCountries,
  vatTypes: getVatTypes,
  accountTypes: getAccountTypes,
};

const Invoices: React.FC = () => {
  const { user } = useContext(UserContext);

  const { role } = user;

  const history = useHistory();

  const location = useLocation();

  const currentTab = useMemo(() => location.hash || "#update", [location.hash]);

  const [token, refresh] = useRefresh();

  const handleTabChange = useCallback(
    (tab) => {
      history.push({
        ...location,
        hash: tab,
      });
    },
    [history, location],
  );
  const csvProps = {
    name: "file",
    beforeUpload: (file: any) => {
      const isCsv = file.type === "text/csv";
      if (!isCsv) {
        message.error(`${file.name} is not a csv file`);
      }
      return isCsv || Upload.LIST_IGNORE;
    },
    withCredentials: true,
    action: `${process.env.REACT_APP_BACKEND_URL}/documents/invoices/csv`,
    showUploadList: false,
    maxCount: 1,
    onChange: refresh,
  };

  useEffect(() => {
    if (role === "OBSERVER") {
      history.push(`${location.pathname}#validate`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Default>
      <DictsProvider dictionaries={dictionaries}>
        <Tabs
          activeKey={currentTab}
          onChange={handleTabChange}
          destroyInactiveTabPane
        >
          {hasPermission(role, ["ADMIN", "USER", "MANAGER"]) ? (
            <Tabs.TabPane tab="New" key="#update">
              <NewInvoicesList csvProps={csvProps} />
            </Tabs.TabPane>
          ) : null}

          {hasPermission(role, ["ADMIN", "USER", "MANAGER", "OBSERVER"]) ? (
            <Tabs.TabPane tab="Validation" key="#validate">
              <ValidatedInvoicesList
                onAfterAction={refresh}
                shouldRefresh={token}
              />
            </Tabs.TabPane>
          ) : null}

          {hasPermission(role, ["ADMIN", "MANAGER", "OBSERVER"]) ? (
            <Tabs.TabPane tab="Status Progress" key="#progress">
              <StatusProgressInvoicesList />
            </Tabs.TabPane>
          ) : null}

          {hasPermission(role, ["ADMIN", "MANAGER", "USER", "OBSERVER"]) ? (
            <Tabs.TabPane tab="Completed" key="#paid">
              <PaidInvoices />
            </Tabs.TabPane>
          ) : null}
          {hasPermission(role, ["ADMIN"]) ? (
            <Tabs.TabPane tab="Centtrip" key="#centrip">
              <CenttripInvoices
                onAfterAction={refresh}
                shouldRefresh={token}
                rowSelection={undefined}
              />
            </Tabs.TabPane>
          ) : null}

          {/* {hasPermission(role, ["ADMIN", "MANAGER", "USER", "OBSERVER"]) ? (
            <Tabs.TabPane tab="3A" key="#3a">
              <ThreeAInvoices />
            </Tabs.TabPane>
          ) : null} */}
        </Tabs>
      </DictsProvider>
    </Default>
  );
};

export default Invoices;
