import { criteriaQuery } from "../utils/query";

import {
  IDataSourceFetchFunc,
  IDataSourceRequest,
} from "../hooks/useDataSource";

export interface IThirdParty {
  id: string;
  tags: string;
  group: string;
  name: string;
  address: string;
  country: string;
  comment: string;
  currency: string;
  iban: string;
  email: string;
  accountType: string;
  vatType: string;
  standardChartOfAccount: string;
  corpInfo: string;
  swift: string;
}
export const getThirdParties: IDataSourceFetchFunc<IThirdParty> = (
  req: IDataSourceRequest,
) =>
  fetch(
    `${process.env.REACT_APP_BACKEND_URL}/third-parties/?${criteriaQuery(req)}`,
    {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    },
  ).then((res) => (res.ok ? res.json() : Promise.reject(res)));

export const createThirdParty: (thirdParties: any) => Promise<IThirdParty> = (
  thirdParty,
) =>
  fetch(`${process.env.REACT_APP_BACKEND_URL}/third-parties/`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    mode: "cors",
    credentials: "include",
    body: JSON.stringify(thirdParty),
  }).then((res) => (res.ok ? res.json() : Promise.reject(res)));

export const saveThirdParty: (
  thirdParty: IThirdParty,
) => Promise<IThirdParty> = (thirdParty) =>
  fetch(`${process.env.REACT_APP_BACKEND_URL}/third-parties/${thirdParty.id}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    mode: "cors",
    credentials: "include",
    body: JSON.stringify(thirdParty),
  }).then((res) => (res.ok ? res.json() : Promise.reject(res)));

export const deleteThirdParty: (thirdParty: IThirdParty) => Promise<void> = (
  thirdParty,
) =>
  fetch(
    `${process.env.REACT_APP_BACKEND_URL}/third-parties/${thirdParty.id}/delete`,
    {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      mode: "cors",
      credentials: "include",
    },
  ).then((res) => (res.ok ? Promise.resolve() : Promise.reject(res)));

export const getThirdPartyReport = (thirdParty: IThirdParty) =>
  fetch(
    `${process.env.REACT_APP_BACKEND_URL}/third-parties/${thirdParty.id}/report`,
    {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      credentials: "include",
      headers: { "Content-Type": "application/octet-stream" },
    },
  ).then((res) => (res.ok ? res.arrayBuffer() : Promise.reject(res)));

export const exportCsv = () =>
  fetch(`${process.env.REACT_APP_BACKEND_URL}/third-parties/csv`, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: { "Content-Type": "application/octet-stream" },
  }).then((res) => (res.ok ? res.arrayBuffer() : Promise.reject(res)));
