import { SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Divider,
  Input,
  Select,
  Space,
  Typography,
  DatePicker,
} from "antd";

const { RangePicker } = DatePicker;

const FilterIcon = (filtered: any) => (
  <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
);

const FilterDialog = ({
  title,
  searchTitle,
  resetTitle,
  confirm,
  clearFilters,
  children,
}: any) => (
  <div style={{ padding: 16, paddingTop: 16 }}>
    <Typography.Paragraph>{title}</Typography.Paragraph>
    {children}
    <Divider />
    <Space>
      <Button
        type="primary"
        onClick={() => confirm()}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 120 }}
      >
        {searchTitle}
      </Button>
      <Button onClick={clearFilters} size="small" style={{ width: 120 }}>
        {resetTitle}
      </Button>
    </Space>
  </div>
);

export const filterText = ({
  title = "Filter",
  searchTitle = "Search",
  resetTitle = "Reset",
}) => ({
  filterDropdown: ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }: any) => (
    <FilterDialog
      title={title}
      searchTitle={searchTitle}
      resetTitle={resetTitle}
      confirm={confirm}
      clearFilters={clearFilters}
    >
      <Input
        value={selectedKeys[0]}
        onChange={(e) =>
          setSelectedKeys(e.target.value ? [e.target.value] : [])
        }
        onPressEnter={() => confirm()}
        style={{ marginBottom: 8, display: "block" }}
      />
    </FilterDialog>
  ),
  filterIcon: FilterIcon,
});

export const filterDate = ({
  title = "Filter",
  searchTitle = "Search",
  resetTitle = "Reset",
}) => {
  return {
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: any) => (
      <FilterDialog
        title={title}
        searchTitle={searchTitle}
        resetTitle={resetTitle}
        confirm={confirm}
        clearFilters={clearFilters}
      >
        <RangePicker
          onChange={(val: any) =>
            setSelectedKeys(
              val ? [val[0].utc().format(), val[1].utc().format()] : [],
            )
          }
          format={"DD.MM.YYYY"}
          showTime={{ format: "HH:mm" }}
        />
      </FilterDialog>
    ),

    filterIcon: FilterIcon,
  };
};

export const filterDict = ({
  title = "Filter",
  dictionary = { content: [], loading: true, error: false },
  searchTitle = "Search",
  resetTitle = "Reset",
  keyField = "id",
  labelField = "name",
}) => {
  return {
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: any) => (
      <FilterDialog
        title={title}
        searchTitle={searchTitle}
        resetTitle={resetTitle}
        confirm={confirm}
        clearFilters={clearFilters}
      >
        <Select
          showSearch
          filterOption={(input, option) =>
            option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          placeholder="Search"
          loading={dictionary.loading}
          value={selectedKeys[0]}
          onChange={(value) => setSelectedKeys([value])}
          style={{ marginBottom: 8, display: "block" }}
        >
          {dictionary.content.map((option: any) => (
            <Select.Option value={option[keyField]} key={option[keyField]}>
              {option[labelField]}
            </Select.Option>
          ))}
        </Select>
      </FilterDialog>
    ),
    filterIcon: FilterIcon,
  };
};
