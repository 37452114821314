import React, { useState } from "react";
import {
  //   Divider,
  Form,
  Input,
  Row,
  Col,
  PageHeader,
  Button,
  notification,
} from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import useRefresh from "../../../hooks/useRefresh";
import { changeUserPassword } from "../../../services/user";

const UserPassword = () => {
  const [passwordForm] = Form.useForm();
  const [, refresh] = useRefresh();

  const onFinishPasswordForm = (values: any) => {
    if (values.newPassword !== values.confirmNewPassword) {
      notification.error({ message: "New passwords don't match" });
      return;
    } else {
      changeUserPassword(values)
        .then(() => {
          notification.success({ message: "Password successfully changed" });
          refresh();
        })
        .catch(() =>
          notification.error({ message: "Could not change password" }),
        );
    }
  };

  const [newPassword, setNewPassword] = useState("");

  return (
    <Form
      form={passwordForm}
      id="passwordForm"
      labelCol={{ span: 5 }}
      wrapperCol={{ span: 16 }}
      onFinish={onFinishPasswordForm}
    >
      <PageHeader
        className="site-page-header"
        title="Change Password"
        extra={[
          <Button htmlType="submit" type="primary">
            Update User Password
          </Button>,
        ]}
      />
      <Row>
        <Col span={14}>
          <Form.Item
            rules={[
              {
                required: true,
                message: "Required Field",
              },
            ]}
            label="Current Password"
            name="currentPassword"
          >
            <Input.Password
              placeholder="Current Password"
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
            />
          </Form.Item>
        </Col>
        <Col span={14}>
          <Form.Item
            rules={[
              { required: true, message: "Required Field" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?.&])[A-Za-z\d@$!%*?.&]{8,}$/.test(
                      value,
                    )
                  ) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      "Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character",
                    ),
                  );
                },
              }),
            ]}
            label="New Password"
            name="newPassword"
          >
            <Input.Password
              placeholder="New Password"
              onChange={(evt) => setNewPassword(evt.target.value)}
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
            />
          </Form.Item>
        </Col>
        <Col span={14}>
          <Form.Item
            rules={[
              { required: true, message: "Required Field" },

              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (value === newPassword) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      "The two passwords that you entered do not match!",
                    ),
                  );
                },
              }),
            ]}
            label="Confirm New Password"
            name="confirmNewPassword"
          >
            <Input.Password
              placeholder="Confirm New Password"
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default UserPassword;
