import React, { useState, useEffect } from "react";
import { useParams } from "react-router";

import { Default } from "../../layouts";

import { getDocumentById, IDocument } from "../../services/documents";
import { IFile } from "../../services/files";
import { getInvoiceById, IInvoice } from "../../services/invoices";
import { getKycById, IKyc } from "../../services/kycs";
import { getLoanById, ILoan } from "../../services/loans";

import PreviewDocument from "../../components/PreviewDocument";
import { getGroups } from "../../services/groups";
import { getAccountTypes } from "../../services/account-types";
import { getBanks } from "../../services/banks";
import { getCompanies } from "../../services/companies";
import { getContactsByType } from "../../services/contacts";
import { getCountries } from "../../services/countries";
import { getCurrencies } from "../../services/currencies";
import { getEmailTemplates } from "../../services/email-templates";
import { getInvoiceNatures } from "../../services/invoice-natuure";
import { getInvoiceTypes } from "../../services/invoice-types";
import { getPayingModes } from "../../services/paying-modes";
import { getThirdParties } from "../../services/third-parties";
import { getVatTypes } from "../../services/vat-types";
import { DictsProvider } from "../../hooks/useDictionaries";

const dictionaries = {
  groups: getGroups,
  invoiceTypes: getInvoiceTypes,
  currencies: getCurrencies,
  payingModes: getPayingModes,
  thirdParties: getThirdParties,
  companies: getCompanies,
  banks: getBanks,
  contacts: getContactsByType("all"),
  templates: getEmailTemplates,
  invoiceNature: getInvoiceNatures,
  countries: getCountries,
  vatTypes: getVatTypes,
  accountTypes: getAccountTypes,
};

const documentFetcher: Record<string, Function> = {
  invoice: getInvoiceById,
  document: getDocumentById,
  loan: getLoanById,
  kyc: getKycById,
};

const Preview: React.FC = () => {
  const { type, id }: { type: string; id: string } = useParams();

  const [files, setFiles] = useState<Array<IFile>>([]);
  const [document, setDocument] = useState<IInvoice | IDocument | ILoan | IKyc>(
    {},
  );

  useEffect(() => {
    const fetcher = documentFetcher[type];
    if (fetcher) {
      Promise.resolve()
        .then(() => {
          setFiles([]);
        })
        .then(() => fetcher(id))
        .then(
          (res: IInvoice | IDocument | ILoan | IKyc) => {
            setFiles(res.files!);
            setDocument(res);
          },
          () => setFiles([]),
        );
    } else {
      setFiles([]);
    }
  }, [type, id]);

  return (
    <Default>
      <DictsProvider dictionaries={dictionaries}>
        <PreviewDocument files={files} document={document} />
      </DictsProvider>
    </Default>
  );
};

export default Preview;
