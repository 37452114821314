import React from "react";
import BanksList from "../../components/entity/BanksList";

import { Default } from "../../layouts";

const Banks = () => {
  return (
    <Default>
      <BanksList />
    </Default>
  );
};

export default Banks;
