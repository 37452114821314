import { parseError } from "../utils/fetch";
import { criteriaQuery } from "../utils/query";
import {
  IDataSourceFetchFunc,
  IDataSourceRequest,
} from "./../hooks/useDataSource";

export interface IContact {
  id?: number;
  tags: string;
  role: string;
  groups: string;
  category: string;
  scope: string;
  firstName: string;
  lastName: string;
  title: string;
  email: string;
  phone: string;
  mobile: string;
  address: string;
  comment: string;
}

export const createContact: (contact: any) => Promise<any> = (contact) =>
  fetch(`${process.env.REACT_APP_BACKEND_URL}/contacts/`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    mode: "cors",
    credentials: "include",
    body: JSON.stringify(contact),
  }).then((res) => (res.ok ? res.json() : parseError(res)));

export const getContactsByType: (
  type: string,
) => IDataSourceFetchFunc<IContact> =
  (type: string) => (req: IDataSourceRequest) =>
    fetch(
      `${process.env.REACT_APP_BACKEND_URL}/contacts/${type}/?${criteriaQuery(
        req,
      )}`,
      {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
      },
    ).then((res) => (res.ok ? res.json() : Promise.reject(res)));

export const saveContact: (contacts: Partial<any>) => Promise<any> = (
  contacts,
) =>
  fetch(`${process.env.REACT_APP_BACKEND_URL}/contacts/${contacts.id}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    mode: "cors",
    credentials: "include",
    body: JSON.stringify(contacts),
  }).then((res) => (res.ok ? res.json() : parseError(res)));

export const deleteContact: (contact: IContact) => Promise<void> = (contact) =>
  fetch(`${process.env.REACT_APP_BACKEND_URL}/contacts/${contact.id}/delete`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    mode: "cors",
    credentials: "include",
  }).then((res) => (res.ok ? Promise.resolve() : Promise.reject(res)));

export const exportCsv = () =>
  fetch(`${process.env.REACT_APP_BACKEND_URL}/contacts/csv`, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: { "Content-Type": "application/octet-stream" },
  }).then((res) => (res.ok ? res.arrayBuffer() : Promise.reject(res)));
