import React, { useMemo, useCallback, useState } from "react";

import {
  Upload,
  message,
  Button,
  PageHeader,
  Popconfirm,
  Table,
  Tooltip,
} from "antd";
import {
  DeleteOutlined,
  PlusCircleOutlined,
  DownloadOutlined,
  UploadOutlined,
} from "@ant-design/icons";

import useDatasource from "../../hooks/useDataSource";
import useModal from "../../hooks/useModal";

import {
  getGroups,
  deleteGroup,
  createGroup,
  IGroup,
  exportCsv,
} from "../../services/groups";

import GroupModal from "./GroupModal";
import { currentDateAsString } from "../../utils/helpers";

interface GroupsListProps {}

const GroupsList: React.FC<GroupsListProps> = () => {
  const { loading, pagination, content, handleChange, reload } =
    useDatasource<IGroup>(getGroups);
  const csvProps = {
    name: "file",
    beforeUpload: (file: any) => {
      const isCsv = file.type === "text/csv";
      if (!isCsv) {
        message.error(`${file.name} is not a csv file`);
      }
      return isCsv || Upload.LIST_IGNORE;
    },
    withCredentials: true,
    action: `${process.env.REACT_APP_BACKEND_URL}/groups/csv`,
    showUploadList: false,
    maxCount: 1,
    onChange: reload,
  };
  const [selectedGroup, setSelectedGroup] = useState<IGroup | null>(null);
  const [show, modalProps] = useModal(reload);

  const handleDuplicate = useCallback(
    (group: IGroup) => {
      createGroup({
        ...group,
        id: undefined,
        name: "<duplicate>" + group.name,
      }).then(() => reload());
    },
    [reload],
  );

  const columns = useMemo(
    () => [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Action",
        dataIndex: "",
        key: "x",
        width: 150,
        render: (text: any, row: any) => (
          <>
            <Tooltip placement="bottomRight" title="Duplicate">
              <Popconfirm
                placement="topLeft"
                title="Are you sure about duplicating this group?"
                onConfirm={() => handleDuplicate(row)}
                okText="Yes"
                cancelText="No"
              >
                <Button type="text" icon={<PlusCircleOutlined />} />
              </Popconfirm>
            </Tooltip>
            <span> </span>
            <Tooltip placement="bottomRight" title="Delete">
              <Popconfirm
                placement="topLeft"
                title="Are you sure about deleting this group?"
                onConfirm={() =>
                  deleteGroup(row).then(() => {
                    reload();
                  })
                }
                okText="Yes"
                cancelText="No"
              >
                <Button
                  type="text"
                  //
                  icon={<DeleteOutlined />}
                />
              </Popconfirm>
            </Tooltip>
          </>
        ),
      },
    ],
    [handleDuplicate, reload],
  );

  // const [selectedRows, setSelectedRows] = useState<Array<React.Key>>([]);
  // const rowSelection: TableRowSelection<IGroup> = {
  //   selectedRowKeys: selectedRows,
  //   onChange: (selectedRowKeys: React.Key[]) => {
  //     setSelectedRows(selectedRowKeys);
  //   },
  // };
  const handleAddGroup = useCallback(() => {
    setSelectedGroup({} as IGroup);
    show();
  }, [show]);

  return (
    <>
      <PageHeader
        className="site-page-header-responsive"
        onBack={() => window.history.back()}
        title="Groups"
        extra={[
          <Button key="1" type="primary" onClick={handleAddGroup}>
            New Group
          </Button>,

          <Button
            key="2"
            icon={<DownloadOutlined />}
            onClick={() =>
              exportCsv().then((buffer) => {
                const url = window.URL.createObjectURL(new Blob([buffer]));
                const link = window.document.createElement("a");
                link.href = url;
                link.setAttribute(
                  "download",
                  `${currentDateAsString}groups-as-csv.csv`,
                );
                window.document.body.appendChild(link);
                link.click();
                link.parentNode!.removeChild(link);
              })
            }
          >
            Download CSV
          </Button>,

          <Upload {...csvProps}>
            <Button icon={<UploadOutlined />}>Upload CSV</Button>
          </Upload>,
        ]}
      >
        <Table
          columns={columns}
          rowKey="id"
          loading={loading}
          pagination={pagination}
          dataSource={content}
          onChange={handleChange}
          // rowSelection={rowSelection}
          onRow={(group: IGroup) => {
            return {
              onDoubleClick: (evt) => {
                evt.preventDefault();
                setSelectedGroup(group);
                show();
              },
            };
          }}
        />
        {/* <Divider />
        <Button onClick={handleAddGroup} type="primary">
          New Group
        </Button> */}
      </PageHeader>

      <GroupModal {...modalProps} group={selectedGroup} />
    </>
  );
};

export default GroupsList;
