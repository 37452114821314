export interface IInvoiceNature {
  label: string;
  value: string;
}

const invoiceNature = {
  content: [
    { label: "II", value: "II", thirdPartyVatType: "international" },
    { label: "IME", value: "IME", thirdPartyVatType: "international" },
    { label: "IOH", value: "IOH", thirdPartyVatType: "international" },
    { label: "PI", value: "PI", thirdPartyVatType: "international" },
    { label: "PME", value: "PME", thirdPartyVatType: "national" },
    { label: "POH", value: "POH", thirdPartyVatType: "national" },
    { label: "PIV", value: "PIV", thirdPartyVatType: "national" },
    {
      label: "DTS",
      value: "DTS",
      thirdPartyVatType: "european_economic_community",
    },
    {
      label: "ME",
      value: "ME",
      thirdPartyVatType: "european_economic_community",
    },
    {
      label: "OH",
      value: "OH",
      thirdPartyVatType: "european_economic_community",
    },
    {
      label: "IV",
      value: "IV",
      thirdPartyVatType: "european_economic_community",
    },
  ],
};

export const getInvoiceNatures: () => Promise<{
  content: Array<IInvoiceNature>;
}> = () => Promise.resolve(invoiceNature);
