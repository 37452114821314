import React, { useMemo, useCallback, useEffect, useContext } from "react";

import {
  Tooltip,
  Button,
  Switch,
  Table,
  Modal,
  Form,
  Input,
  Row,
  Col,
  Select,
  notification,
} from "antd";
import {
  CheckOutlined,
  CloseOutlined,
  DownloadOutlined,
} from "@ant-design/icons";

import { ModalProps } from "../../../hooks/useModal";

import { IKyc, saveKyc } from "../../../services/kycs";
import {
  downloadFile,
  setFileAttachable,
  IFile,
} from "../../../services/files";

import { UserContext } from "../../../components/auth";
import DatePickerMillis from "../../DatePickerMillis";
import FileSizeLabel from "../../FileSizeLabel";
import UploadDropZone from "../../UploadDropZone";

interface KycModalProps extends ModalProps {
  kyc: IKyc | null;
  dictionaries: Record<
    string,
    { content: Array<any>; loading: boolean; error: boolean }
  >;
  onAfterAction: Function;
}

const KycModal: React.FC<KycModalProps> = ({
  visible,
  onOk,
  onCancel,
  kyc,
  dictionaries,
  onAfterAction,
}) => {
  const [form] = Form.useForm();
  const { user } = useContext(UserContext);
  const { role } = user;

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(kyc);
  }, [form, kyc]);

  const { companies, kycTypes, kycAuthenticationTypes, status, alertPeriods } =
    dictionaries;

  const handleFinish = useCallback(
    (value) => {
      const entity: IKyc = {
        ...kyc,
        ...value,
      };

      saveKyc(entity)
        .then(() => {
          onOk(null);
          notification.success({ message: "Document saved" });
          onAfterAction();
        })
        .catch((err) => {
          console.error(err);
          notification.error({
            message:
              role === "OBSERVER"
                ? "Cannot modify record"
                : "Something went wrong",
          });
        });
    },

    [kyc, onAfterAction, onOk, role],
  );

  const handleDownload = useCallback((file) => {
    downloadFile(file!).then((buffer) => {
      const url = window.URL.createObjectURL(new Blob([buffer]));
      const link = window.document.createElement("a");
      link.href = url;
      link.setAttribute("download", file!.fileName);
      window.document.body.appendChild(link);
      link.click();
      link.parentNode!.removeChild(link);
    });
  }, []);

  const handleChangeAttachmentTag = useCallback(
    (index: number, file: IFile, attachable: boolean) => {
      setFileAttachable(file, attachable).then(() => {
        form.setFieldsValue({
          files: form.getFieldValue("files").map((val: IFile, idx: number) => ({
            ...val,
            attachable: idx === index ? attachable : val.attachable,
          })),
        });
      });
    },
    [form],
  );

  const fileColumns = useMemo(
    () => [
      {
        title: "File name",
        dataIndex: "fileName",
        key: "fileName",
      },
      {
        title: "File size",
        dataIndex: "fileSize",
        key: "fileSize",
        render: (size: number) => <FileSizeLabel size={size} />,
      },
      {
        title: "Created by",
        dataIndex: "createdBy",
        key: "createdBy",
      },
      {
        title: "Created at",
        dataIndex: "createdAt",
        key: "createdAt",
        render: (createdAt: string) => (
          <>{new Date(parseInt(createdAt, 10)).toLocaleDateString()}</>
        ),
      },
      {
        title: "Attach to email",
        dataIndex: "attachable",
        key: "attachable",
        shouldCellUpdate: (current: IFile, prev: IFile) => true,
        render: (value: boolean, record: IFile, index: number) => {
          return (
            <>
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                checked={value}
                // disabled={index === 0}
                onClick={(val) => handleChangeAttachmentTag(index, record, val)}
              />
            </>
          );
        },
      },
      {
        title: "Actions",
        dataIndex: "_actions",
        key: "_actions",
        render: (_value: string, record: IKyc) => {
          return (
            <Tooltip title="Download file">
              <Button
                type="text"
                size="small"
                icon={<DownloadOutlined />}
                onClick={() => handleDownload(record)}
              />
            </Tooltip>
          );
        },
      },
    ],
    [handleChangeAttachmentTag, handleDownload],
  );

  return (
    <Form
      onFinish={handleFinish}
      form={form}
      id="KycModalForm"
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 16 }}
    >
      <Modal
        title="KYC"
        visible={visible}
        okButtonProps={{ htmlType: "submit", form: "KycModalForm" }}
        onCancel={onCancel}
        width={1600}
      >
        <Row>
          <Col span={12}>
            <Form.Item
              rules={[{ required: true, message: "Required Field" }]}
              label="Document date"
              name="documentDate"
            >
              <DatePickerMillis />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              rules={[{ required: true, message: "Required Field" }]}
              label="Concern Entity"
              name={["documentTo", "id"]}
            >
              <Select loading={companies.loading}>
                {companies.content.map(
                  (element: { id: number; name: string }) => (
                    <Select.Option value={element.id} key={element.id}>
                      {element.name}
                    </Select.Option>
                  ),
                )}
              </Select>
            </Form.Item>
          </Col>

          {/* <Col span={12}>
            <Form.Item
              rules={[{ required: true, message: "Required Field" }]}
              label="Company"
              name={["company", "id"]}
            >
              <Select loading={companies.loading}>
                {companies.content.map(
                  (element: { id: number; name: string }) => (
                    <Select.Option value={element.id} key={element.id}>
                      {element.name}
                    </Select.Option>
                  ),
                )}
              </Select>
            </Form.Item>
          </Col> */}

          {/* <Col span={12}>
            <Form.Item label="Category" name="kycCategory">
              <Select>
                {kycCategories.content.map(
                  (element: { value: string; label: string }) => (
                    <Select.Option value={element.value} key={element.value}>
                      {element.label}
                    </Select.Option>
                  ),
                )}
              </Select>
            </Form.Item>
          </Col> */}
          <Col span={12}>
            <Form.Item label="Type" name="kycType">
              <Select>
                {kycTypes.content.map(
                  (element: { value: string; label: string }) => (
                    <Select.Option value={element.value} key={element.value}>
                      {element.label}
                    </Select.Option>
                  ),
                )}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Authentication" name="kycAuthentication">
              <Select>
                {kycAuthenticationTypes.content.map(
                  (element: { value: string; label: string }) => (
                    <Select.Option value={element.value} key={element.value}>
                      {element.label}
                    </Select.Option>
                  ),
                )}
              </Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label="Purpose" name="purpose">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Tags" name="tags">
              <Input />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label="Linked Parties" name="otherParties">
              <Input />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label="Status" name="status">
              <Select>
                {status.content.map(
                  (element: { value: string; label: string }) => (
                    <Select.Option value={element.value} key={element.value}>
                      {element.label}
                    </Select.Option>
                  ),
                )}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Next Important Date" name="nextImportantDate">
              <DatePickerMillis />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Expiry Date" name="maturityDate">
              <DatePickerMillis />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label="Alert Period" name="alertPeriod">
              <Select>
                {alertPeriods.content.map(
                  (element: { value: string; label: string }) => (
                    <Select.Option value={element.value} key={element.value}>
                      {element.label}
                    </Select.Option>
                  ),
                )}
              </Select>
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              label="Comments"
              name="comments"
              labelCol={{ span: 3 }}
              wrapperCol={{ span: 20 }}
            >
              <Input.TextArea rows={5} />
            </Form.Item>
          </Col>
        </Row>

        <UploadDropZone action={`/documents/kycs/${kyc?.id}/upload`} />

        <Form.Item
          shouldUpdate
          labelCol={{ span: 0 }}
          wrapperCol={{ span: 24 }}
        >
          {() => (
            <Table
              columns={fileColumns}
              rowKey="id"
              dataSource={form.getFieldValue("files") || []}
              className="pf-table-auto"
            />
          )}
        </Form.Item>
      </Modal>
    </Form>
  );
};

export default KycModal;
