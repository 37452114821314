import { useState, useCallback, useMemo } from "react";

export default function useRefresh() {
  const [token, setToken] = useState(0);

  const refresh: Function = useCallback(() => {
    setToken((prev) => prev + 1);
  }, []);

  const wrapper = useMemo<[number, Function]>(
    () => [token, refresh],
    [token, refresh],
  );

  return wrapper;
}
