import { criteriaQuery } from "../utils/query";

import {
  IDataSourceFetchFunc,
  IDataSourceRequest,
} from "./../hooks/useDataSource";

export interface ICompany {
  name?: string;
  id?: string;
  tags: string;
  group: string;
  legalForm: string;
  tradeRegister: string;
  vat: string;
  tin: string;
  otherPermits: string;
  address: string;
  country: string;
  comment: string;
}

export const getCompanies: IDataSourceFetchFunc<ICompany> = (
  req: IDataSourceRequest,
) =>
  fetch(
    `${process.env.REACT_APP_BACKEND_URL}/companies/?${criteriaQuery(req)}`,
    {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    },
  ).then((res) => (res.ok ? res.json() : Promise.reject(res)));

export const createCompany: (companies: any) => Promise<ICompany> = (company) =>
  fetch(`${process.env.REACT_APP_BACKEND_URL}/companies/`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    mode: "cors",
    credentials: "include",
    body: JSON.stringify(company),
  }).then((res) => (res.ok ? res.json() : Promise.reject(res)));

export const saveCompany: (company: ICompany) => Promise<ICompany> = (
  company,
) =>
  fetch(`${process.env.REACT_APP_BACKEND_URL}/companies/${company.id}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    mode: "cors",
    credentials: "include",
    body: JSON.stringify(company),
  }).then((res) => (res.ok ? res.json() : Promise.reject(res)));

export const deleteCompany: (company: ICompany) => Promise<void> = (company) =>
  fetch(`${process.env.REACT_APP_BACKEND_URL}/companies/${company.id}/delete`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    mode: "cors",
    credentials: "include",
  }).then((res) => (res.ok ? Promise.resolve() : Promise.reject(res)));

export const exportCsv = () =>
  fetch(`${process.env.REACT_APP_BACKEND_URL}/companies/csv`, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: { "Content-Type": "application/octet-stream" },
  }).then((res) => (res.ok ? res.arrayBuffer() : Promise.reject(res)));
