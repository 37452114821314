import React, { useState, useCallback, useMemo, useEffect } from "react";

import { Space, Table, Divider, Button, Tooltip } from "antd";
import { AuditOutlined, WarningOutlined } from "@ant-design/icons";

import useDatasource from "../../../hooks/useDataSource";

import useModal from "../../../hooks/useModal";

import { IKyc, getKycsByStatuses } from "../../../services/kycs";
import AuditModal from "../AuditModal";

import DictionaryLabel from "../../DictionaryLabel";
import { DownloadOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

interface KycsListProps {
  status: string;
  dictionaries: Record<string, any>;
  shouldRefresh: number;
  onRowDblClick: Function;
  rowSelection: any;
}

const GenericKycs: React.FC<KycsListProps> = ({
  status,
  dictionaries,
  shouldRefresh,
  onRowDblClick,
  rowSelection,
}) => {
  const { loading, pagination, content, handleChange, reload } =
    useDatasource<IKyc>(getKycsByStatuses(status));

  const { t } = useTranslation();

  useEffect(() => {
    if (shouldRefresh > 0) {
      reload();
    }
  }, [reload, shouldRefresh]);

  const [showAuditModal, auditModalProps] = useModal();
  const [auditModalParams, setAuditModalParams] = useState({
    id: null,
    documentType: null,
  });
  const onAudit = useCallback(
    (kycId: any) => {
      //@ts-ignore
      if (showAuditModal && setAuditModalParams) {
        //@ts-ignore
        setAuditModalParams({ id: +kycId, documentType: "kyc" });
        showAuditModal();
      }
    },
    [showAuditModal, setAuditModalParams],
  );

  const showAlertIcon = (value: string) => {
    if (value && value.endsWith(" alert")) {
      return (
        <>
          {/* <Tooltip title="Alert"> */}
          <WarningOutlined
            style={{
              fontSize: "16px",
              color: "#f5222d",
              marginRight: ".5rem",
            }}
          />
          {/* </Tooltip> */}
          <span>{value.substring(0, value.length - 5)}</span>
        </>
      );
    }
    return value;
  };

  const columns = useMemo(
    () => [
      {
        title: "File name",
        dataIndex: ["files", 0, "fileName"],
        key: "files.0.fileName",
        width: 70,
        render: (value: string, record: IKyc) => {
          return (
            <Tooltip title={value}>
              <Button
                type="link"
                onClick={() =>
                  window.open(`/documents/kyc/preview/${record.id}`)
                }
              >
                <DownloadOutlined style={{ color: "black" }} />
              </Button>
            </Tooltip>
          );
        },
      },
      {
        title: "Concern Entity",
        dataIndex: ["documentTo", "id"],
        key: "documentTo",
        render: (value: string) => (
          <DictionaryLabel dictionary={dictionaries.companies} value={value} />
        ),
      },

      {
        title: "Type",
        dataIndex: "kycType",
        key: "kycType",
        render: (value: string) => (
          <DictionaryLabel
            dictionary={dictionaries.kycTypes}
            value={value}
            keyField="value"
            labelField="label"
          />
        ),
      },

      {
        title: "Authentication",
        dataIndex: "kycAuthentication",
        key: "kycAuthentication",
        render: (value: string) => (
          <DictionaryLabel
            dictionary={dictionaries.kycAuthenticationTypes}
            value={value}
            keyField="value"
            labelField="label"
          />
        ),
      },

      {
        title: "Purpose",
        dataIndex: "purpose",
        key: "purpose",
      },

      // {
      //   title: "Document date",
      //   dataIndex: "documentDate",
      //   key: "documentDate",
      //   render: (value: any) => {
      //     return value
      //       ? new Date(parseInt(value, 10)).toLocaleDateString()
      //       : "";
      //   },
      // },
      // {
      //   title: "Category",
      //   dataIndex: "kycCategory",
      //   key: "kycCategory",
      //   render: (value: string) => (
      //     <DictionaryLabel
      //       dictionary={dictionaries.kycCategories}
      //       value={value}
      //       keyField="value"
      //       labelField="label"
      //     />
      //   ),
      // },

      // {
      //   title: "Company",
      //   dataIndex: ["company", "id"],
      //   key: "company",
      //   render: (value: string) => (
      //     <DictionaryLabel dictionary={dictionaries.companies} value={value} />
      //   ),
      // },

      //   {
      //     title: "Other Entities",
      //     dataIndex: ["otherEntities", "id"],
      //     key: "otherEntities",
      //     render: (value: string) => (
      //       <DictionaryLabel dictionary={companies} value={value} />
      //     ),
      //   },
      // {
      //   title: "Tags",
      //   dataIndex: "tags",
      //   key: "tags",
      // },

      // {
      //   title: "Linked parties",
      //   dataIndex: "otherParties",
      //   key: "otherParties",
      // },

      {
        title: "Next Important Date",
        dataIndex: "nextImportantDate",
        key: "nextImportantDate",
        render: (value: any) => {
          return value
            ? new Date(parseInt(value, 10)).toLocaleDateString()
            : "";
        },
      },

      {
        title: "Expiry Date",
        dataIndex: "maturityDate",
        key: "maturityDate",
        render: (value: any) => {
          return value
            ? new Date(parseInt(value, 10)).toLocaleDateString()
            : "";
        },
      },
      {
        title: "Countdown",
        key: "_daysToExpiry",
        dataIndex: "_daysToExpiry",
        render: (value: string) => {
          return showAlertIcon(value);
        },
      },

      // {
      //   title: "Status progress",
      //   dataIndex: "_statusProgress",
      //   key: "_statusProgress",
      // },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (value: string) => <span>{t(`document.status.${value}`)}</span>,
        // render: (value: string) => (
        //   <DictionaryLabel
        //     dictionary={status}
        //     value={value}
        //     keyField="value"
        //     labelField="label"
        //   />
        // ),
      },
      {
        title: "Actions",
        dataIndex: "actions",
        key: "actions",
        width: 100,
        render: (value: any, record: IKyc) => {
          return (
            <>
              <Space size="small">
                <Tooltip placement="bottomRight" title="Audit">
                  <Button
                    type="text"
                    aria-label="Audit"
                    onClick={() => onAudit(record.id)}
                    icon={<AuditOutlined />}
                  />
                </Tooltip>
              </Space>
            </>
          );
        },
      },
      // {
      //   title: "Comments",
      //   dataIndex: "comments",
      //   key: "comments",
      // },
    ],
    [
      dictionaries.companies,
      dictionaries.kycAuthenticationTypes,
      dictionaries.kycTypes,
      onAudit,
      t,
    ],
  );

  return (
    <>
      <Table
        columns={columns}
        rowKey="id"
        loading={loading}
        pagination={pagination}
        dataSource={content}
        onChange={handleChange}
        rowSelection={rowSelection}
        // scroll={{ x: 1800 }}
        onRow={(document: IKyc) => {
          return {
            onDoubleClick: (evt) => {
              evt.preventDefault();
              onRowDblClick(document);
            },
          };
        }}
      />
      <Divider />
      {auditModalParams?.id ? (
        <AuditModal {...{ ...auditModalProps, ...auditModalParams }} />
      ) : null}
    </>
  );
};

export default GenericKycs;
