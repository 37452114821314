import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import { UserProvider, PrivateRoute } from "./components/auth";

import {
  Forbidden,
  NotFound,
  //
  Login,
  Reset,
  //
  Dashboard,
  Upload,
  //
  Groups,
  Entities,
  Banks,
  BankAccounts,
  Contacts,
  ThirdParties,
  //
  Invoices,
  Documents,
  Loans,
  KYC,
  Preview,
  //
  Users,
  Templates,
  Settings,
  BackupAndSync,
  //
  Tenants,
  UserSettings,
  UserCreation,
  PasswordReset,
  PasswordResetEmailForm,
} from "./pages";

function App() {
  return (
    <>
      <UserProvider>
        <BrowserRouter>
          <Switch>
            <Route exact path="/" component={Login} />
            <Route exact path="/reset" component={Reset} />
            <Route exact path="/reset/:token" component={Reset} />
            <Route exact path="/forbidden" component={Forbidden} />

            <Route path="/user-creation/:token" component={UserCreation} />
            <Route path="/password-reset/:token" component={PasswordReset} />
            <Route
              path="/password-reset-email-form"
              component={PasswordResetEmailForm}
            />
            <PrivateRoute
              path="/dashboard"
              component={Dashboard}
              roles={["ADMIN", "USER", "MANAGER", "OBSERVER"]}
            />
            <PrivateRoute
              path="/upload"
              component={Upload}
              roles={["ADMIN", "USER", "MANAGER"]}
            />

            <PrivateRoute
              path="/entity/groups"
              component={Groups}
              roles={["ADMIN", "USER", "MANAGER"]}
            />
            <PrivateRoute
              path="/entity/entities"
              component={Entities}
              roles={["USER", "MANAGER", "ADMIN"]}
            />
            <PrivateRoute
              path="/entity/banks"
              component={Banks}
              roles={["ADMIN", "USER", "MANAGER"]}
            />
            <PrivateRoute
              path="/entity/accounts"
              component={BankAccounts}
              roles={["ADMIN", "USER", "MANAGER"]}
            />
            <PrivateRoute
              path="/entity/contacts"
              component={Contacts}
              roles={["ADMIN", "USER", "MANAGER"]}
            />
            <PrivateRoute
              path="/entity/thirdparty"
              component={ThirdParties}
              roles={["ADMIN", "USER", "MANAGER"]}
            />

            <PrivateRoute
              path="/documents/:type/preview/:id"
              component={Preview}
              roles={["ADMIN", "USER", "MANAGER", "OBSERVER"]}
            />

            <PrivateRoute
              path="/documents/invoices"
              component={Invoices}
              roles={["USER", "ADMIN", "MANAGER", "OBSERVER"]}
            />
            <PrivateRoute
              path="/documents/documents"
              component={Documents}
              roles={["ADMIN", "USER", "MANAGER", "OBSERVER"]}
            />
            <PrivateRoute
              path="/documents/loans"
              component={Loans}
              roles={["ADMIN", "USER", "MANAGER", "OBSERVER"]}
            />
            <PrivateRoute
              path="/documents/kyc"
              component={KYC}
              roles={["ADMIN", "USER", "MANAGER", "OBSERVER"]}
            />

            <PrivateRoute
              path="/admin/users"
              component={Users}
              roles={["ADMIN"]}
            />
            <PrivateRoute
              path="/admin/templates"
              component={Templates}
              roles={["ADMIN"]}
            />
            <PrivateRoute
              path="/admin/settings"
              component={Settings}
              roles={["ADMIN"]}
            />
            <PrivateRoute
              path="/admin/user"
              component={UserSettings}
              roles={["ADMIN", "USER", "OBSERVER", "MANAGER"]}
            />
            <PrivateRoute
              path="/admin/sync"
              component={BackupAndSync}
              roles={["ADMIN"]}
            />

            <PrivateRoute
              path="/system/tenants"
              component={Tenants}
              roles={["SUPERADMIN"]}
            />

            <Route component={NotFound} />
          </Switch>
        </BrowserRouter>
      </UserProvider>
    </>
  );
}

export default App;
