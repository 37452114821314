import React, { useCallback } from "react";

import { Card } from "antd";
import { CloudDownloadOutlined } from "@ant-design/icons";

interface DownloadBucketProps {}

const DownloadBucket: React.FC<DownloadBucketProps> = () => {
  const handleDownload = useCallback(() => {
    window.open(`${process.env.REACT_APP_BACKEND_URL}/admin/backup/download`);
  }, []);

  return (
    <Card
      actions={[
        <CloudDownloadOutlined key="download" onClick={handleDownload} />,
      ]}
    >
      <Card.Meta
        title="Download"
        description="Download all documents"
        style={{ height: 120 }}
      />
    </Card>
  );
};

export default DownloadBucket;
