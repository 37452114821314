import React, { useEffect, useContext } from "react";

import { Form, Input, Row, Col, PageHeader, Button, notification } from "antd";
import useRefresh from "../../../hooks/useRefresh";
import { UserContext } from "../../../components/auth";
import { updateUserData } from "../../../services/users";

const UserData = () => {
  const [dataForm] = Form.useForm();
  const [, refresh] = useRefresh();
  const { user } = useContext(UserContext);

  useEffect(() => {
    dataForm.resetFields();
    dataForm.setFieldsValue({
      fullName: user.fullname,
    });
  }, [dataForm, user.fullname]);

  const onFinishDataForm = (values: any) => {
    if (values.newData !== values.confirmNewData) {
      notification.error({ message: "New datas don't match" });
      return;
    } else {
      updateUserData(values)
        .then(() => {
          notification.success({ message: "Data successfully changed" });
          refresh();
        })
        .catch(() => notification.error({ message: "Could not change data" }));
    }
  };

  return (
    <>
      <Form
        form={dataForm}
        id="userForm"
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 16 }}
        onFinish={onFinishDataForm}
      >
        <PageHeader
          className="site-page-header"
          title="User Information"
          extra={[
            <Button htmlType="submit" type="primary">
              Update User Information
            </Button>,
          ]}
        />

        <Row>
          <Col span={14}>
            <Form.Item
              rules={[{ required: true, message: "Required Field" }]}
              label="Full Name"
              name="fullName"
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default UserData;
