export const hasPermission = (role: string, required: string[]) => {
  if (!Array.isArray(required) || required.length === 0) {
    return true;
  }

  return required.includes(role);
};

export const currentDateAsString = `${new Date(new Date().getTime())
  .toLocaleDateString()
  .replaceAll("/", "-")}`;
