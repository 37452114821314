import React from "react";
import EmailTemplatesList from "../../components/admin/EmailTemplatesList";

import { Default } from "../../layouts";

const Templates = () => {
  return (
    <Default>
      <EmailTemplatesList />
    </Default>
  );
};

export default Templates;
