export interface IDocumentType {
  value: string;
  label: string;
}

const documentTypes = {
  content: [
    {
      label: "Corporate Administration",
      value: "Corporate Administration",
    },
    { label: "Intragroup", value: "Intragroup" },
    { label: "Legal", value: "Legal" },
    { label: "Letter", value: "Letter" },
    { label: "Bank", value: "Bank" },
    { label: "Other", value: "Other" },
  ],
};

export const getDocumentTypes: () => Promise<{
  content: Array<IDocumentType>;
}> = () => Promise.resolve(documentTypes);
