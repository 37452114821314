import React, { useEffect, useCallback, useState } from "react";

import {
  Form,
  Row,
  Col,
  Select,
  Input,
  Button,
  Alert,
  notification,
  Divider,
  Collapse,
} from "antd";

import ReactQuill from "react-quill";

import useDictionaries from "../hooks/useDictionaries";
import useRefresh from "../hooks/useRefresh";

// import { getEmailTemplates } from "../services/email-templates";
import { getContactsByType } from "../services/contacts";
import { sendEmail } from "../services/email";

import UploadDropZone from "./UploadDropZone";

import "react-quill/dist/quill.snow.css";

const dictionaries = {
  // templates: getEmailTemplates,
  contacts: getContactsByType("all"),
};

interface SendMailFormProps {
  type: string;
  scope: string;
  documents: Array<React.Key>;
  onMailSent?: () => void;
  onAfterMailSent?: () => void;
  to: Array<object>;
  cc: Array<object>;
  templates: any;
  labelTo: string;
  labelCC: string;
}

const SendMailForm: React.FC<SendMailFormProps> = ({
  type,
  scope,
  documents,
  onMailSent,
  onAfterMailSent,
  to,
  cc,
  templates,
  labelTo = "To: (Information)",
  labelCC = "Cc: (Information)",
}) => {
  const { Panel } = Collapse;

  const [filteredTo, setFilteredTo] = useState(to);
  const [form] = Form.useForm();
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [shouldReset, resetForm] = useRefresh();
  const [dicts] = useDictionaries(dictionaries);
  const [fileList, setFileList] = useState<Record<string, any>>({});
  const { contacts } = dicts;

  // const filteredTemplates = contacts.content.filter((t: any) => {
  //   if (template === "validate") {
  //     return t.scope === "VALIDATION";
  //   } else if (template === "progress") {
  //     return t.scope === "EXECUTION" || t.scope === "INFORMATION";
  //   } else if (template === "generic") {
  //     return t.scope === "EXECUTION";
  //   }
  //   return t.scope === "INFORMATION";
  // });

  useEffect(() => {
    setErrors({});
    form.setFieldsValue({
      subject: "",
      body: "",
      to: [],
      cc: [],
      template: undefined,
    });
  }, [form, shouldReset]);

  useEffect(() => {
    setErrors((errors) => {
      const { documents, ...rest } = errors;
      return rest;
    });
  }, [documents]);

  const handleChangeTemplate = useCallback(
    (value) => {
      const template = templates.find((template: any) => template.id === value);
      const templateScope = template.scope.split(",");
      const filteredContacts = to.filter((recipient) =>
        // // @ts-ignore
        // recipient.scope.split(",").includes(ttemplateScope),
        //@ts-ignore
        recipient.scope.split(",").some((r) => templateScope.includes(r)),
      );
      setFilteredTo(filteredContacts);
      form.setFieldsValue({ to: [] });

      setErrors((errors) => {
        const { body, subject, ...rest } = errors;
        return rest;
      });

      if (template) {
        const { emailSubject, emailBody } = template;
        form.setFieldsValue({
          body: emailBody,
          subject: emailSubject,
        });
      } else {
        form.setFieldsValue({
          subject: "",
          body: "",
        });
      }
    },
    [form, templates, to],
  );

  const handleSendMail = useCallback(
    (value) => {
      const { body, cc, subject, template, to } = value;

      const payload = {
        template: parseInt(template, 10),
        subject,
        body,
        to: (to || []).map((v: string) => parseInt(v, 10)),
        cc: (cc || []).map((v: string) => parseInt(v, 10)),
        documents,
      };

      sendEmail(
        type,
        scope,
        payload,
        fileList.map(
          (file: Record<string, any>) => file.originFileObj,
        ) as Array<File>,
      ).then(
        (_res) => {
          resetForm();

          notification.success({ message: "Mail sent" });
          if (typeof onMailSent === "function") {
            onMailSent();
          }
          if (typeof onAfterMailSent === "function") {
            // cleares selected rows inside the parent component
            onAfterMailSent();
          }
        },
        (err) => {
          setErrors(err);
        },
      );
    },
    [documents, type, scope, fileList, resetForm, onMailSent, onAfterMailSent],
  );

  const handleFileListChange = useCallback((fileList) => {
    setFileList(fileList);
  }, []);

  const handleFieldsChange = useCallback((fields) => {
    if (!Array.isArray(fields)) {
      return;
    }

    setErrors((errors) =>
      fields
        .flatMap((field) => field.name)
        .reduce((acc, key) => {
          const { [key]: val, ...rest } = acc;
          return rest;
        }, errors),
    );
  }, []);

  return (
    <div style={{ paddingBottom: 25 }}>
      <Collapse>
        <Panel header="Send Mail Form" key="1">
          <Form
            onFinish={handleSendMail}
            form={form}
            onFieldsChange={handleFieldsChange}
            id="SendMailForm"
            style={{ marginBottom: 24 }}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 16 }}
          >
            <Row>
              <Col span={24}>
                <Form.Item
                  label="Email template"
                  name="template"
                  validateStatus={errors["template"] ? "error" : ""}
                  help={errors["template"]}
                >
                  <Select
                    loading={templates.loading}
                    onChange={handleChangeTemplate}
                    // allowClear
                  >
                    {templates.map(
                      ({ id, name }: { id: number; name: string }) => (
                        <Select.Option value={id} key={id}>
                          {name}
                        </Select.Option>
                      ),
                    )}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  label="To"
                  name="to"
                  validateStatus={errors["to"] ? "error" : ""}
                  help={errors["to"]}
                >
                  <Select
                    loading={contacts.loading}
                    mode="multiple"
                    allowClear
                    placeholder={labelTo}
                  >
                    {filteredTo &&
                      filteredTo.map(
                        //@ts-ignore
                        ({
                          id,
                          firstName,
                          lastName,
                          email,
                        }: {
                          id: number;
                          firstName: string;
                          lastName: string;
                          email: string;
                        }) => (
                          <Select.Option value={id} key={id}>
                            {firstName} {lastName} &lt;{email}&gt;
                          </Select.Option>
                        ),
                      )}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  label="CC"
                  name="cc"
                  validateStatus={errors["cc"] ? "error" : ""}
                  help={errors["cc"]}
                >
                  <Select
                    loading={contacts.loading}
                    mode="multiple"
                    allowClear
                    placeholder={labelCC}
                  >
                    {cc &&
                      cc.map(
                        // @ts-ignore
                        ({
                          id,
                          firstName,
                          lastName,
                          email,
                          groups,
                        }: {
                          id: number;
                          firstName: string;
                          lastName: string;
                          email: string;
                          groups: string;
                        }) => (
                          <Select.Option value={id} key={id}>
                            {firstName} {lastName} &lt;{email}&gt; {groups}
                          </Select.Option>
                        ),
                      )}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  label="Email subject"
                  name="subject"
                  validateStatus={errors["subject"] ? "error" : ""}
                  help={errors["subject"]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  label="Email body"
                  name="body"
                  validateStatus={errors["body"] ? "error" : ""}
                  help={errors["body"]}
                >
                  <ReactQuill
                    theme="snow"
                    style={{ height: "200px", marginBottom: "43px" }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <UploadDropZone
              action={null}
              selectable
              shouldReset={shouldReset}
              onFileListChange={handleFileListChange}
            />
            <Divider />
            <Button type="primary" htmlType="submit">
              Send mail
            </Button>

            {errors["documents"] && (
              <Alert message="No documents selected" type="error" />
            )}
          </Form>
        </Panel>
      </Collapse>
    </div>
  );
};

export default SendMailForm;
