import React, {
  useMemo,
  useCallback,
  useState,
  useContext,
  useEffect,
} from "react";

import { Upload, Button, PageHeader, Table, message } from "antd";
import { TableRowSelection } from "antd/lib/table/interface";

import useDatasource from "../../../hooks/useDataSource";
import useModal from "../../../hooks/useModal";
import * as yup from "yup";

import { DownloadOutlined } from "@ant-design/icons";
import {
  //@ts-ignore
  getInvoiceById,
  getInvoicesByStatus,
  IInvoice,
  processInvoice,
  exportCsv,
  processInvoicesByTags,
} from "../../../services/invoices";

import useInvoicesColumns from "./useInvoicesColumns";
import InvoiceModal from "./InvoiceModal";
import AuditModal from "../AuditModal";
import { currentDateAsString } from "../../../utils/helpers";
import { DictsContext } from "../../../hooks/useDictionaries";

const schema = yup.object().shape({
  documentType: yup.mixed().required("required field"),
  currency: yup.mixed().required("required field"),
  payingMode: yup.mixed().required("required field"),
  documentFrom: yup.mixed().required("required field"),
  documentTo: yup.mixed().required("required field"),
  payingAgent: yup.mixed().required("required field"),
  payingBank: yup.mixed().required("required field"),
});

interface NewInvoicesListProps {
  csvProps: any;
}

const NewInvoicesList: React.FC<NewInvoicesListProps> = ({
  // dicts,
  csvProps,
}) => {
  const [filterValue, setFilterValue] = useState({});
  const { dicts } = useContext(DictsContext);
  const { loading, pagination, content, handleChange, reload } =
    useDatasource<IInvoice>(getInvoicesByStatus("new"));

  const [selectedInvoice, setSelectedInvoice] = useState<IInvoice | null>(null);
  const [show, modalProps] = useModal(reload);
  const [showAuditModal, auditModalProps] = useModal();
  const [auditModalParams, setAuditModalParams] = useState({
    id: null,
    documentType: null,
  });

  const [statusList, setStatusList] = useState([] as object[]);

  useEffect(() => {
    setStatusList(
      content.map((invoice: IInvoice) => ({ id: invoice.id, status: 0 })),
    );
  }, [content]);

  const onStatusChange = useCallback(
    (row) => {
      const { id } = row;

      const currentInvoice = statusList.find((inv: any) => inv.id === id);
      const filteredStatusList = statusList.filter((inv: any) => inv.id !== id);

      if (!currentInvoice) {
        return;
      }
      const updatedStatusList = [
        ...filteredStatusList,
        {
          ...currentInvoice,
          // @ts-ignore
          status: currentInvoice.status !== 2 ? currentInvoice.status + 1 : 0,
        },
      ];
      setStatusList(updatedStatusList);
    },
    [statusList],
  );

  const columnProps = useMemo(
    () => ({
      dictionaries: dicts,
      onPreviewDocument: (invoice: IInvoice) =>
        window.open(`/documents/invoice/preview/${invoice.id}`),
      hiddenColumns: ["validation_process", "eyes"],
      gridType: "invoiceStatus.validation",
      userRightsStatus: "new",
      showAuditModal,
      fourEyesScope: "new",
      setAuditModalParams,
      setFilterValue,
      filterValue,
      statusList,
      onStatusChange,
      reload,
    }),
    [dicts, filterValue, onStatusChange, showAuditModal, statusList, reload],
  );

  const columns = useInvoicesColumns(columnProps);

  const [failedRows, setFailedRows] = useState<Array<number>>([]);
  const [selectedRows, setSelectedRows] = useState<Array<React.Key>>([]);

  const rowSelection: TableRowSelection<IInvoice> = {
    selectedRowKeys: selectedRows,
    columnTitle: <>Process</>,
    columnWidth: 80,
    onChange: (selectedRowKeys: React.Key[]) => {
      setSelectedRows(selectedRowKeys);
    },
  };

  const handleProcess = useCallback(async () => {
    if (selectedRows.length === 0) {
      return;
    }
    const selectedInvoices = content.filter((c: IInvoice) =>
      selectedRows.includes(c.id!),
    );
    const validationResult = await Promise.allSettled(
      selectedInvoices.map((invoice) => schema.validate(invoice)),
    );
    const failedRows = validationResult
      .filter((v) => v.status === "rejected")
      .map((v) => (v as PromiseRejectedResult).reason.value?.id);

    setFailedRows(failedRows);

    if (failedRows.length > 0) {
      return;
    }

    try {
      await Promise.allSettled(
        selectedRows.map((id) =>
          processInvoice({ id: Number(id) }, "validate"),
        ),
      );
      const tags = Array.from(
        new Set(
          selectedInvoices.flatMap(
            (i) => i.tags && i.tags.map((t: { id: number }) => t.id),
          ),
        ),
      );
      if (tags && tags.length > 0) {
        processInvoicesByTags(tags, "validate").then(() => reload());
      } else {
        reload();
      }
    } catch (e) {
      message.error(`Failed sending invoices to validation`);
    }
  }, [content, reload, selectedRows]);

  return (
    <>
      <PageHeader
        onBack={() => window.history.back()}
        title="New Invoices"
        extra={[
          <Button key="0" type="primary" onClick={handleProcess}>
            Send to Validation
          </Button>,
          <Upload key="1" {...csvProps}>
            {/* <Button key="1" icon={<UploadOutlined />}>
              Upload CSV
            </Button> */}
          </Upload>,
          <Button
            key="2"
            icon={<DownloadOutlined />}
            onClick={() =>
              exportCsv().then((buffer) => {
                const url = window.URL.createObjectURL(new Blob([buffer]));
                const link = window.document.createElement("a");
                link.href = url;
                link.setAttribute(
                  "download",
                  `${currentDateAsString}"invoices-as-csv.csv`,
                );

                window.document.body.appendChild(link);
                link.click();
                link.parentNode!.removeChild(link);
              })
            }
          >
            Download CSV
          </Button>,
        ]}
      >
        <Table
          columns={columns}
          // scroll={{ y: 580 }}
          rowKey="id"
          loading={loading}
          pagination={pagination}
          dataSource={content}
          onChange={handleChange}
          rowSelection={rowSelection}
          rowClassName={(record) =>
            failedRows.includes(record.id!) ? "table-row-error" : ""
          }
          onRow={(record: IInvoice) => {
            return {
              onDoubleClick: (evt) => {
                evt.preventDefault();
                getInvoiceById(record.id!).then((res: IInvoice) => {
                  setSelectedInvoice({
                    ...res,
                  } as unknown as IInvoice);
                  show();
                });
              },
            };
          }}
        />
      </PageHeader>
      <InvoiceModal {...modalProps} invoice={selectedInvoice} />
      {auditModalParams?.id ? (
        <AuditModal {...{ ...auditModalProps, ...auditModalParams }} />
      ) : null}
    </>
  );
};

export default NewInvoicesList;
