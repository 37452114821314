import React, { useCallback, useEffect } from "react";

import { Modal, Form, Input, Row, Col, Select, notification } from "antd";
import { ModalProps } from "../../hooks/useModal";
import {
  createEmailTemplate,
  IEmailTemplate,
  updateEmailTemplate,
} from "../../services/email-templates";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

interface EmailTemplateModalProps extends ModalProps {
  emailTemplate: IEmailTemplate | null;
  dictionaries: Record<
    string,
    { content: Array<any>; loading: boolean; error: boolean }
  >;
}

const EmailTemplateModal: React.FC<EmailTemplateModalProps> = ({
  visible,
  onOk,
  onCancel,
  emailTemplate,
  dictionaries,
}) => {
  const [form] = Form.useForm();

  const { groups, scopes } = dictionaries;
  useEffect(() => {
    form.resetFields();
    form.setFieldsValue({
      ...emailTemplate,
      groups: emailTemplate?.groups?.split(",").filter((v: string) => !!v),
      tags: emailTemplate?.tags?.split(",").filter((v: string) => !!v),
      scope: emailTemplate?.scope?.split(",").filter((v: string) => !!v),
    });
  }, [form, emailTemplate]);

  const handleFinish = useCallback(
    (value) => {
      const entity: IEmailTemplate = {
        ...emailTemplate,
        ...value,
      };

      (entity.id ? updateEmailTemplate : createEmailTemplate)(entity)
        .then(() => {
          notification.success({ message: "Successfully saved" });
          onOk(null);
        })
        .catch(() => {
          notification.error({ message: "Something went wrong" });
        });
    },
    [emailTemplate, onOk],
  );

  return (
    <Form
      onFinish={handleFinish}
      form={form}
      id="EmailTemplateModalForm"
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 16 }}
    >
      <Modal
        title="Email template details"
        visible={visible}
        okButtonProps={{ htmlType: "submit", form: "EmailTemplateModalForm" }}
        onCancel={onCancel}
        width={1600}
      >
        <Row>
          <Col span={12}>
            <Form.Item
              rules={[{ required: true, message: "Required Field" }]}
              label="Groups"
              name="groups"
            >
              <Select mode="multiple" loading={groups.loading}>
                {groups.content.map((group: { id: number; name: string }) => (
                  <Select.Option value={group.id} key={group.id}>
                    {group.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              rules={[{ required: true, message: "Required Field" }]}
              label="Name"
              name="name"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              rules={[{ required: true, message: "Required Field" }]}
              label="Email subject"
              name="emailSubject"
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              rules={[{ required: true, message: "Required Field" }]}
              label="Scope"
              name="scope"
            >
              <Select mode="multiple" loading={scopes.loading}>
                {scopes.content.map(
                  (scope: { label: string; value: string }) => (
                    <Select.Option value={scope.value} key={scope.value}>
                      {scope.label}
                    </Select.Option>
                  ),
                )}
              </Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label="Tags" name="tags">
              <Select mode="tags" style={{ width: "100%" }}></Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Email body"
              name="emailBody"
              labelCol={{ span: 2 }}
              wrapperCol={{ span: 20 }}
              initialValue={""}
            >
              <ReactQuill
                theme="snow"
                style={{ height: "40vh", marginBottom: "43px" }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Modal>
    </Form>
  );
};

export default EmailTemplateModal;
