export interface IAccountType {
  label: string;
  value: string;
}

const accountTypes = {
  content: [
    { label: "Bank", value: "bank" },
    { label: "General Ledger", value: "general_ledger" },
    { label: "Supplier", value: "supplier" },
  ],
};

export const getAccountTypes: () => Promise<{
  content: Array<IAccountType>;
}> = () => Promise.resolve(accountTypes);
