import React from "react";
import CompaniesList from "../../components/entity/CompaniesList";

import { Default } from "../../layouts";

const Entities = () => {
  return (
    <Default>
      <CompaniesList />
    </Default>
  );
};

export default Entities;
