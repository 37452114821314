import React, { useMemo, useCallback, useEffect } from "react";

import { Button, Table, Popconfirm, Tooltip } from "antd";
// import { TableRowSelection } from "antd/lib/table/interface";
import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";

import useDatasource from "../../../hooks/useDataSource";

import {
  deleteContact,
  createContact,
  IContact,
  getContactsByType,
} from "../../../services/contacts";

import DictionaryLabel from "../../DictionaryLabel";

interface GenericContactsProps {
  contactType: string;
  dictionaries: Record<string, any>;
  shouldRefresh: number;
  onRowDblClick: Function;
}

const GenericContacts: React.FC<GenericContactsProps> = ({
  contactType,
  dictionaries,
  shouldRefresh,
  onRowDblClick,
}) => {
  const { loading, pagination, content, handleChange, reload } =
    useDatasource<IContact>(getContactsByType(contactType));

  useEffect(() => {
    if (shouldRefresh > 0) {
      reload();
    }
  }, [reload, shouldRefresh]);

  const handleDuplicate = useCallback(
    (contact: IContact) => {
      createContact({
        ...contact,
        id: null,
        firstName: "<duplicate>" + contact.firstName,
        lastName: "<duplicate>" + contact.lastName,
      }).then(() => reload());
    },
    [reload],
  );

  const { roles, categories } = dictionaries;

  const columns = useMemo(
    () => [
      {
        title: "Groups",
        dataIndex: "groups",
        key: "groups",
        // render: (text: any, row: any) =>
        //   row.groups
        //     ?.split(",")
        //     .filter((v: string) => !!v)
        //     .map((name: string) => (
        //       <>
        //         <DictionaryLabel dictionary={groups} value={name} />
        //         <span> </span>
        //       </>
        //     )),
      },
      {
        title: "First Name",
        dataIndex: "firstName",
        key: "firstName",
      },
      {
        title: "Last Name",
        dataIndex: "lastName",
        key: "lastName",
      },
      {
        title: "Scope",
        dataIndex: "scope",
        key: "scope",
        // render: (text: any, row: any) =>
        //   row.scope
        //     ?.split(",")
        //     .filter((v: string) => !!v)
        //     .map((id: number) => (
        //       <>
        //         <DictionaryLabel dictionary={scopes} value={id} />
        //         <span> </span>
        //       </>
        //     )),
      },
      {
        title: "Company",
        dataIndex: "company",
        key: "company",
      },
      {
        title: "Role",
        dataIndex: "role",
        key: "role",
        render: (value: string) => (
          <DictionaryLabel
            dictionary={roles}
            value={value}
            keyField="value"
            labelField="label"
          />
        ),
      },
      {
        title: "Category",
        dataIndex: "category",
        key: "category",
        render: (value: string) => (
          <DictionaryLabel
            dictionary={categories}
            value={value}
            keyField="value"
            labelField="label"
          />
        ),
      },
      // {
      //   title: "Title",
      //   dataIndex: "title",
      //   key: "title",
      // },
      // {
      //   title: "Email",
      //   dataIndex: "email",
      //   key: "email",
      // },
      // {
      //   title: "Phone",
      //   dataIndex: "phone",
      //   key: "phone",
      // },
      // {
      //   title: "Mobile",
      //   dataIndex: "mobile",
      //   key: "mobile",
      // },
      // {
      //   title: "Address",
      //   dataIndex: "address",
      //   key: "address",
      // },
      // {
      //   title: "Comment",
      //   dataIndex: "comment",
      //   key: "comment",
      // },
      {
        title: "Action",
        dataIndex: "",
        key: "x",
        width: 150,
        render: (text: any, row: any) => (
          <>
            <Tooltip placement="bottomRight" title="Duplicate">
              <Popconfirm
                placement="topLeft"
                title="Are you sure about duplicating this contact?"
                onConfirm={() => handleDuplicate(row)}
                okText="Yes"
                cancelText="No"
              >
                <Button type="text" icon={<PlusCircleOutlined />} />
              </Popconfirm>
            </Tooltip>
            <span> </span>
            <Tooltip placement="bottomRight" title="Delete">
              <Popconfirm
                placement="topLeft"
                title="Are you sure about deleting this contact?"
                onConfirm={() =>
                  deleteContact(row).then(() => {
                    reload();
                  })
                }
                okText="Yes"
                cancelText="No"
              >
                <Button type="text" icon={<DeleteOutlined />} />
              </Popconfirm>
            </Tooltip>
          </>
        ),
      },
    ],
    [categories, handleDuplicate, reload, roles],
  );

  // const [selectedRows, setSelectedRows] = useState<Array<React.Key>>([]);
  // const rowSelection: TableRowSelection<IContact> = {
  //   selectedRowKeys: selectedRows,
  //   onChange: (selectedRowKeys: React.Key[]) => {
  //     setSelectedRows(selectedRowKeys);
  //   },
  // };

  return (
    <Table
      columns={columns}
      rowKey="id"
      loading={loading}
      pagination={pagination}
      dataSource={content}
      onChange={handleChange}
      // rowSelection={rowSelection}
      scroll={{ x: 1300 }}
      onRow={(contact: IContact) => {
        return {
          onDoubleClick: (evt) => {
            evt.preventDefault();
            onRowDblClick(contact);
          },
        };
      }}
    />
  );
};

export default GenericContacts;
