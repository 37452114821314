import React, { useEffect, useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";

import { Col, Modal, Row, Table } from "antd";
import useDatasource from "../../hooks/useDataSource";
import { getDocumentAudit, IDocumentAudit } from "../../services/audit";

const AuditModal: React.FC<any> = ({ visible, onCancel, documentType, id }) => {
  const { loading, pagination, content, handleChange, reload } =
    useDatasource<IDocumentAudit>(getDocumentAudit(documentType, id));
  const { t } = useTranslation();

  useEffect(() => {
    reload();
  }, [reload, id, visible]);

  const parseValueForStatus = useCallback(
    (value: string) => {
      const isStatus = value?.startsWith("status: ");
      const all = isStatus && value.split("=> ", value.length);
      const dirtyLeft =
        typeof all === "object" && all.length > 0 && all[0]?.split(": ")[1];
      const left = typeof dirtyLeft === "string" && dirtyLeft.slice(0, -1);
      const right = isStatus && value.split("=> ", value.length)[1];

      if (isStatus) {
        // const translatedLeft = t(`invoiceStatusAudit.${left}`);
        // const translatedRight = t(`invoiceStatusAudit.${right}`);
        return (
          "status: " +
          t(`invoiceStatusAudit.${left}`) +
          " => " +
          t(`invoiceStatusAudit.${right}`)
        );
      }

      return value;
    },
    [t],
  );

  const columns = useMemo(
    () => [
      {
        title: "Date",
        dataIndex: "createdAt",
        key: "createdAt",
        width: 140,
        render: (value: any) => {
          return value
            ? new Date(parseInt(value, 10)).toLocaleDateString()
            : "";
        },
      },
      {
        title: "User",
        dataIndex: "createdBy",
        key: "createdBy",
      },
      {
        title: "Action",
        dataIndex: "action",
        key: "action",
      },
      {
        title: "Message",
        dataIndex: "message",
        key: "message",
        // width: 300,
        render: (value: any) => {
          return (
            <span style={{ display: "block", width: "1200px" }}>
              {parseValueForStatus(value)}
            </span>
          );
        },
      },
    ],
    [parseValueForStatus],
  );

  return (
    <Modal
      title="Change History"
      visible={visible}
      okButtonProps={{ htmlType: "submit", form: "InvoiceModalForm" }}
      onCancel={onCancel}
      onOk={onCancel}
      width={1600}
      destroyOnClose={true}
    >
      {content?.length > 0 ? (
        <Row wrap={true}>
          <Col>
            <Table
              columns={columns}
              rowKey="id"
              loading={loading}
              pagination={pagination}
              dataSource={content}
              onChange={handleChange}
            />
          </Col>
        </Row>
      ) : (
        <h3>Change history is not available for this entity</h3>
      )}
    </Modal>
  );
};

export default AuditModal;
