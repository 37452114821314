import React, { useMemo, useState, useEffect } from "react";

import { Typography, Table, Progress, Upload, Button } from "antd";
import { UploadFile } from "antd/lib/upload/interface";
import { TableRowSelection, ColumnType } from "antd/lib/table/interface";

import {
  ClearOutlined,
  DeleteOutlined,
  InboxOutlined,
} from "@ant-design/icons";

import FileSizeLabel from "./FileSizeLabel";

interface UploadDropZoneProps {
  action?: string | null;
  shouldReset?: number;
  onFileListChange?: (fileList: Record<string, any>) => any;
  onUploadComplete?: Function;
  selectable?: boolean;
}

const UploadDropZone: React.FC<UploadDropZoneProps> = ({
  selectable,
  action,
  onFileListChange,
  onUploadComplete,
  shouldReset,
}) => {
  const [fileList, setFileList] = useState<Array<UploadFile>>([]);

  useEffect(() => {
    setFileList([]);
  }, [action]);

  const [selectedFiles, setSelectedFiles] = useState<Array<React.Key>>([]);

  useEffect(() => {
    if (selectable) {
      setSelectedFiles([]);
    } else {
      setFileList([]);
    }
  }, [shouldReset, selectable]);

  const rowSelection: TableRowSelection<UploadFile> = {
    selectedRowKeys: selectedFiles,
    onChange: (selectedRowKeys: React.Key[]) => {
      setSelectedFiles(selectedRowKeys);
    },
  };

  const handleRemoveFile = (index: number) => {
    setFileList((fileList) => fileList.filter((_value, idx) => idx !== index));
  };

  const columns = useMemo(
    () =>
      [
        {
          title: "File name",
          dataIndex: "name",
          key: "name",
        } as ColumnType<any>,
        {
          title: "File size",
          dataIndex: "size",
          key: "size",
          render: (size: number) => <FileSizeLabel size={size} />,
        },
      ]
        .concat(
          action == null
            ? []
            : [
                {
                  title: "Progress",
                  dataIndex: "progress",
                  key: "progress",
                  render: (progress: number, record: Record<string, any>) => (
                    <>
                      <Progress
                        percent={record.status === "done" ? 100 : progress}
                        status={
                          record.status === "error"
                            ? "exception"
                            : record.status === "done"
                            ? "success"
                            : "normal"
                        }
                      />
                      {record.status === "error" ? (
                        <Typography.Text type="danger">
                          {record.response.message}
                        </Typography.Text>
                      ) : null}
                    </>
                  ),
                },
              ],
        )
        .concat([
          {
            title: "Actions",
            dataIndex: "_actions",
            key: "actions",
            width: 100,
            render: (
              _value: any,
              record: Record<string, any>,
              index: number,
            ) => (
              <>
                {action && record.status === "error" ? (
                  <></>
                ) : action && record.status === "done" ? (
                  <></>
                ) : action ? (
                  <Button
                    type="text"
                    size="small"
                    onClick={() => handleRemoveFile(index)}
                    icon={<DeleteOutlined />}
                  />
                ) : (
                  <>
                    {record.status === "done" && (
                      <Button
                        type="text"
                        size="small"
                        onClick={() => handleRemoveFile(index)}
                        icon={<ClearOutlined />}
                      />
                    )}
                  </>
                )}
              </>
            ),
          },
        ]),
    [action],
  );

  const countDoneUploads = useMemo(() => {
    return fileList.filter((f: Record<string, any>) => f.status === "done")
      .length;
  }, [fileList]);

  useEffect(() => {
    if (countDoneUploads > 0 && typeof onUploadComplete === "function") {
      onUploadComplete();
    }
  }, [countDoneUploads, onUploadComplete]);

  useEffect(() => {
    if (typeof onFileListChange === "function") {
      onFileListChange(
        selectable
          ? fileList.filter((fl) => selectedFiles.includes(fl.uid))
          : fileList,
      );
    }
  }, [fileList, onFileListChange, selectable, selectedFiles]);

  const uploaderProps = useMemo(
    () => ({
      name: "file",
      multiple: true,
      action:
        action == null
          ? ""
          : `${process.env.REACT_APP_BACKEND_URL}${action || "/upload"}`,
      beforeUpload: () => (action == null ? false : true),
      withCredentials: true,
      showUploadList: false,

      onChange(info: Record<string, any>) {
        const { fileList } = info;
        setFileList(fileList);
      },
    }),
    [action],
  );

  return (
    <>
      <Upload.Dragger {...uploaderProps} fileList={fileList}>
        <InboxOutlined />

        <Typography.Paragraph>
          Click or drag file to this area to upload
        </Typography.Paragraph>
      </Upload.Dragger>

      {fileList && fileList.length > 0 && (
        <Table
          rowKey={"uid"}
          dataSource={fileList}
          columns={columns}
          pagination={false}
          className="pf-table-auto"
          rowSelection={selectable ? rowSelection : undefined}
        />
      )}
    </>
  );
};

export default UploadDropZone;
