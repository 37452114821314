import { criteriaQuery } from "../utils/query";

import {
  IDataSourceFetchFunc,
  IDataSourceRequest,
} from "../hooks/useDataSource";

export interface IEmailTemplate {
  id?: string;
  name?: string;
  emailSubject?: string;
  emailBody?: string;
  scope?: string;
  tags?: string;
  groups?: string;
}

export const getEmailTemplates: IDataSourceFetchFunc<IEmailTemplate> = (
  req: IDataSourceRequest,
) =>
  fetch(
    `${process.env.REACT_APP_BACKEND_URL}/email-templates/?${criteriaQuery(
      req,
    )}`,
    {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    },
  ).then((res) => (res.ok ? res.json() : Promise.reject(res)));

export const createEmailTemplate: (
  emailTemplate: IEmailTemplate,
) => Promise<IEmailTemplate> = (emailTemplate) =>
  fetch(`${process.env.REACT_APP_BACKEND_URL}/email-templates/`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    mode: "cors",
    credentials: "include",
    body: JSON.stringify(emailTemplate),
  }).then((res) => (res.ok ? res.json() : Promise.reject(res)));

export const updateEmailTemplate: (
  emailTemplate: IEmailTemplate,
) => Promise<IEmailTemplate> = (emailTemplate) =>
  fetch(
    `${process.env.REACT_APP_BACKEND_URL}/email-templates/${emailTemplate.id}`,
    {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      mode: "cors",
      credentials: "include",
      body: JSON.stringify(emailTemplate),
    },
  ).then((res) => (res.ok ? res.json() : Promise.reject(res)));

export const deleteEmailTemplate: (
  emailTemplate: IEmailTemplate,
) => Promise<void> = (emailTemplate) =>
  fetch(
    `${process.env.REACT_APP_BACKEND_URL}/email-templates/${emailTemplate.id}/delete`,
    {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      mode: "cors",
      credentials: "include",
    },
  ).then((res) => (res.ok ? Promise.resolve() : Promise.reject(res)));
