export interface IUserRole {
  value: string;
  label: string;
}

const userRoles = {
  content: [
    { label: "Admin", value: "ADMIN" },
    { label: "Manager", value: "MANAGER" },
    { label: "Observer", value: "OBSERVER" },
    { label: "User", value: "USER" },
  ],
};

export const getUserRoles: () => Promise<{
  content: Array<IUserRole>;
}> = () => Promise.resolve(userRoles);
