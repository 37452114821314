import React, { useMemo, useCallback } from "react";
import { DatePicker } from "antd";
import moment, { Moment } from "moment";

const DatePickerMillis: React.FC<any> = (props) => {
  const { value, onChange, ...rest } = props;

  const updatedValue: Moment | null = useMemo(() => {
    return typeof value === "string"
      ? moment(parseInt(value, 10))
      : (value ? moment(value) : null);
  }, [value]);

  const handleOnChange = useCallback(
    (nextValue) => {
      onChange(nextValue.valueOf());
    },
    [onChange],
  );

  return (
    <DatePicker
      style={{ width: "100%" }}
      {...rest}
      value={updatedValue}
      onChange={handleOnChange}
      format={"DD.MM.YYYY"}
    />
  );
};

export default DatePickerMillis;
