export interface IFile {
  id: number;
  fileName: string;
  index?: number;
  attachable?: boolean;
  showInEmail?: boolean;
  includeInAccounting?: boolean;
}

export const downloadFile = (file: IFile) =>
  fetch(`${process.env.REACT_APP_BACKEND_URL}/upload/${file.id}`, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: { "Content-Type": "application/octet-stream" },
  }).then((res) => (res.ok ? res.arrayBuffer() : Promise.reject(res)));

export const setFileAttachable = (file: IFile, value: boolean) =>
  fetch(`${process.env.REACT_APP_BACKEND_URL}/upload/${file.id}/attachable`, {
    method: value ? "PUT" : "DELETE",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: { "Content-Type": "application/json" },
  }).then((res) => (res.ok ? Promise.resolve() : Promise.reject(res)));

export const setFileToShowInEmail = (file: IFile, value: boolean) =>
  fetch(`${process.env.REACT_APP_BACKEND_URL}/upload/${file.id}/show-in-email`, {
    method: value ? "PUT" : "DELETE",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: { "Content-Type": "application/json" },
  }).then((res) => (res.ok ? Promise.resolve() : Promise.reject(res)));
  
export const setFileToIncludeInAccounting = (file: IFile, value: boolean) =>
  fetch(`${process.env.REACT_APP_BACKEND_URL}/upload/${file.id}/include-in-accounting`, {
    method: value ? "PUT" : "DELETE",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: { "Content-Type": "application/json" },
  }).then((res) => (res.ok ? Promise.resolve() : Promise.reject(res)));
