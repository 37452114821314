import React, { useCallback, useEffect } from "react";

import { Modal, Form, Input, Row, Col, Select, notification } from "antd";
import { ModalProps } from "../../../hooks/useModal";
import {
  IThirdParty,
  createThirdParty,
  saveThirdParty,
} from "../../../services/third-parties";

interface InvoiceThirdPartyModalProps extends ModalProps {
  selectedEntityGroup: string;
  createThirdPartyOnTheFly: any;
  thirdParty: any;
  dictionaries: Record<
    string,
    { content: Array<any>; loading: boolean; error: boolean }
  >;
}

const ThirdPartyInvoiceModal: React.FC<InvoiceThirdPartyModalProps> = ({
  visible,
  setVisible,
  onCancel,
  onOk,
  thirdParty,
  selectedEntityGroup,
  createThirdPartyOnTheFly,
  dictionaries,
}) => {
  const [form] = Form.useForm();

  const { groups, countries, currencies, accountTypes, vatTypes } =
    dictionaries;

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue({
      ...thirdParty,
      groups: selectedEntityGroup,
    });
  }, [form, groups.content, selectedEntityGroup, thirdParty]);

  const handleFinish = useCallback(
    (value) => {
      const entity: IThirdParty = {
        ...thirdParty,
        ...value,
      };

      if (entity.id) {
        saveThirdParty({ ...entity })
          .then(() => {
            createThirdPartyOnTheFly(entity);
            form.resetFields();
            onOk(null);
            setVisible(false);
            notification.success({ message: "Successfully saved" });
          })
          .catch(() => {
            notification.error({ message: "Something went wrong" });
          });
      } else {
        createThirdParty(entity)
          .then((createdThirdParty) => {
            createThirdPartyOnTheFly(createdThirdParty);
            form.resetFields();
            onOk(null);
            setVisible(false);
            notification.success({ message: "Successfully saved" });
          })
          .catch(() => {
            notification.error({ message: "Something went wrong" });
          });
      }
    },
    [createThirdPartyOnTheFly, form, onOk, setVisible, thirdParty],
  );
  return (
    <Form
      onFinish={handleFinish}
      form={form}
      id="InvoiceThirdPartyModalForm"
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 16 }}
    >
      <Modal
        title="Third Party details"
        visible={visible}
        okButtonProps={{
          htmlType: "submit",
          form: "InvoiceThirdPartyModalForm",
        }}
        onCancel={onCancel}
        width={1600}
      >
        <Row>
          <Col span={12}>
            <Form.Item
              rules={[{ required: true, message: "Required Field" }]}
              label="Groups"
              name="groups"
            >
              <Select disabled loading={groups.loading}>
                {groups.content.map((group: { id: number; name: string }) => (
                  <Select.Option value={group.name} key={group.name}>
                    {group.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              rules={[{ required: true, message: "Required Field" }]}
              label="Name"
              name="name"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              rules={[{ required: true, message: "Required Field" }]}
              label="Address"
              name="address"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              rules={[{ required: true, message: "Required Field" }]}
              label="Country"
              name="country"
            >
              <Select
                showSearch
                loading={countries.loading}
                filterOption={(input, option) =>
                  option?.children.toLowerCase().indexOf(input.toLowerCase()) >=
                    0 ||
                  option?.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {countries.content.map(
                  (country: { value: string; label: string }) => (
                    <Select.Option value={country.value} key={country.value}>
                      {country.label}
                    </Select.Option>
                  ),
                )}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="City" name="city">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Postcode" name="postcode">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Corp. Info" name="corpInfo">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Bank" name="bank">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="BIC / SWIFT" name="swift">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="IBAN" name="iban">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Currency" name="currency">
              <Select loading={currencies.loading}>
                {currencies.content.map(
                  (currency: { value: string; label: string }) => (
                    <Select.Option value={currency.value} key={currency.value}>
                      {currency.label}
                    </Select.Option>
                  ),
                )}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Account Type" name="accountType">
              <Select loading={accountTypes.loading}>
                {accountTypes.content.map(
                  (acc: { value: string; label: string }) => (
                    <Select.Option value={acc.value} key={acc.value}>
                      {acc.label}
                    </Select.Option>
                  ),
                )}
              </Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label="VAT Type"
              name="vatType"
              rules={[{ required: true, message: "Required Field" }]}
            >
              <Select loading={vatTypes.loading}>
                {vatTypes.content.map(
                  (vat: { value: string; label: string }) => (
                    <Select.Option value={vat.value} key={vat.value}>
                      {vat.label}
                    </Select.Option>
                  ),
                )}
              </Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label="Chart of Acc" name="standardChartOfAccount">
              <Select mode="tags" style={{ width: "100%" }}></Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Email" name="email">
              <Input />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Comment"
              name="comment"
              labelCol={{ span: 2 }}
              wrapperCol={{ span: 20 }}
            >
              <Input.TextArea rows={5} />
            </Form.Item>
          </Col>
        </Row>
      </Modal>
    </Form>
  );
};

export default ThirdPartyInvoiceModal;
