import { criteriaQuery } from "./../utils/query";

import { IDataSourceRequest } from "./../hooks/useDataSource";

import { IFile } from "./files";

export interface IInvoice {
  id?: number;
  dateCreated?: number;
  userCreated?: string;
  documentDate?: number;
  documentType?: string;
  documentRef?: string;
  currency?: string;
  vat?: string;
  amount?: number;
  amountEur?: number;
  payingMode?: string;
  tags?: [{ id: number; name: string }];
  groups?: [{ id: number; name: string }];
  comments?: string;
  purpose?: string;
  status?: string;
  documentFrom?: { id: number; name: string, country: string };
  documentTo?: { id: number; name: string; groups: any };
  payingAgent?: { id: number; name: string };
  payingBank?: { id: number; name: string };
  company?: { id: number };
  files?: Array<IFile>;
  invoiceNature?: string;
}

const getInvoiceById = (id: number) =>
  fetch(`${process.env.REACT_APP_BACKEND_URL}/documents/invoices/${id}`, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: { "Content-Type": "application/json" },
  }).then((res) => (res.ok ? res.json() : Promise.reject(res)));

const getInvoicesByStatus = (status: string) => (req: IDataSourceRequest) =>
  fetch(
    `${
      process.env.REACT_APP_BACKEND_URL
    }/documents/invoices/${status}?${criteriaQuery(req)}`,
    {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    },
  ).then((res) => (res.ok ? res.json() : Promise.reject(res)));

const updateInvoice = (invoice: IInvoice) =>
  fetch(
    `${process.env.REACT_APP_BACKEND_URL}/documents/invoices/${invoice.id}`,
    {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "include",
      body: JSON.stringify(invoice),
      headers: { "Content-Type": "application/json" },
    },
  ).then((res) => (res.ok ? res.json() : Promise.reject(res)));

const deleteInvoiceHard = (id: number) =>
  fetch(`${process.env.REACT_APP_BACKEND_URL}/documents/invoices/${id}/hard`, {
    method: "DELETE",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: { "Content-Type": "application/json" },
  }).then((res) => (res.ok ? res : Promise.reject(res)));

const processInvoice = (invoice: IInvoice, destination: string) =>
  fetch(
    `${process.env.REACT_APP_BACKEND_URL}/documents/invoices/${invoice.id}/to/${destination}`,
    {
      method: "PUT",
      mode: "cors",
      cache: "no-cache",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    },
  ).then((res) => (res.ok ? Promise.resolve() : Promise.reject(res)));

const processInvoicesByTags = (tags: string[], destination: string) =>
  fetch(
    `${process.env.REACT_APP_BACKEND_URL}/documents/invoices/tags/${tags}/to/${destination}`,
    {
      method: "PUT",
      mode: "cors",
      cache: "no-cache",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    },
  ).then((res) => (res.ok ? Promise.resolve() : Promise.reject(res)));

const exportCsv = () =>
  fetch(`${process.env.REACT_APP_BACKEND_URL}/documents/invoices/csv`, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: { "Content-Type": "application/octet-stream" },
  }).then((res) => (res.ok ? res.arrayBuffer() : Promise.reject(res)));

const getAllInvoiceTags = () =>
  fetch(`${process.env.REACT_APP_BACKEND_URL}/documents/invoices/tags`, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: { "Content-Type": "application/json" },
  }).then((res) => (res.ok ? res.json() : Promise.reject(res)));

const getAllInvoiceGroups = () =>
  fetch(`${process.env.REACT_APP_BACKEND_URL}/documents/invoices/groups`, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: { "Content-Type": "application/json" },
  }).then((res) => (res.ok ? res.json() : Promise.reject(res)));

export {
  getInvoicesByStatus,
  updateInvoice,
  processInvoice,
  processInvoicesByTags,
  getInvoiceById,
  exportCsv,
  getAllInvoiceTags,
  getAllInvoiceGroups,
  deleteInvoiceHard,
};
