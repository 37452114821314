import React from "react";
import DocumentsList from "../../components/documents/documents/DocumentsList";

import { Default } from "../../layouts";

const Documents: React.FC = () => {
  return (
    <Default>
      <DocumentsList />
    </Default>
  );
};

export default Documents;
