import React, { useCallback, useEffect } from "react";

import { Modal, Form, Input, Row, Col, notification, Select } from "antd";
import { ModalProps } from "../../../hooks/useModal";
import { IUser, saveUser } from "../../../services/users";
import { sendMailValidationNewUser } from "../../../services/email";

interface UserModalProps extends ModalProps {
  user: IUser | null;
  dictionaries: Record<
    string,
    { content: Array<any>; loading: boolean; error: boolean }
  >;
  refresh: any;
}

const UserModal: React.FC<UserModalProps> = ({
  visible,
  onOk,
  onCancel,
  user,
  dictionaries,
  refresh,
}) => {
  const [form] = Form.useForm();

  const { userRoles } = dictionaries;

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(user);
  }, [form, user]);

  const newUser = user?.id ? false : true;

  const handleFinish = useCallback(
    (value) => {
      const entity: IUser = {
        ...user,
        ...value,
      };

      (entity.id ? saveUser : sendMailValidationNewUser)(entity)
        .then(() => {
          notification.success({ message: "Successfully saved" });
          onOk(null);
          refresh();
        })
        .catch((err) =>
          err.status === 401
            ? notification.error({
                message: "Can't duplicate this user",
              })
            : notification.error({ message: "Something went wrong" }),
        );
    },
    [onOk, refresh, user],
  );

  return (
    <Form
      onFinish={handleFinish}
      form={form}
      id="UserModalForm"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
    >
      <Modal
        title="User modal"
        visible={visible}
        okButtonProps={{ htmlType: "submit", form: "UserModalForm" }}
        onCancel={onCancel}
        width={500}
      >
        <Row>
          <Col span={20}>
            {newUser ? (
              <Form.Item
                rules={[{ required: true, message: "Required Field" }]}
                label="Email"
                name="email"
              >
                <Input />
              </Form.Item>
            ) : (
              <Form.Item
                rules={[{ required: true, message: "Required Field" }]}
                label="Full Name"
                name="fullname"
              >
                <Input />
              </Form.Item>
            )}
          </Col>

          <Col span={20}>
            <Form.Item
              label="User Role"
              name="role"
              rules={[{ required: true, message: "Required Field" }]}
            >
              <Select>
                {userRoles.content.map(
                  (element: { value: string; label: string }) => (
                    <Select.Option value={element.value} key={element.value}>
                      {element.label}
                    </Select.Option>
                  ),
                )}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Modal>
    </Form>
  );
};

export default UserModal;
