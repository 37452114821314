import { parseError } from "../utils/fetch";

export interface IMailValidation {
  email?: string;
}

export const sendEmail = (
  type: string,
  scope: string,
  payload: Record<string, any>,
  attachments: File[] | null,
) => {
  const formData = new FormData();
  formData.append("payload", JSON.stringify(payload));

  if (Array.isArray(attachments) && attachments.length > 0) {
    attachments.forEach((attachment) =>
      formData.append("files", attachment, attachment.name),
    );
  }

  return fetch(`${process.env.REACT_APP_BACKEND_URL}/emails/${type}/${scope}`, {
    method: "POST",
    mode: "cors",
    credentials: "include",
    body: formData,
  }).then((res) => (res.ok ? res.json() : parseError(res)));
};

export const sendMailValidationNewUser: (
  payload: any,
) => Promise<IMailValidation> = (payload) =>
  fetch(`${process.env.REACT_APP_BACKEND_URL}/emails/create-user`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    mode: "cors",
    credentials: "include",
    body: JSON.stringify(payload),
  }).then((res) => (res.ok ? res.json() : Promise.reject(res)));
