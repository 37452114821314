import React, { useCallback, useEffect, useState } from "react";

import { Modal, Form, Input, Row, Col, Select, notification } from "antd";
import { ModalProps } from "../../hooks/useModal";
import {
  IBankAccount,
  saveBankAccount,
  createBankAccount,
} from "../../services/bank-accounts";

interface BankAccountModalProps extends ModalProps {
  bankAccount: IBankAccount | null;
  dictionaries: Record<
    string,
    { content: Array<any>; loading: boolean; error: boolean }
  >;
}

const BankAccountModal: React.FC<BankAccountModalProps> = ({
  visible,
  onOk,
  onCancel,
  bankAccount,
  dictionaries,
}) => {
  const [form] = Form.useForm();

  const { currencies, companies, banks } = dictionaries;
  const [filteredBanks, setFilteredBanks] = useState([]);

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(bankAccount);
    //@ts-ignore
    setFilteredBanks(banks.content);
  }, [form, bankAccount, banks.content]);

  const handleFinish = useCallback(
    (value) => {
      const entity: IBankAccount = {
        ...bankAccount,
        ...value,
      };

      (entity.id ? saveBankAccount : createBankAccount)(entity)
        .then(() => {
          notification.success({ message: "Successfully saved" });
          onOk(null);
        })
        .catch(() => {
          notification.error({ message: "Something went wrong" });
        });
    },
    [bankAccount, onOk],
  );

  const handleChangeCompany = useCallback(
    (value) => {
      const selectedCompany = companies.content.find(
        (company: any) => company.id === value,
      );

      const parsedBanks = banks.content.map((bank: any) => ({
        ...bank,
        groups: bank.groups.split(","),
      }));

      const truthfulValues = parsedBanks
        .map((parsedBank: any, idx) => ({
          value: parsedBank.groups.includes(selectedCompany.groups),
          idx,
        }))
        .filter((truthfulValue: any) => truthfulValue.value === true)
        .map((el: any) => el.idx);

      const filteredBanks = parsedBanks.filter((parsedBank: any, idx) =>
        truthfulValues.includes(idx),
      );
      //@ts-ignore
      setFilteredBanks(filteredBanks);
    },
    [banks, companies.content],
  );

  return (
    <Form
      onFinish={handleFinish}
      form={form}
      id="BankAccountModalForm"
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 16 }}
    >
      <Modal
        title="Bank Account modal"
        visible={visible}
        okButtonProps={{ htmlType: "submit", form: "BankAccountModalForm" }}
        onCancel={onCancel}
        width={1600}
      >
        <Row>
          <Col span={12}>
            <Form.Item
              rules={[{ required: true, message: "Required Field" }]}
              label="Account name"
              name={["company", "id"]}
            >
              <Select
                loading={companies.loading}
                onChange={handleChangeCompany}
                allowClear
              >
                {companies.content.map(
                  (company: { id: number; name: string }) => (
                    //@ts-ignore
                    <Select.Option value={company.id} key={company.id}>
                      {company.name}
                    </Select.Option>
                  ),
                )}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              rules={[{ required: true, message: "Required Field" }]}
              label="Bank"
              name={["bank", "id"]}
            >
              <Select
              // disabled={entity.id ? false : true}
              >
                {filteredBanks.map((bank: { id: string; name: string }) => (
                  <Select.Option value={bank.id} key={bank.id}>
                    {bank.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {/* <Col span={12}>
            <Form.Item
              rules={[{ required: true, message: "Required Field" }]}
              label="Account name"
              name={["company", "id"]}
            >
              <Select loading={companies.loading}>
                {companies.content.map(
                  (company: { id: number; name: string }) => (
                    //@ts-ignore
                    <Select.Option value={company.id} key={company.id}>
                      {company.name}
                    </Select.Option>
                  ),
                )}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              rules={[{ required: true, message: "Required Field" }]}
              label="Bank"
              name={["bank", "id"]}
            >
              <Select
                // disabled={entity.id ? false : true}
                loading={banks.loading}
              >
                {banks.content.map((bank: { id: number; name: string }) => (
                  <Select.Option value={bank.id} key={bank.id}>
                    {bank.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col> */}
          <Col span={12}>
            <Form.Item
              rules={[{ required: true, message: "Required Field" }]}
              label="IBAN"
              name="iban"
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              rules={[{ required: true, message: "Required Field" }]}
              label="Currency"
              name="currency"
            >
              <Select loading={currencies.loading}>
                {currencies.content.map(
                  (country: { value: string; label: string }) => (
                    <Select.Option value={country.value} key={country.value}>
                      {country.label}
                    </Select.Option>
                  ),
                )}
              </Select>
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              label="Comment"
              name="comment"
              labelCol={{ span: 2 }}
              wrapperCol={{ span: 20 }}
            >
              <Input.TextArea rows={5} />
            </Form.Item>
          </Col>
        </Row>
      </Modal>
    </Form>
  );
};

export default BankAccountModal;
