export interface ICurrency {
  value: string;
  label: string;
}

const currencies = {
  content: [
    { value: "EUR", label: "EUR" },
    { value: "USD", label: "USD" },
    { value: "CHF", label: "CHF" },
    { value: "GBP", label: "GBP" },
    { value: "JPY", label: "JPY" },
    { value: "MUL", label: "Multi" },
    { value: "ZZZ", label: "Other" },
  ],
};

export const getCurrencies: () => Promise<{ content: Array<ICurrency> }> = () =>
  Promise.resolve(currencies);
