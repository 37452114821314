export interface IKycType {
  value: string;
  label: string;
}

const kycTypes = {
  content: [
    {
      label: "ID",
      value: "ID",
    },
    { label: "TIN", value: "TIN" },
    { label: "Proof of Address", value: "Proof of Address" },
    { label: "Reference Letter", value: "Reference Letter" },
    { label: "Company Register", value: "Company Register" },
    { label: "Shareholder Register", value: "Shareholder Register" },
    { label: "Constitutive Document", value: "Constitutive Document" },
    { label: "Legal Chart", value: "Legal Chart" },
    { label: "Source of Funds/Wealth", value: "Source of Funds/Wealth" },
    { label: "FATCA/CRS", value: "FATCA/CRS" },
  ],
};

export const getKycTypes: () => Promise<{
  content: Array<IKycType>;
}> = () => Promise.resolve(kycTypes);
