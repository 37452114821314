/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo, useContext, useState } from "react";
import { useHistory } from "react-router-dom";

import { Dropdown, Layout, Menu, message } from "antd";

import {
  MenuFoldOutlined,
  SettingOutlined,
  LineChartOutlined,
  FormOutlined,
  FileOutlined,
  CloudUploadOutlined,
  AppstoreOutlined,
  ProfileOutlined,
  SyncOutlined,
} from "@ant-design/icons";

import { UserContext } from "../components/auth";
import { syncWithDrive } from "../services/drive";

const hasPermission = (role: string, required: string[]) => {
  if (!Array.isArray(required) || required.length === 0) {
    return true;
  }

  return required.includes(role);
};

const Default: React.FC = ({ children }) => {
  const { user, tenant } = useContext(UserContext);
  const { role } = user;

  const history = useHistory();

  const handleSelect = async ({ key }: { key: string }) => {
    if (key === "sync") {
      try {
        await syncWithDrive();
        message.success("Successfully synced with Storage");
      } catch (error) {
        message.error(error);
      }
    } else {
      key && history.push(key);
    }
  };

  const userMenu = useMemo(
    () => (
      <Menu>
        {user.tenants.map((t: string[]) => (
          <Menu.Item key={`${t[0]}`}>
            <a
              className="ant-dropdown-link"
              href={`//${t[0]}.${process.env.REACT_APP_APP_DOMAIN}/dashboard`}
            >
              <span
                style={{ fontWeight: t[2] === tenant.name ? "bold" : "normal" }}
              >
                {t[2]}
              </span>
            </a>
          </Menu.Item>
        ))}
        <Menu.Divider />
        <Menu.Item key="_logout">
          <a
            className="ant-dropdown-link"
            href={`${
              process.env.REACT_APP_BACKEND_URL
            }/auth/logout?${Math.random()}`}
          >
            Logout
          </a>
        </Menu.Item>
      </Menu>
    ),
    [user, tenant],
  );

  const [collapsed, setCollapsed] = useState(true);

  return (
    <>
      <Layout>
        <Layout.Header
          style={{
            position: "fixed",
            zIndex: 1000,
            width: "100%",
            padding: 0,
          }}
        >
          <Menu
            style={{
              backgroundColor: "black",
            }}
            theme="dark"
            mode="horizontal"
            selectable={false}
          >
            <Menu.Item
              style={{ width: collapsed ? 60 : 240 }}
              key="1"
              icon={
                collapsed ? (
                  <span>
                    <img
                      src="/neobank-logo.png"
                      alt="neobank.services logo"
                      style={{ width: 30, marginRight: 5 }}
                    ></img>
                    <span
                      style={{
                        paddingLeft: "40px",
                        color: "#bfbfbf",
                        // fontSize: "120%",
                      }}
                    >
                      neobank.services
                    </span>
                  </span>
                ) : (
                  <MenuFoldOutlined />
                )
              }
              onClick={() => setCollapsed((prev) => !prev)}
            >
              {collapsed ? "" : "neobank.services"}
            </Menu.Item>
            <Menu.Item
              key="3"
              style={{
                marginLeft: "auto",
              }}
            >
              <Dropdown overlay={userMenu} trigger={["click"]}>
                <a
                  className="ant-dropdown-link"
                  onClick={(e) => e.preventDefault()}
                >
                  {user.fullname || user.email} | {tenant.name}
                </a>
              </Dropdown>
            </Menu.Item>
          </Menu>
        </Layout.Header>

        <Layout>
          <Layout.Sider
            width={240}
            collapsedWidth={60}
            collapsed={collapsed}
            style={{
              overflow: "auto",
              height: "100vh",
              position: "fixed",
              left: 0,
            }}
          >
            <Menu
              mode="inline"
              style={{
                minHeight: "100vh",
                borderRight: 0,
                paddingTop: "64px",
                overflowX: "hidden",
                overflow: "auto",
              }}
              onClick={handleSelect}
            >
              {hasPermission(role, ["SUPERADMIN"]) ? (
                <Menu.Item key="/system/tenants" icon={<AppstoreOutlined />}>
                  Tenants
                </Menu.Item>
              ) : null}

              {hasPermission(role, ["ADMIN", "USER", "MANAGER", "OBSERVER"]) ? (
                <Menu.Item key="/dashboard" icon={<LineChartOutlined />}>
                  Dashboard
                </Menu.Item>
              ) : null}

              {hasPermission(role, ["ADMIN", "USER", "MANAGER"]) ? (
                <Menu.Item key="/upload" icon={<CloudUploadOutlined />}>
                  Upload
                </Menu.Item>
              ) : null}

              <Menu.Divider />
              {hasPermission(role, ["ADMIN", "USER", "MANAGER"]) ? (
                <Menu.Divider />
              ) : null}

              {hasPermission(role, ["ADMIN", "USER", "MANAGER"]) ? (
                <Menu.SubMenu title="Company Data" key="/entity">
                  <Menu.Item key="/entity/groups">Groups</Menu.Item>
                  <Menu.Item key="/entity/entities">Entities</Menu.Item>
                  <Menu.Item key="/entity/banks">Banks</Menu.Item>
                  <Menu.Item key="/entity/accounts">Bank Accounts</Menu.Item>
                  <Menu.Item key="/entity/contacts">Contacts</Menu.Item>
                </Menu.SubMenu>
              ) : null}
              {hasPermission(role, ["ADMIN", "USER", "MANAGER"]) ? (
                <Menu.Item key="/entity/thirdparty" icon={<ProfileOutlined />}>
                  Third Party
                </Menu.Item>
              ) : null}

              {hasPermission(role, ["ADMIN", "USER", "MANAGER"]) ? (
                <Menu.Divider />
              ) : null}

              {hasPermission(role, ["ADMIN", "USER", "MANAGER", "OBSERVER"]) ? (
                <Menu.Item key="/documents/invoices" icon={<FormOutlined />}>
                  Cash Flow
                </Menu.Item>
              ) : null}

              {hasPermission(role, ["ADMIN", "USER", "MANAGER", "OBSERVER"]) ? (
                <Menu.Item key="/documents/documents" icon={<FileOutlined />}>
                  Documents
                </Menu.Item>
              ) : null}
              {/* {hasPermission(role, ["ADMIN", "USER", "MANAGER", "OBSERVER"]) ? (
                <Menu.Item key="/documents/loans" icon={<CreditCardOutlined />}>
                  Loans
                </Menu.Item>
              ) : null}
              {hasPermission(role, ["ADMIN", "USER", "MANAGER", "OBSERVER"]) ? (
                <Menu.Item key="/documents/kyc" icon={<ContactsOutlined />}>
                  KYC
                </Menu.Item>
              ) : null} */}

              <Menu.Divider />

              {hasPermission(role, ["ADMIN", "USER", "MANAGER"]) ? (
                <Menu.Item key="sync" icon={<SyncOutlined />}>
                  Sync with Storage
                </Menu.Item>
              ) : null}

              {hasPermission(role, ["ADMIN"]) ? <Menu.Divider /> : null}

              <Menu.SubMenu
                title="Administration"
                key="/admin"
                icon={<SettingOutlined />}
              >
                {/* {hasPermission(role, ["ADMIN"]) ? (
                  <Menu.Item key="/">User Accounts</Menu.Item>
                ) : null} */}
                {hasPermission(role, ["ADMIN"]) ? (
                  <Menu.Item key="/admin/settings">Tenant Settings</Menu.Item>
                ) : null}
                {hasPermission(role, ["ADMIN"]) ? (
                  <Menu.Item key="/admin/templates">Email Templates</Menu.Item>
                ) : null}
                {hasPermission(role, [
                  "ADMIN",
                  "USER",
                  "OBSERVER",
                  "MANAGER",
                ]) ? (
                  <Menu.Item key="/admin/user">User Settings</Menu.Item>
                ) : null}
                {hasPermission(role, ["ADMIN", "SUPERADMIN"]) ? (
                  <Menu.Item key="/admin/users">User Administration</Menu.Item>
                ) : null}
                {hasPermission(role, ["ADMIN"]) ? (
                  <Menu.Item key="/admin/sync">Backup and Sync</Menu.Item>
                ) : null}
              </Menu.SubMenu>
            </Menu>
          </Layout.Sider>
          <Layout
            style={{
              minHeight: "100vh",
              borderRight: 0,
              paddingTop: "64px",
              paddingLeft: collapsed ? "60px" : "240px",
            }}
          >
            <div
              style={{
                padding: 24,
                margin: 0,
                minHeight: "100%",
              }}
            >
              <Layout.Content
                style={{
                  // background: "#141414",
                  background: "#fff",
                  minHeight: "100%",
                  padding: 24,
                }}
              >
                {children}
              </Layout.Content>
            </div>
          </Layout>
        </Layout>
      </Layout>
    </>
  );
};

export default Default;
