import MultiColorProgressBar from "./MultiColorProgressBar";

const groupTokensByStatus = (tokens: any) =>
  (tokens || []).reduce(
    (acc: Record<string, Array<string>>, val: any) => ({
      ...acc,
      [val.status || "unknown"]: [
        ...(acc[val.status || "unknown"] || []),
        val.email,
      ],
    }),
    {} as Record<string, number>,
  );

const arrayLength = (arr: Array<any>) => (Array.isArray(arr) ? arr.length : 0);
const arrayToString = (arr: Array<any>) =>
  Array.isArray(arr) ? arr.join("\n") : "";

const ValidationProgress = ({ row }: { row: any }) => {
  const grouped = groupTokensByStatus(row.tokens);

  return (
    <MultiColorProgressBar
      values={[
        {
          value: arrayLength(grouped.approved),
          label: arrayToString(grouped.approved),
          color: "#06D6A0",
        },
        {
          value: arrayLength(grouped.on_hold),
          label: arrayToString(grouped.on_hold),
          color: "#fdf498",
        },
        {
          value: arrayLength(grouped.rejected),
          label: arrayToString(grouped.rejected),
          color: "#ff6f69",
        },
        {
          value: arrayLength(grouped.unknown),
          label: arrayToString(grouped.unknown),
          color: "#eceff1",
        },
      ]}
    />
  );
};

export default ValidationProgress;
