import { criteriaQuery } from "../utils/query";

import { IDataSourceRequest } from "./../hooks/useDataSource";
import { IFile } from "./files";

export interface ILoan {
  id?: number;
  documentDate?: number;
  dateCreated?: number;
  currency?: string;
  amount?: string;
  purpose?: string;
  documentFrom?: { id: number; name: string };
  documentTo?: { id: number; name: string };
  comments?: string;
  expiryDate?: number;
  nextInterestDate?: number;
  nextAmortisationDate?: number;
  maturityDate?: number;
  otherParties?: string;
  interestRate?: number;
  defaultRate?: number;
  dayCountConversion?: string;
  status?: string;
  company?: string;
  files?: Array<IFile>;
}

export const getLoanById = (id: number) =>
  fetch(`${process.env.REACT_APP_BACKEND_URL}/documents/loans/${id}`, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: { "Content-Type": "application/json" },
  }).then((res) => (res.ok ? res.json() : Promise.reject(res)));

export const getLoans = (status: string) => (req: IDataSourceRequest) =>
  fetch(
    `${
      process.env.REACT_APP_BACKEND_URL
    }/documents/loans/?loanStatus=${status}&${criteriaQuery(req)}`,
    {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    },
  ).then((res) => (res.ok ? res.json() : Promise.reject(res)));

export const saveLoan: (loan: ILoan) => Promise<ILoan> = (loan) =>
  fetch(`${process.env.REACT_APP_BACKEND_URL}/documents/loans/${loan.id}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    mode: "cors",
    credentials: "include",
    body: JSON.stringify(loan),
  }).then((res) => (res.ok ? res.json() : Promise.reject(res)));

export const duplicateLoan: (loan: ILoan) => Promise<ILoan> = (loan) =>
  fetch(
    `${process.env.REACT_APP_BACKEND_URL}/documents/loans/${loan.id}/duplicate`,
    {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      mode: "cors",
      credentials: "include",
      body: JSON.stringify(loan),
    },
  ).then((res) => (res.ok ? res.json() : Promise.reject(res)));

export const downloadLoan: (loan: ILoan) => Promise<ILoan> = (loan) =>
  fetch(
    `${process.env.REACT_APP_BACKEND_URL}/documents/loans/${loan.id}/download`,
    {
      method: "GET",
      mode: "cors",
      credentials: "include",
    },
  ).then((res) => (res.ok ? res.json() : Promise.reject(res)));

export const exportCsv = () =>
  fetch(`${process.env.REACT_APP_BACKEND_URL}/documents/loans/csv`, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: { "Content-Type": "application/octet-stream" },
  }).then((res) => (res.ok ? res.arrayBuffer() : Promise.reject(res)));
