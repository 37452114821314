import { criteriaQuery } from "./../utils/query";

import {
  IDataSourceFetchFunc,
  IDataSourceRequest,
} from "./../hooks/useDataSource";

export interface IUser {
  id?: number;
  email?: string;
  //   password?: string;
  role?: string;
  fullName?: string;
}

export const getUsers: IDataSourceFetchFunc<IUser> = (
  req: IDataSourceRequest,
) =>
  fetch(`${process.env.REACT_APP_BACKEND_URL}/users/?${criteriaQuery(req)}`, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: { "Content-Type": "application/json" },
  }).then((res) => (res.ok ? res.json() : Promise.reject(res)));

export const createUser: (user: IUser) => Promise<IUser> = (user) =>
  fetch(`${process.env.REACT_APP_BACKEND_URL}/users/`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    mode: "cors",
    credentials: "include",
    body: JSON.stringify(user),
  }).then((res) => (res.ok ? res.json() : Promise.reject(res)));

export const saveUser: (users: IUser) => Promise<IUser> = (users) =>
  fetch(`${process.env.REACT_APP_BACKEND_URL}/users/${users.id}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    mode: "cors",
    credentials: "include",
    body: JSON.stringify(users),
  }).then((res) => (res.ok ? res.json() : Promise.reject(res)));

export const updateUserData: (user: IUser) => Promise<IUser> = (user) =>
  fetch(`${process.env.REACT_APP_BACKEND_URL}/users/update-user-data`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    mode: "cors",
    credentials: "include",
    body: JSON.stringify(user),
  }).then((res) => (res.ok ? res.json() : Promise.reject(res)));

export const deleteUser: (user: IUser) => Promise<void> = (user) =>
  fetch(`${process.env.REACT_APP_BACKEND_URL}/users/${user.id}/delete`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    mode: "cors",
    credentials: "include",
  }).then((res) => (res.ok ? Promise.resolve() : Promise.reject(res)));
