export interface ICountConversion {
  value: string;
  label: string;
}

const dayCountConversion: { content: Array<ICountConversion> } = {
  content: [
    { label: "Actual/360", value: "Actual/360" },
    { label: "Actual/365", value: "Actual/365" },
    { label: "30/365", value: "30/365" },
  ],
};

export const getDayCountConversion: () => Promise<{
  content: Array<ICountConversion>;
}> = () => Promise.resolve(dayCountConversion);
