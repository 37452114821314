import React, { useMemo, useCallback, useState } from "react";
import { Button, Table, Popconfirm, Tooltip, Divider, PageHeader } from "antd";
// import { TableRowSelection } from "antd/lib/table/interface";
import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";
import useDatasource from "../../hooks/useDataSource";
import useModal from "../../hooks/useModal";
import useDictionaries from "../../hooks/useDictionaries";
import {
  IEmailTemplate,
  createEmailTemplate,
  deleteEmailTemplate,
  getEmailTemplates,
} from "../../services/email-templates";
import { getGroups } from "../../services/groups";
import DictionaryLabel from "../DictionaryLabel";
import EmailTemplateModal from "./EmailTemplateModal";
import { getScopes } from "../../services/scopes";

const dictionaries = {
  groups: getGroups,
  scopes: getScopes,
};

interface EmailTemplatesListProps {}

const EmailTemplatesList: React.FC<EmailTemplatesListProps> = () => {
  const { loading, pagination, content, handleChange, reload } =
    useDatasource<IEmailTemplate>(getEmailTemplates);

  const handleDuplicate = useCallback(
    (emailTemplate: IEmailTemplate) => {
      createEmailTemplate({
        ...emailTemplate,
        id: undefined,
        name: "<duplicate>" + emailTemplate.name,
      }).then(() => reload());
    },
    [reload],
  );
  const [dicts] = useDictionaries(dictionaries);
  const { groups } = dicts;
  const [selectedEmailTemplate, setSelectedEmailTemplate] =
    useState<IEmailTemplate | null>(null);

  const [show, modalProps] = useModal(reload);

  const columns = useMemo(
    () => [
      {
        title: "Groups",
        dataIndex: "groups",
        key: "groups",
        render: (text: any, row: any) =>
          row.groups
            ?.split(",")
            .filter((v: string) => !!v)
            .map((id: number) => (
              <>
                <DictionaryLabel dictionary={groups} value={id} />
                <span> </span>
              </>
            )),
      },
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Action",
        dataIndex: "",
        key: "x",
        width: 150,
        render: (_text: any, row: any) => (
          <>
            <Tooltip placement="bottomRight" title="Duplicate">
              <Popconfirm
                placement="topLeft"
                title="Are you sure about duplicating this email template?"
                onConfirm={() => handleDuplicate(row)}
                okText="Yes"
                cancelText="No"
              >
                <Button type="text" icon={<PlusCircleOutlined />} />
              </Popconfirm>
            </Tooltip>
            <span> </span>
            <Tooltip placement="bottomRight" title="Delete">
              <Popconfirm
                placement="topLeft"
                title="Are you sure about deleting this email template?"
                onConfirm={() =>
                  deleteEmailTemplate(row).then(() => {
                    reload();
                  })
                }
                okText="Yes"
                cancelText="No"
              >
                <Button type="text" icon={<DeleteOutlined />} />
              </Popconfirm>
            </Tooltip>
          </>
        ),
      },
    ],
    [groups, handleDuplicate, reload],
  );

  // const [selectedRows, setSelectedRows] = useState<Array<React.Key>>([]);
  // const rowSelection: TableRowSelection<IEmailTemplate> = {
  //   selectedRowKeys: selectedRows,
  //   onChange: (selectedRowKeys: React.Key[]) => {
  //     setSelectedRows(selectedRowKeys);
  //   },
  // };

  const handleAddEmailTemplate = useCallback(() => {
    setSelectedEmailTemplate({} as IEmailTemplate);
    show();
  }, [show]);

  return (
    <>
      <PageHeader
        onBack={() => window.history.back()}
        title="Email Templates"
        extra={[
          <Button onClick={handleAddEmailTemplate} type="primary">
            New Email Template
          </Button>,
        ]}
      >
        <Table
          columns={columns}
          rowKey="id"
          loading={loading}
          pagination={pagination}
          dataSource={content}
          onChange={handleChange}
          // rowSelection={rowSelection}
          // scroll={{ x: 1500 }}
          onRow={(emailTemplate: IEmailTemplate) => {
            return {
              onDoubleClick: (evt) => {
                evt.preventDefault();
                setSelectedEmailTemplate(emailTemplate);
                show();
              },
            };
          }}
        />
        <Divider />
      </PageHeader>

      <EmailTemplateModal
        dictionaries={dicts}
        {...modalProps}
        emailTemplate={selectedEmailTemplate}
      />
    </>
  );
};

export default EmailTemplatesList;
