import React, { useMemo, useCallback, useState, useContext } from "react";

import {
  Upload,
  message,
  Button,
  Table,
  Popconfirm,
  Tooltip,
  Divider,
  PageHeader,
  notification,
} from "antd";
import {
  DeleteOutlined,
  PlusCircleOutlined,
  DownloadOutlined,
  UploadOutlined,
  SyncOutlined,
} from "@ant-design/icons";

import useDatasource from "../../hooks/useDataSource";
import useModal from "../../hooks/useModal";
import useDictionaries from "../../hooks/useDictionaries";

import {
  getBankAccounts,
  deleteBankAccount,
  createBankAccount,
  IBankAccount,
  exportCsv,
  syncBankAccounts,
} from "../../services/bank-accounts";
import { getGroups } from "../../services/groups";
import { getCompanies } from "../../services/companies";
import { getCurrencies } from "../../services/currencies";
import { getBanks } from "../../services/banks";

import { UserContext } from "../../components/auth";
import { currentDateAsString, hasPermission } from "../../utils/helpers";

import BankAccountModal from "./BankAccountModal";

const dictionaries = {
  groups: getGroups,
  companies: getCompanies,
  currencies: getCurrencies,
  banks: getBanks,
};

interface BankAccountsListProps {}

const BankAccountsList: React.FC<BankAccountsListProps> = () => {
  const { user } = useContext(UserContext);
  const { role } = user;

  const { loading, pagination, content, handleChange, reload } =
    useDatasource<IBankAccount>(getBankAccounts);

  const csvProps = {
    name: "file",
    beforeUpload: (file: any) => {
      const isCsv = file.type === "text/csv";
      if (!isCsv) {
        message.error(`${file.name} is not a csv file`);
      }
      return isCsv || Upload.LIST_IGNORE;
    },
    withCredentials: true,
    action: `${process.env.REACT_APP_BACKEND_URL}/bank-accounts/csv`,
    showUploadList: false,
    maxCount: 1,
    onChange: reload,
  };

  const handleDuplicate = useCallback(
    (bankAccount: IBankAccount) => {
      createBankAccount({
        ...bankAccount,
        id: null,
      }).then(reload);
    },
    [reload],
  );
  const [dicts] = useDictionaries(dictionaries);

  const handleSyncCentrip = () => {
    syncBankAccounts()
      .then(() => {
        notification.success({ message: "Successful Sync" });
        reload();
      })
      .catch(() => notification.error({ message: "Something went wrong" }));
  };

  const [selectedBankAccount, setSelectedBankAccount] =
    useState<IBankAccount | null>(null);

  const [show, modalProps] = useModal(reload);

  const columns = useMemo(
    () => [
      {
        title: "Group",
        dataIndex: ["bank", "groups"],
        key: "groups",

        // render: (text: any) => (
        //   <DictionaryLabel
        //     dictionary={groups}
        //     value={text}
        //     keyField="name"
        //     labelField="name"
        //   />
        // ),
      },
      {
        title: "Name",
        dataIndex: ["company", "name"],
        key: "company.name",
      },
      {
        title: "Bank",
        dataIndex: ["bank", "name"],
        key: "bank.name",
      },
      {
        title: "IBAN",
        dataIndex: "iban",
        key: "iban",
      },
      {
        title: "Currency",
        dataIndex: "currency",
        key: "currency",
      },
      // {
      //   title: "Comment",
      //   dataIndex: "comment",
      //   key: "comment",
      // },
      // {
      //   title: "Tags",
      //   dataIndex: "tags",
      //   key: "tags",
      // },
      {
        title: "Action",
        dataIndex: "",
        key: "x",
        width: 150,
        render: (text: any, row: any) => (
          <>
            <Tooltip placement="bottomRight" title="Duplicate">
              <Popconfirm
                placement="topLeft"
                title="Are you sure about duplicating this bank account?"
                onConfirm={() => handleDuplicate(row)}
                okText="Yes"
                cancelText="No"
              >
                <Button type="text" icon={<PlusCircleOutlined />} />
              </Popconfirm>
            </Tooltip>
            <span> </span>
            <Tooltip placement="bottomRight" title="Delete">
              <Popconfirm
                placement="topLeft"
                title="Are you sure about deleting this bank account?"
                onConfirm={() =>
                  deleteBankAccount(row).then(() => {
                    reload();
                  })
                }
                okText="Yes"
                cancelText="No"
              >
                <Button type="text" icon={<DeleteOutlined />} />
              </Popconfirm>
            </Tooltip>
          </>
        ),
      },
    ],
    [
      // groups,
      handleDuplicate,
      reload,
    ],
  );

  const handleAddBankAccount = useCallback(() => {
    setSelectedBankAccount({} as IBankAccount);
    show();
  }, [show]);

  return (
    <>
      <PageHeader
        className="site-page-header-responsive"
        onBack={() => window.history.back()}
        title="Bank Accounts"
        extra={
          hasPermission(role, ["ADMIN", "USER"])
            ? [
                <Button onClick={handleAddBankAccount} type="primary">
                  New Bank Account
                </Button>,
                <Upload {...csvProps}>
                  <Button icon={<UploadOutlined />}>Upload CSV</Button>
                </Upload>,
                <Button
                  key="2"
                  icon={<DownloadOutlined />}
                  onClick={() =>
                    exportCsv().then((buffer) => {
                      const url = window.URL.createObjectURL(
                        new Blob([buffer]),
                      );
                      const link = window.document.createElement("a");
                      link.href = url;
                      link.setAttribute(
                        "download",
                        `${currentDateAsString}"bank-accounts-as-csv.csv`,
                      );
                      window.document.body.appendChild(link);
                      link.click();
                      link.parentNode!.removeChild(link);
                    })
                  }
                >
                  Download CSV
                </Button>,
                <Button
                  key="3"
                  icon={<SyncOutlined />}
                  onClick={handleSyncCentrip}
                >
                  Sync
                </Button>,
              ]
            : null
        }
      >
        <Table
          columns={columns}
          rowKey="id"
          loading={loading}
          pagination={pagination}
          dataSource={content}
          onChange={handleChange}
          // rowSelection={rowSelection}
          // scroll={{ x: 1500 }}
          style={{ minHeight: "80vh" }}
          onRow={(bankAccount: IBankAccount) => {
            return {
              onDoubleClick: (evt) => {
                evt.preventDefault();
                setSelectedBankAccount(bankAccount);
                show();
              },
            };
          }}
        />
        <Divider />

        <BankAccountModal
          dictionaries={dicts}
          {...modalProps}
          bankAccount={selectedBankAccount}
        />
      </PageHeader>
    </>
  );
};

export default BankAccountsList;
