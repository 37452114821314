import { Button, Col, Row, Select } from "antd";
import React from "react";

const SelectWithAddon: React.FC<any> = ({
  withChoice,
  addonLabel,
  onAddonClick,
  secondAddonLabel,
  onSecondAddonClick,
  children,
  ...rest
}) => {
  return (
    <Row>
      <Col span={18}>
        <Select {...rest}>{children}</Select>
      </Col>
      <Col span={6}>
        {withChoice ? (
          <Button style={{ width: "100%" }} onClick={onAddonClick}>
            {addonLabel}
          </Button>
        ) : (
          <Button style={{ width: "100%" }} onClick={onSecondAddonClick}>
            {secondAddonLabel}
          </Button>
        )}
      </Col>
    </Row>
  );
};

export default SelectWithAddon;
