import { useState, useCallback, useMemo } from "react";

export interface ModalProps {
  visible: boolean;
  onOk: (evt: any) => void;
  onCancel: (evt: any) => void;
  setVisible: (evt: any) => void;
}

export default function useModal(callback?: Function) {
  const [visible, setVisible] = useState(false);

  const show = useCallback(() => {
    setVisible(true);
  }, []);

  const onOk = useCallback(() => {
    if (callback) {
      callback();
    }
    setVisible(false);
  }, [callback]);

  const onCancel = useCallback(() => {
    setVisible(false);
  }, []);

  const wrapper: [Function, ModalProps] = useMemo(
    () => [show, { visible, onOk, onCancel, setVisible } as ModalProps],
    [onCancel, onOk, show, visible, setVisible],
  );

  return wrapper;
}
