import React from "react";

import { Row, Col, PageHeader } from "antd";
import DownloadBucket from "../../components/admin/DownloadBucket";
import SyncToGDrive from "../../components/admin/SyncToGDrive";
import { Default } from "../../layouts";

interface BackupAndSyncProps {}

const BackupAndSync: React.FC<BackupAndSyncProps> = () => {
  return (
    <Default>
      <PageHeader title="Backup And Sync" />

      <Row gutter={16}>
        <Col span={6}>
          <DownloadBucket />
        </Col>
        <Col span={6}>
          <SyncToGDrive />
        </Col>
      </Row>
    </Default>
  );
};

export default BackupAndSync;
