import React, { useMemo } from "react";

import { Typography } from "antd";

const threshold = 1000;
const units = ["B", "kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

interface IFileSizeLabelProps {
  size: number;
}

const FileSizeLabel: React.FC<IFileSizeLabelProps> = ({ size }) => {
  const label = useMemo(() => {
    if (size == null) {
      return "";
    }

    let currentUnit = 0;
    let currentSize = Number(size);
    while (currentSize >= threshold && currentUnit < units.length - 1) {
      currentUnit++;
      currentSize /= threshold;
    }

    return currentSize.toFixed(1) + " " + units[currentUnit];
  }, [size]);
  return <Typography.Text>{label}</Typography.Text>;
};

export default FileSizeLabel;
