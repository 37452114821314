import React from "react";
import { Layout, Menu } from "antd";

import BackgroundImage from "./hipster-medium.jpg";

const Empty = ({ children }: { children: React.ReactNode }) => (
  <>
    <Layout>
      <Layout.Header
        style={{
          position: "fixed",
          zIndex: 1000,
          width: "100%",
          padding: 0,
        }}
      >
        <Menu
          theme="dark"
          mode="horizontal"
          style={{ backgroundColor: "black" }}
        >
          <Menu.Item key="1">
            <img
              src="/neobank-logo.png"
              alt="neobank.services logo"
              style={{ width: 30, marginRight: 5 }}
            ></img>
            <span style={{ fontSize: "100%" }}>neobank.services</span>
          </Menu.Item>
        </Menu>
      </Layout.Header>
      <Layout>
        <div
          style={{
            height: "100vh",
            minHeight: "400px",
            borderRight: 0,
            paddingTop: "64px",
            backgroundImage: `url(${BackgroundImage})`,
            position: "fixed",
            top: 0,
            right: 0,
            bottom: 0,
            minWidth: "100%",
            WebkitFilter: "blur(20px)",
            filter: "grayscale(100%) blur(5px)",
            WebkitBackgroundSize: "cover",
            MozBackgroundSize: "cover",
            backgroundSize: "cover",
            backgroundPosition: "0 60%",
            backgroundRepeat: "no-repeat",
          }}
        ></div>
        <div
          style={{
            padding: 24,
            margin: 0,
            height: "100%",
          }}
        >
          <Layout.Content>{children}</Layout.Content>
          {/* <Row style={{ paddingTop: "300px" }}>
            <Col lg={{ span: 20, offset: 2 }}>
              <Steps current={3}>
                <Steps.Step title="Invoice Approved" description="By John" />
                <Steps.Step title="Invoice Approved" description="By Jane" />
                <Steps.Step title="Invoice Approved" description="By Helen" />
                <Steps.Step title="Payment In Process" description="By Jack" />
              </Steps>
            </Col>
          </Row> */}
        </div>
      </Layout>
    </Layout>
  </>
);

export default Empty;
