export interface IDocumentStatus {
  label: string;
  value: string;
}

const documentStatus = {
  content: [
    { label: "In Force", value: "in_force" },
    { label: "Expired", value: "closed" },
  ],
};

export const getDocumentStatus: () => Promise<{
  content: Array<IDocumentStatus>;
}> = () => Promise.resolve(documentStatus);
