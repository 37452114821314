export interface ITenant {
  id?: string;
  name?: string;
  subdomain?: string;
  identifier?: string;
  logo?: string;
  header?: string;
  signature?: string;
  footer?: string;
}

export const getTenant = () =>
  fetch(`${process.env.REACT_APP_BACKEND_URL}/tenant-settings`, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: { "Content-Type": "application/json" },
  }).then((res) => (res.ok ? res.json() : Promise.reject(res)));

export const updateTenant: (tenant: ITenant) => Promise<ITenant> = (tenant) =>
  fetch(`${process.env.REACT_APP_BACKEND_URL}/tenant-settings`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    mode: "cors",
    credentials: "include",
    body: JSON.stringify(tenant),
  }).then((res) => (res.ok ? res.json() : Promise.reject(res)));
