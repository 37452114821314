import React, { useCallback, useEffect } from "react";

import { Modal, Form, Input, Row, Col, Select, notification } from "antd";

import { ModalProps } from "../../../hooks/useModal";

import {
  IContact,
  saveContact,
  createContact,
} from "../../../services/contacts";

interface ContactModalProps extends ModalProps {
  contact: IContact | null;
  contactModalForm: string;
  dictionaries: Record<
    string,
    { content: Array<any>; loading: boolean; error: boolean }
  >;
  onAfterAction: Function;
}

const ContactModal: React.FC<ContactModalProps> = ({
  visible,
  onOk,
  onCancel,
  contact,
  dictionaries,
  contactModalForm,

  onAfterAction,
}) => {
  const [form] = Form.useForm();

  const { groups, scopes, categories, roles } = dictionaries;
  useEffect(() => {
    form.resetFields();
    form.setFieldsValue({
      ...contact,
      groups: contact?.groups?.split(",").filter((v: string) => !!v),
      scope: contact?.scope?.split(",").filter((v: string) => !!v),
    });
  }, [form, contact]);

  const handleFinish = useCallback(
    (value) => {
      const entity: IContact = {
        ...contact,
        ...value,
      };

      (entity.id! ? saveContact : createContact)(entity)
        .then(() => {
          notification.success({ message: "Successfully saved" });

          onOk(null);
          onAfterAction();
        })
        .catch(() => {
          notification.error({ message: "Something went wrong" });
        });
    },
    [contact, onAfterAction, onOk],
  );

  return (
    <Form
      onFinish={handleFinish}
      form={form}
      id={contactModalForm}
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 16 }}
    >
      <Modal
        title="Contact modal"
        visible={visible}
        okButtonProps={{ htmlType: "submit", form: contactModalForm }}
        onCancel={onCancel}
        width={1600}
      >
        <Row>
          <Col span={12}>
            <Form.Item
              rules={[{ required: true, message: "Required Field" }]}
              label="Groups"
              name="groups"
            >
              <Select mode="multiple" loading={groups.loading}>
                {groups.content.map((group: { id: number; name: string }) => (
                  <Select.Option value={group.name} key={group.id}>
                    {group.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              rules={[{ required: true, message: "Required Field" }]}
              label="Scope"
              name="scope"
            >
              <Select mode="multiple" loading={scopes.loading}>
                {scopes.content.map(
                  (scope: { label: string; value: string }) => (
                    <Select.Option value={scope.value} key={scope.value}>
                      {scope.label}
                    </Select.Option>
                  ),
                )}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              rules={[{ required: true, message: "Required Field" }]}
              label="Category"
              name="category"
            >
              <Select loading={categories.loading}>
                {categories.content.map(
                  (category: { value: string; label: string }) => (
                    <Select.Option value={category.value} key={category.value}>
                      {category.label}
                    </Select.Option>
                  ),
                )}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              rules={[{ required: true, message: "Required Field" }]}
              label="Role"
              name="role"
            >
              <Select loading={categories.loading}>
                {roles.content.map((role: { value: string; label: string }) => (
                  <Select.Option value={role.value} key={role.value}>
                    {role.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              rules={[{ required: true, message: "Required Field" }]}
              label="First Name"
              name="firstName"
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              rules={[{ required: true, message: "Required Field" }]}
              label="Last Name"
              name="lastName"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Company" name="company">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Title" name="title">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              rules={[{ required: true, message: "Required Field" }]}
              label="Email"
              name="email"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Phone" name="phone">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Mobile" name="mobile">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Address" name="address">
              <Input />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Comment"
              name="comment"
              labelCol={{ span: 2 }}
              wrapperCol={{ span: 20 }}
            >
              <Input.TextArea rows={5} />
            </Form.Item>
          </Col>
        </Row>
      </Modal>
    </Form>
  );
};

export default ContactModal;
