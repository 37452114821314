export interface IInvoiceType {
  value: string;
  label: string;
}

const invoiceTypes: { content: Array<IInvoiceType> } = {
  content: [
    { value: "tax", label: "Tax" },
    { value: "social", label: "Social" },
    { value: "provider", label: "Provider" },
    { value: "bank", label: "Bank" },
    { value: "expenses", label: "Expenses" },
    { value: "salary", label: "Salary" },
    { value: "other", label: "Other" },
  ],
};

export const getInvoiceTypes: () => Promise<{ content: Array<IInvoiceType> }> =
  () => Promise.resolve(invoiceTypes);
