import React, { useState } from "react";
import {
  //   Divider,
  Form,
  Input,
  Row,
  Col,
  Button,
  notification,
} from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import useRefresh from "../../hooks/useRefresh";
import { resetUserPassword } from "../../services/user";
import { useHistory, useParams } from "react-router-dom";

const PasswordReset = () => {
  const [resetPasswordForm] = Form.useForm();
  const [, refresh] = useRefresh();
  //@ts-ignore
  const { token } = useParams();
  const history = useHistory();

  const onFinishResetPassword = (values: any) => {
    if (values.newPassword !== values.confirmNewPassword) {
      notification.error({ message: "passwords don't match" });
      return;
    } else {
      resetUserPassword(token, values.confirmNewPassword)
        .then(() => {
          notification.success({ message: "Password successfully reset" });
          history.push("/");
          refresh();
        })
        .catch(() => {
          history.push("/");
          notification.error({ message: "Could not reset password" });
        });
    }
  };

  const [newPassword, setNewPassword] = useState("");

  return (
    <div style={{ paddingTop: "280px" }}>
      <Form
        form={resetPasswordForm}
        id="resetPasswordForm"
        labelCol={{ span: 18 }}
        wrapperCol={{ span: 20 }}
        onFinish={onFinishResetPassword}
      >
        <Row>
          <Col span={14}>
            <Form.Item
              rules={[
                { required: true, message: "Required Field" },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (
                      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?.&])[A-Za-z\d@$!%*?.&]{8,}$/.test(
                        value,
                      )
                    ) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character",
                      ),
                    );
                  },
                }),
              ]}
              label="New Password"
              name="newPassword"
            >
              <Input.Password
                placeholder="New Password"
                onChange={(evt) => setNewPassword(evt.target.value)}
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
            </Form.Item>
          </Col>
          <Col span={14}>
            <Form.Item
              rules={[
                { required: true, message: "Required Field" },

                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (value === newPassword) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "The two passwords that you entered do not match!",
                      ),
                    );
                  },
                }),
              ]}
              label="Confirm New Password"
              name="confirmNewPassword"
            >
              <Input.Password
                placeholder="Confirm New Password"
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
            </Form.Item>
          </Col>

          <Col span={14}>
            <Form.Item wrapperCol={{ offset: 18, span: 20 }}>
              <Button htmlType="submit" type="primary">
                Submit Data
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default PasswordReset;
