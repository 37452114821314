export interface ILoanEntityActingAs {
  value: string;
  label: string;
}

const loanEntityActingAs = {
  content: [
    { label: "Lender", value: "Lender" },
    { label: "Borrower", value: "Borrower" },
  ],
};

export const getLoanEntityActingAs: () => Promise<{
  content: Array<ILoanEntityActingAs>;
}> = () => Promise.resolve(loanEntityActingAs);
