import { criteriaQuery } from "../utils/query";

import {
  IDataSourceFetchFunc,
  IDataSourceRequest,
} from "../hooks/useDataSource";

export interface IBankAccount {
  id: string;
  bankAccountName: string;
  iban: string;
  currency: string;
  swift: string;
  tags: string;
  comment: string;
}

export const getBankAccounts: IDataSourceFetchFunc<IBankAccount> = (
  req: IDataSourceRequest,
) =>
  fetch(
    `${process.env.REACT_APP_BACKEND_URL}/bank-accounts/?${criteriaQuery(req)}`,
    {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    },
  ).then((res) => (res.ok ? res.json() : Promise.reject(res)));

export const syncBankAccounts = () =>
  fetch(`${process.env.REACT_APP_BACKEND_URL}/bank-accounts/sync`, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: { "Content-Type": "application/octet-stream" },
  }).then((res) => (res.ok ? res.arrayBuffer() : Promise.reject(res)));

export const createBankAccount: (bankAccounts: any) => Promise<IBankAccount> = (
  bankAccount,
) =>
  fetch(`${process.env.REACT_APP_BACKEND_URL}/bank-accounts/`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    mode: "cors",
    credentials: "include",
    body: JSON.stringify(bankAccount),
  }).then((res) => (res.ok ? res.json() : Promise.reject(res)));

export const saveBankAccount: (
  bankAccount: IBankAccount,
) => Promise<IBankAccount> = (bankAccount) =>
  fetch(
    `${process.env.REACT_APP_BACKEND_URL}/bank-accounts/${bankAccount.id}`,
    {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      mode: "cors",
      credentials: "include",
      body: JSON.stringify(bankAccount),
    },
  ).then((res) => (res.ok ? res.json() : Promise.reject(res)));

export const deleteBankAccount: (bankAccount: IBankAccount) => Promise<void> = (
  bankAccount,
) =>
  fetch(
    `${process.env.REACT_APP_BACKEND_URL}/bank-accounts/${bankAccount.id}/delete`,
    {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      mode: "cors",
      credentials: "include",
    },
  ).then((res) => (res.ok ? Promise.resolve() : Promise.reject(res)));

export const exportCsv = () =>
  fetch(`${process.env.REACT_APP_BACKEND_URL}/bank-accounts/csv`, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: { "Content-Type": "application/octet-stream" },
  }).then((res) => (res.ok ? res.arrayBuffer() : Promise.reject(res)));
