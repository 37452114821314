import React, { useMemo, useCallback, useState } from "react";

import { Button, PageHeader, Popconfirm, Table, Tooltip } from "antd";
// import { TableRowSelection } from "antd/lib/table/interface";
import { DeleteOutlined } from "@ant-design/icons";

import useDatasource from "../../../hooks/useDataSource";
import useModal from "../../../hooks/useModal";

import { getUsers, deleteUser, IUser } from "../../../services/users";
import { getUserRoles } from "../../../services/user-roles";

import UserModal from "./UserModal";
import useDictionaries from "../../../hooks/useDictionaries";

const dictionaries = {
  userRoles: getUserRoles,
};

interface UsersListProps {}

const UsersList: React.FC<UsersListProps> = () => {
  const { loading, pagination, content, handleChange, reload } =
    useDatasource<IUser>(getUsers);

  const [dicts] = useDictionaries(dictionaries);

  const [selectedUser, setSelectedUser] = useState<IUser | null>(null);
  const [show, modalProps] = useModal(reload);

  const columns = useMemo(
    () => [
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
      },
      {
        title: "Full Name",
        dataIndex: "fullname",
        key: "fullname",
      },
      {
        title: "Role",
        dataIndex: "role",
        key: "role",
      },
      {
        title: "Action",
        dataIndex: "",
        key: "x",
        width: 150,
        render: (text: any, row: any) => (
          <>
            <Tooltip placement="bottomRight" title="Delete">
              <Popconfirm
                placement="topLeft"
                title="Are you sure about deleting this user?"
                onConfirm={() =>
                  deleteUser(row).then(() => {
                    reload();
                  })
                }
                okText="Yes"
                cancelText="No"
              >
                <Button
                  type="text"
                  //
                  icon={<DeleteOutlined />}
                />
              </Popconfirm>
            </Tooltip>
          </>
        ),
      },
    ],
    [reload],
  );

  // const [selectedRows, setSelectedRows] = useState<Array<React.Key>>([]);
  // const rowSelection: TableRowSelection<IUser> = {
  //   selectedRowKeys: selectedRows,
  //   onChange: (selectedRowKeys: React.Key[]) => {
  //     setSelectedRows(selectedRowKeys);
  //   },
  // };

  const handleAddUser = useCallback(() => {
    setSelectedUser({} as IUser);
    show();
  }, [show]);

  return (
    <>
      <PageHeader
        className="site-page-header-responsive"
        onBack={() => window.history.back()}
        title="Users"
        extra={[
          <Button key="1" type="primary" onClick={handleAddUser}>
            New User
          </Button>,
        ]}
      >
        <Table
          columns={columns}
          rowKey="id"
          loading={loading}
          pagination={pagination}
          dataSource={content}
          onChange={handleChange}
          // rowSelection={rowSelection}
          onRow={(user: IUser) => {
            return {
              onDoubleClick: (evt) => {
                evt.preventDefault();
                setSelectedUser(user);
                show();
              },
            };
          }}
        />
        {/* <Divider />
        <Button onClick={handleAddUser} type="primary">
          New User
        </Button> */}
      </PageHeader>

      <UserModal
        {...modalProps}
        user={selectedUser}
        dictionaries={dicts}
        refresh={reload}
      />
    </>
  );
};

export default UsersList;
