import { useMemo, useCallback, useContext } from "react";
import { Button, InputNumber, Tooltip, Tag, notification } from "antd";

import { IDictionaryRecord } from "../../../hooks/useDictionaries";

import {
  deleteInvoiceHard,
  IInvoice,
  processInvoice,
  processInvoicesByTags,
} from "../../../services/invoices";

import DictionaryLabel from "../../DictionaryLabel";
import InvoiceActions from "./InvoiceActions";
import FourEyesValidation from "./FourEyesValidation";
import ValidationProgress from "../../ValidationProgress";
import { DownloadOutlined } from "@ant-design/icons";

import { UserContext } from "../../../components/auth";
import { hasPermission } from "../../../utils/helpers";
import Column from "../../Column";
import FourEyesInvoiceActioins from "./FourEyesInvoiceActioins";

interface InvoicesColumnsProps {
  dictionaries: Record<string, IDictionaryRecord>;
  hiddenColumns?: Array<string>;
  onPreviewDocument: (invoice: IInvoice) => void;
  onAfterAction?: Function;
  gridType: string;
  fourEyesScope: string;
  userRightsStatus: string;
  showAuditModal?: Function;
  setAuditModalParams?: Function;
  onStatusChange: any;
  statusList: any;
  reload?: Function;
}

const useInvoicesColumns = (props: InvoicesColumnsProps) => {
  const { user } = useContext(UserContext);
  const { role } = user;
  const {
    dictionaries,
    onPreviewDocument,
    onAfterAction,
    hiddenColumns,
    gridType,
    fourEyesScope,
    userRightsStatus,
    showAuditModal,
    setAuditModalParams,
    onStatusChange,
    statusList,
    reload,
  } = props;

  const { thirdParties, companies, banks, currencies } = dictionaries;
  const handleAction = useCallback(
    async (invoice: IInvoice, destination: string) => {
      await processInvoice(invoice, destination);
      const tags = invoice.tags;
      if (tags && tags.length > 0) {
        await processInvoicesByTags(
          tags.map((t) => t.id.toString()),
          destination,
        );
      }
      if (typeof onAfterAction === "function") {
        onAfterAction();
      }
    },
    [onAfterAction],
  );

  const onAudit = useCallback(
    (invoiceId: number) => {
      if (showAuditModal && setAuditModalParams) {
        setAuditModalParams({ id: invoiceId, documentType: "invoice" });
        showAuditModal();
      }
    },
    [showAuditModal, setAuditModalParams],
  );

  const onDelete = useCallback(
    (id: number) => {
      deleteInvoiceHard(id)
        .then(() => {
          notification.success({ message: "Deleted document" });
          if (reload) {
            reload();
          }
        })
        .catch(() => {
          notification.error({ message: "Something went wrong" });
        });
    },
    [reload],
  );

  const renderComponentByUserRights = (userRightsStatus: string) => {
    const statusProgress = ["approved", "on_hold", "rejected"];

    if (userRightsStatus === "validation") {
      return {
        values: ["MANAGER", "ADMIN", "OBSERVER", "USER"],
        validationStatus: ["MANAGER", "ADMIN", "OBSERVER"],
        actions: ["MANAGER", "ADMIN"],
      };
    }
    if (statusProgress.includes(userRightsStatus)) {
      return {
        values: ["MANAGER", "ADMIN", "OBSERVER"],
        validationStatus: ["MANAGER", "ADMIN", "OBSERVER"],
        actions: ["MANAGER", "ADMIN"],
      };
    }
    if (userRightsStatus === "paid") {
      return {
        values: ["MANAGER", "ADMIN", "OBSERVER", "USER"],
        validationStatus: ["MANAGER", "ADMIN", "OBSERVER"],
        actions: ["MANAGER", "ADMIN"],
      };
    }
    return {
      values: [],
      validationStatus: [],
      actions: [],
    };
  };

  const columns = useMemo(
    () =>
      [
        {
          title: "File",
          dataIndex: ["files", 0, "fileName"],
          key: "files.0.fileName",
          width: 70,
          render: (value: string, record: IInvoice) => {
            return hasPermission(
              role,
              renderComponentByUserRights(userRightsStatus).values,
            ) ? (
              <Tooltip title={value}>
                <Button type="link" onClick={() => onPreviewDocument(record)}>
                  <DownloadOutlined style={{ color: "black" }} />
                </Button>
              </Tooltip>
            ) : null;
          },
        },
        {
          ...Column.date("documentDate", "Document date", {
            filter: true,
          }),
        },

        {
          ...Column.text("purpose", "Purpose", {
            sort: true,
            filter: true,
          }),
          render: (value: any) => {
            return hasPermission(
              role,
              renderComponentByUserRights(userRightsStatus).values,
            )
              ? value
              : null;
          },
        },
        // {
        //   title: "Purpose",
        //   dataIndex: "purpose",
        //   key: "purpose",
        //   width: 140,
        //   render: (value: any) => {
        //     return hasPermission(
        //       role,
        //       renderComponentByUserRights(userRightsStatus).values,
        //     )
        //       ? value
        //       : null;
        //   },
        // },
        {
          title: "Group",
          dataIndex: "tags",
          key: "tags",
          width: 150,
          render: (value: any) => {
            return hasPermission(
              role,
              renderComponentByUserRights(userRightsStatus).values,
            )
              ? value.map((tag: any) => (
                  <Tag style={{ margin: "2px" }} color="purple">
                    {tag.name}
                  </Tag>
                ))
              : null;
          },
        },

        {
          ...Column.text("documentRef", "Ref", {
            sort: true,
            filter: true,
          }),
          render: (value: any) => {
            return hasPermission(
              role,
              renderComponentByUserRights(userRightsStatus).values,
            )
              ? value
              : null;
          },
        },
        // {
        //   title: "Ref",
        //   dataIndex: "documentRef",
        //   key: "documentRef",
        //   width: 150,
        //   render: (value: any) => {
        //     return hasPermission(
        //       role,
        //       renderComponentByUserRights(userRightsStatus).values,
        //     )
        //       ? value
        //       : null;
        //   },
        // },
        {
          ...Column.dict(
            "documentFrom.id",
            "From",
            thirdParties,
            "id",
            "name",
            {
              filter: true,
            },
          ),
          render: (text: any, record: any) =>
            record.documentFrom.name?.toUpperCase(),
        },
        {
          ...Column.dict(
            "documentTo.id",
            "Concern Entity",
            companies,
            "id",
            "name",
            {
              filter: true,
            },
          ),
          render: (text: any, record: any) => record.documentTo.name,
        },
        {
          ...Column.dict("currency", "CCY", currencies, "value", "label", {
            filter: true,
          }),
          render: (value: any) => {
            return hasPermission(
              role,
              renderComponentByUserRights(userRightsStatus).values,
            ) ? (
              <DictionaryLabel
                dictionary={currencies}
                value={value}
                keyField="value"
                labelField="label"
              />
            ) : null;
          },
        },
        // {
        //   title: "CCY",
        //   dataIndex: "currency",
        //   width: 70,

        //   key: "currency",
        //   render: (value: any) => {
        //     return hasPermission(
        //       role,
        //       renderComponentByUserRights(userRightsStatus).values,
        //     ) ? (
        //       <DictionaryLabel
        //         dictionary={currencies}
        //         value={value}
        //         keyField="value"
        //         labelField="label"
        //       />
        //     ) : null;
        //   },
        // },
        {
          title: "Amount + VAT",
          dataIndex: "amount",
          key: "amount",
          width: 180,
          render: (value: any) => {
            return hasPermission(
              role,
              renderComponentByUserRights(userRightsStatus).values,
            ) ? (
              <InputNumber
                disabled
                className="input-currency"
                step={0.01}
                min={0}
                value={value}
                formatter={(value) =>
                  value &&
                  `${parseFloat(value).toFixed(2)}`.replace(
                    /\B(?=(\d{3})+(?!\d))/g,
                    ",",
                  )
                }
                //@ts-ignore
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                // precision={2}
                style={{
                  width: "100%",
                  color: "rgba(0, 0, 0, 0.85)",
                }}
              />
            ) : null;
          },
        },
        {
          title: "Paying/Receiving agent",
          dataIndex: ["payingAgent", "id"],
          key: "payingAgent",
          width: 200,
          render: (value: any) => {
            return hasPermission(
              role,
              renderComponentByUserRights(userRightsStatus).values,
            ) ? (
              <DictionaryLabel
                dictionary={companies}
                value={value}
                keyField="id"
                labelField="name"
              />
            ) : null;
          },
        },
        {
          title: "Paying/Receiving Bank",
          dataIndex: ["payingBank", "id"],
          key: "payingBank",
          width: 200,
          render: (value: any) => {
            return hasPermission(
              role,
              renderComponentByUserRights(userRightsStatus).values,
            ) ? (
              <DictionaryLabel
                dictionary={banks}
                value={value}
                keyField="id"
                labelField="name"
              />
            ) : null;
          },
        },
        {
          width: "150px",
          title: "Validation Status",
          dataIndex: "validation_process",
          key: "validation_process",
          render: (value: any, record: IInvoice) => {
            return hasPermission(
              role,
              renderComponentByUserRights(userRightsStatus).validationStatus,
            ) ? (
              <ValidationProgress row={record} />
            ) : null;
          },
          display: false,
        },
        {
          title: "4 Eyes",
          dataIndex: "eyes",
          key: "eyes",
          width: 100,
          render: (value: any, record: IInvoice) => {
            return hasPermission(
              role,
              renderComponentByUserRights(userRightsStatus).actions,
            ) ? (
              <FourEyesValidation
                row={record}
                onStatusChange={onStatusChange}
                statusList={statusList}
                gridType={gridType}
              />
            ) : null;
          },
        },
        {
          title: "Actions",
          dataIndex: "actions",
          key: "actions",
          width: 100,
          render: (value: any, record: IInvoice) => {
            return hasPermission(
              role,
              renderComponentByUserRights(userRightsStatus).actions,
            ) ? (
              fourEyesScope === "validation" ? (
                <FourEyesInvoiceActioins
                  row={record}
                  onAction={handleAction}
                  onAudit={onAudit}
                  gridType={gridType}
                  statusList={statusList}
                />
              ) : (
                <InvoiceActions
                  row={record}
                  onAction={handleAction}
                  onAudit={onAudit}
                  onDelete={onDelete}
                  gridType={gridType}
                  statusList={statusList}
                />
              )
            ) : null;
          },
        },
      ].filter((col) =>
        Array.isArray(hiddenColumns) ? !hiddenColumns.includes(col.key) : true,
      ),
    [
      banks,
      companies,
      currencies,
      fourEyesScope,
      gridType,
      handleAction,
      hiddenColumns,
      onAudit,
      onPreviewDocument,
      onStatusChange,
      role,
      statusList,
      thirdParties,
      userRightsStatus,
      onDelete,
    ],
  );

  return columns;
};

export default useInvoicesColumns;
