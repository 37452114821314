import React, { useState } from "react";
import { Form, Input, Button, message } from "antd";
import { setUpDrive } from "../../services/drive";

const TenantDrive = () => {
  const [email, setEmail] = useState("");

  const handleSubmit = async () => {
    try {
      await setUpDrive(email);
      message.success(`Successfully set up tenant drive for ${email}`);
    } catch (error) {
      console.log(error);
      message.error(`An error occurred: ${error as Error}`);
    }
  };

  return (
    <div style={{ marginTop: "3rem" }}>
      <h1 style={{ fontSize: "1.5em" }}>Set Up Tenant Drive</h1>
      <Form onFinish={handleSubmit}>
        <Form.Item
          label="Neobank Email"
          labelCol={{ span: 2 }}
          wrapperCol={{ span: 4 }}
          name="email"
          rules={[
            { required: true, message: "Email is required" },
            { type: "email", message: "Invalid email format" },
          ]}
        >
          <Input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter Neobank Email"
          />
        </Form.Item>
        <Form.Item labelCol={{ span: 2 }} wrapperCol={{ span: 4 }}>
          <Button
            type="primary"
            htmlType="submit"
            style={{ marginTop: "0.5rem" }}
          >
            Set Up Tenant Drive
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default TenantDrive;
