import React, {
  useMemo,
  useState,
  useEffect,
  useContext,
  useCallback,
} from "react";

import {
  Button,
  Col,
  Divider,
  Dropdown,
  Menu,
  Popconfirm,
  Row,
  Statistic,
  Table,
  Tooltip,
} from "antd";

import useDatasource from "../../../hooks/useDataSource";
import useModal from "../../../hooks/useModal";

import {
  getInvoiceById,
  getInvoicesByStatus,
  IInvoice,
  processInvoice,
} from "../../../services/invoices";

import InvoiceModal from "./InvoiceModal";
import AuditModal from "../AuditModal";
import useInvoicesColumns from "./useInvoicesColumns";
import { DictsContext } from "../../../hooks/useDictionaries";
import {
  getAccountBalances,
  getAccounts,
  ICenttripAccount,
  ICenttripAccountBalance,
  payInvoice,
} from "../../../services/centtrip";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { TableRowSelection } from "antd/lib/table/interface";

interface CenttripInvoicesListProps {
  onAfterAction: Function;
  shouldRefresh: number;
  rowSelection: any;
}

const CenttripInvoices: React.FC<CenttripInvoicesListProps> = ({
  onAfterAction,
  shouldRefresh,
}) => {
  const { dicts } = useContext(DictsContext);

  const { loading, pagination, content, handleChange, reload } =
    useDatasource<IInvoice>(getInvoicesByStatus("approved"));

  useEffect(() => {
    if (shouldRefresh > 0) {
      reload();
    }
  }, [reload, shouldRefresh]);

  const [selectedInvoice, setSelectedInvoice] = useState<IInvoice | null>(null);

  const [show, modalProps] = useModal(reload);

  const [showAuditModal, auditModalProps] = useModal();
  const [auditModalParams, setAuditModalParams] = useState({
    id: null,
    documentType: null,
  });

  const [statusList, setStatusList] = useState([] as object[]);

  useEffect(() => {
    setStatusList(
      content.map((invoice: IInvoice) => ({ id: invoice.id, status: 0 })),
    );
  }, [content]);

  const onStatusChange = useCallback(
    (row) => {
      const { id } = row;

      const currentInvoice = statusList.find((inv: any) => inv.id === id);
      const filteredStatusList = statusList.filter((inv: any) => inv.id !== id);

      if (!currentInvoice) {
        return;
      }
      const updatedStatusList = [
        ...filteredStatusList,
        {
          ...currentInvoice,
          // @ts-ignore
          status: currentInvoice.status !== 2 ? currentInvoice.status + 1 : 0,
        },
      ];
      setStatusList(updatedStatusList);
    },
    [statusList],
  );

  const columnProps = useMemo(
    () => ({
      dictionaries: dicts,
      onPreviewDocument: (invoice: IInvoice) =>
        window.open(`/documents/invoice/preview/${invoice.id}`),
      onAfterAction,
      gridType: "invoiceStatus.statusProgress.approvedGrid",
      userRightsStatus: "approved",
      hiddenColumns: ["eyes"],
      fourEyesScope: "approved",
      showAuditModal,
      setAuditModalParams,
      statusList,
      onStatusChange,
    }),
    [dicts, onAfterAction, onStatusChange, showAuditModal, statusList],
  );

  const columns = useInvoicesColumns(columnProps);

  const [selectedRows, setSelectedRows] = useState<Array<IInvoice>>([]);

  const rowSelection: TableRowSelection<IInvoice> = {
    columnTitle: <>Pay</>,
    onChange: (selectedRowKeys: React.Key[], selectedRows: IInvoice[]) => {
      setSelectedRows(selectedRows);
    },
  };

  const [centtripAccounts, setCenttripAccounts] = useState<
    ICenttripAccount[] | null
  >(null);
  const [selectedAccountId, setSelectedAccountId] = useState<string | null>(
    null,
  );
  const [balances, setBalances] = useState<ICenttripAccountBalance[] | null>(
    null,
  );
  const [dropdownVisible, setDropdownVisible] = useState(false);

  useEffect(() => {
    getAccounts().then((accounts) => {
      setCenttripAccounts(accounts);
      if (accounts && accounts.length === 1) {
        setSelectedAccountId(accounts[0].id);
        getAccountBalances(accounts[0].id)
          .then((balances) =>
            balances.filter((b: ICenttripAccountBalance) => b.amount > 0),
          )
          .then((balances) => setBalances(balances));
      }
    });
  }, [reload]);

  const handleMenuClick = (e: any) => {
    const selectedId = e.key;
    setSelectedAccountId(selectedId);
    getAccountBalances(selectedId).then((balances) =>
      setBalances(
        balances.filter((b: ICenttripAccountBalance) => b.amount > 0),
      ),
    );
    setDropdownVisible(false);
  };

  const handleDropdownVisibleChange = (visible: boolean) => {
    setDropdownVisible(visible);
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      {centtripAccounts &&
        centtripAccounts.map((account) => (
          <Menu.Item key={account.id}>{account.name}</Menu.Item>
        ))}
    </Menu>
  );

  const handleExecutePayment = () => {
    const updatedRows = selectedRows.map(async (row) => {
      if (selectedAccountId && row.id) {
        try {
          await payInvoice(selectedAccountId, row.id);
          await processInvoice(row, "payment_executed");
          reload();
          return {
            ...row,
            paymentStatus: "success",
            paymentResponse: "Payment Submitted Successfuly",
          };
        } catch (res: any) {
          const data = await res.json();
          return {
            ...row,
            paymentStatus: "failed",
            paymentResponse: data.message + ". " + data?.data?.RecipientErrors,
          };
        }
      }
      return Promise.resolve(row);
    });

    Promise.all(updatedRows).then((updatedRowsData) => {
      setSelectedRows(() => updatedRowsData);
    });
  };

  const totalsByCurrency = selectedRows.reduce((totals, row) => {
    const { currency, amount } = row;
    if (!currency || !amount) {
      return totals;
    }
    //@ts-ignore
    const numericAmount = parseFloat(amount) || 0;
    if (totals.hasOwnProperty(currency)) {
      totals[currency] += numericAmount;
    } else {
      totals[currency] = numericAmount;
    }
    return totals;
  }, {} as Record<string, number>);

  return (
    <>
      <div>
        <Row style={{ marginBottom: "1.5rem" }}>
          <Col span={12}>
            <Dropdown
              trigger={["click"]}
              overlay={menu}
              visible={dropdownVisible}
              onVisibleChange={handleDropdownVisibleChange}
            >
              <Button onClick={() => setDropdownVisible(!dropdownVisible)}>
                {selectedAccountId ? (
                  centtripAccounts?.find(
                    (account) => account.id === selectedAccountId,
                  )?.name
                ) : (
                  <>
                    Select an account <DownOutlined />
                  </>
                )}
              </Button>
            </Dropdown>
          </Col>
        </Row>
        <Row style={{ marginBottom: "1.5rem" }}>
          {balances &&
            balances.map(({ currency, amount }) => (
              <Col span={4}>
                <Statistic title={currency} value={amount} precision={2} />
              </Col>
            ))}
        </Row>
        <Divider />
        {selectedRows?.length > 0 && (
          <div>
            <span style={{ fontSize: "18px" }}>Summary</span>
            <Row style={{ marginTop: "1rem", marginBottom: "1.5rem" }}>
              <Table
                className="no-min-height-table"
                pagination={false}
                dataSource={selectedRows}
                // footer={renderFooter}
                columns={[
                  {
                    title: "Document Date",
                    dataIndex: "documentDate",
                    key: "documentDate",
                    render: (createdAt: string) => (
                      <>
                        {new Date(parseInt(createdAt, 10)).toLocaleDateString()}
                      </>
                    ),
                  },
                  {
                    title: "Currency",
                    dataIndex: "currency",
                    key: "currency",
                  },
                  {
                    title: "Amount",
                    dataIndex: "amount",
                    key: "amount",
                    render: (text) => {
                      const formattedAmount = parseFloat(text).toFixed(2);
                      return <span>{formattedAmount}</span>;
                    },
                  },
                  {
                    title: "Payment Status",
                    dataIndex: "paymentStatus",
                    key: "paymentStatus",
                    render: (text) => {
                      if (text === "success") {
                        return (
                          <span
                            style={{
                              color: "#389e0d",
                              textAlign: "center",
                              display: "block",
                            }}
                          >
                            <CheckCircleOutlined />
                          </span>
                        );
                      } else if (text === "failed") {
                        return (
                          <span
                            style={{
                              color: "#f5222d",
                              textAlign: "center",
                              display: "block",
                            }}
                          >
                            <CloseCircleOutlined />
                          </span>
                        );
                      }
                    },
                  },
                  {
                    title: "Payment Response",
                    dataIndex: "paymentResponse",
                    key: "paymentResponse",
                  },
                ]}
              />
            </Row>
            <div>
              {Object.keys(totalsByCurrency).map((currency) => (
                <div key={currency} style={{ fontSize: 16 }}>
                  Total {currency}: {totalsByCurrency[currency].toFixed(2)}
                </div>
              ))}
            </div>
            <Row style={{ marginBottom: "1.5rem" }}>
              <Tooltip placement="bottomRight" title="Execute">
                <Popconfirm
                  placement="topLeft"
                  title="Execute payments?"
                  okText="Yes"
                  cancelText="No"
                  onConfirm={handleExecutePayment}
                >
                  <Button style={{ marginTop: "1.5rem" }} key="2">
                    Pay via Centtrip
                  </Button>
                </Popconfirm>
              </Tooltip>
            </Row>
          </div>
        )}
      </div>
      <Table
        scroll={{ x: 2000 }}
        columns={columns}
        rowKey="id"
        loading={loading}
        pagination={pagination}
        dataSource={content}
        onChange={handleChange}
        // scroll={{ x: 2000 }}
        // scroll={content.length > 0 ? { x: 2000 } : undefined}
        rowSelection={rowSelection}
        onRow={(record: IInvoice) => {
          return {
            onDoubleClick: (evt) => {
              evt.preventDefault();
              getInvoiceById(record.id!).then((res: IInvoice) => {
                setSelectedInvoice({
                  ...res,
                } as unknown as IInvoice);
                show();
              });
            },
          };
        }}
      />

      <InvoiceModal {...modalProps} invoice={selectedInvoice} />

      {auditModalParams?.id ? (
        <AuditModal {...{ ...auditModalProps, ...auditModalParams }} />
      ) : null}
    </>
  );
};

export default CenttripInvoices;
