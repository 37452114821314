import React, { useEffect, useState, useCallback, useMemo } from "react";
import { IDataSourceFetchFunc } from "./useDataSource";

export type IDictionaryRecord = {
  content: Array<any>;
  loading: boolean;
  error: boolean;
};

export type IDictionariesProvider = {
  dictionaries: Record<string, IDataSourceFetchFunc<any>>;
};

export type IDictionaryContext = {
  dicts: Record<string, IDictionaryRecord>;
  reload: Function;
};

const init = (definition: Record<string, IDataSourceFetchFunc<any>>) =>
  Object.entries(definition).reduce(
    (acc, [key]) => ({
      ...acc,
      [key]: { content: [], loading: true, error: false },
    }),
    {},
  );

const load = (definition: Record<string, IDataSourceFetchFunc<any>>) =>
  Promise.all(
    Object.entries(definition).map(([key, fetcher]) =>
      fetcher({ pageSize: 1000 })
        .then((result) => ({
          [key]: { content: result.content, loading: false, error: false },
        }))
        .catch((err) => {
          console.error(err);
          return { [key]: { content: [], loading: false, error: true } };
        }),
    ),
  ).then((res) => res.reduce((acc, val) => ({ ...acc, ...val }), {}));

export default function useDictionaries(
  definition: Record<string, IDataSourceFetchFunc<any>>,
): [Record<string, IDictionaryRecord>, Function] {
  const [dictionaries, setDictionaries] = useState(init(definition));

  const reload = useCallback(() => {
    Promise.resolve()
      .then(() => init(definition))
      .then(() => load(definition))
      .then((res) => setDictionaries(res));
  }, [definition]);

  useEffect(() => {
    reload();
  }, [reload]);

  return [dictionaries, reload];
}

export const DictsContext: React.Context<IDictionaryContext> =
  React.createContext({ dicts: {}, reload: () => null } as IDictionaryContext);

export const DictsProvider: React.FC<IDictionariesProvider> = ({
  dictionaries,
  children,
}) => {
  const [dicts, reload] = useDictionaries(dictionaries);
  const value = useMemo(() => ({ dicts, reload }), [dicts, reload]);

  return (
    <DictsContext.Provider value={value}>{children}</DictsContext.Provider>
  );
};
