import React, { useEffect, useState, useContext } from "react";
import { Upload, message, Button, Collapse, PageHeader } from "antd";
import { DownloadOutlined, UploadOutlined } from "@ant-design/icons";

import useRefresh from "../../../hooks/useRefresh";
import useDictionaries from "../../../hooks/useDictionaries";
import useModal from "../../../hooks/useModal";

import { getGroups } from "../../../services/groups";
import { getCategories } from "../../../services/categories";
import { getCountries } from "../../../services/countries";
import { IContact, exportCsv } from "../../../services/contacts";
import { getRoles } from "../../../services/roles";
import { getScopes } from "../../../services/scopes";

import GenericContacts from "./GenericContacts";
import ContactsModal from "./ContactsModal";

import { UserContext } from "../../../components/auth";
import { currentDateAsString, hasPermission } from "../../../utils/helpers";

const dictionaries = {
  groups: getGroups,
  countries: getCountries,
  categories: getCategories,
  roles: getRoles,
  scopes: getScopes,
};

interface BankAccountsListProps {}

const ContactsList: React.FC<BankAccountsListProps> = () => {
  const { user } = useContext(UserContext);
  const { role } = user;
  const [token, refresh] = useRefresh();

  const csvProps = {
    name: "file",
    beforeUpload: (file: any) => {
      const isCsv = file.type === "text/csv";
      if (!isCsv) {
        message.error(`${file.name} is not a csv file`);
      }
      return isCsv || Upload.LIST_IGNORE;
    },
    withCredentials: true,
    action: `${process.env.REACT_APP_BACKEND_URL}/contacts/csv`,
    showUploadList: false,
    maxCount: 1,
    onChange: () => refresh(),
  };

  const { Panel } = Collapse;

  const [dicts] = useDictionaries(dictionaries);

  const [selectedContact, setSelectedContact] = useState<IContact | null>(null);

  const [show, modalProps] = useModal(refresh);

  useEffect(() => {
    if (selectedContact) {
      show();
    }
  }, [selectedContact, show]);

  return (
    <>
      <PageHeader
        className="site-page-header-responsive"
        onBack={() => window.history.back()}
        title="Contacts"
        extra={
          hasPermission(role, ["ADMIN", "USER"])
            ? [
                <Button
                  type="primary"
                  onClick={() => setSelectedContact({} as IContact)}
                >
                  New Contact
                </Button>,
                <Upload {...csvProps}>
                  <Button icon={<UploadOutlined />}>Upload CSV</Button>
                </Upload>,
                <Button
                  key="2"
                  icon={<DownloadOutlined />}
                  onClick={() =>
                    exportCsv().then((buffer) => {
                      const url = window.URL.createObjectURL(
                        new Blob([buffer]),
                      );
                      const link = window.document.createElement("a");
                      link.href = url;
                      link.setAttribute(
                        "download",
                        `${currentDateAsString}"contacts-as-csv.csv`,
                      );
                      window.document.body.appendChild(link);
                      link.click();
                      link.parentNode!.removeChild(link);
                    })
                  }
                >
                  Download CSV
                </Button>,
              ]
            : null
        }
      >
        <Collapse defaultActiveKey={["1", "2", "3"]}>
          <Panel header="Category Entity" key="1">
            <GenericContacts
              shouldRefresh={token}
              contactType="entity"
              dictionaries={dicts}
              onRowDblClick={setSelectedContact}
            />
          </Panel>
          <Panel header="Category Bank" key="2">
            <GenericContacts
              shouldRefresh={token}
              contactType="bank"
              dictionaries={dicts}
              onRowDblClick={setSelectedContact}
            />
          </Panel>
          <Panel header="Category Agent" key="3">
            <GenericContacts
              shouldRefresh={token}
              contactType="agent"
              dictionaries={dicts}
              onRowDblClick={setSelectedContact}
            />
          </Panel>
        </Collapse>
      </PageHeader>

      <ContactsModal
        onAfterAction={refresh}
        contactModalForm="AgentsContactModalForm"
        dictionaries={dicts}
        {...modalProps}
        contact={selectedContact}
      />
    </>
  );
};

export default ContactsList;
