import React, { useState, useEffect, useContext, useMemo } from "react";
import { Collapse, PageHeader } from "antd";

import { Default } from "../layouts";
import { getDashboardDetails } from "../services/dashboard";
import TenantDetails from "../components/dashboard/TenantDashboard";

import { UserContext } from "../components/auth";
import { hasPermission } from "../utils/helpers";

const Dashboard = () => {
  const { user } = useContext(UserContext);
  const { role } = user;

  const [tenantsDashboard, setTenantsDashboard] = useState([]);
  useEffect(() => {
    getDashboardDetails().then((res: any) => setTenantsDashboard(res));
  }, [setTenantsDashboard]);

  const ids = useMemo(
    () => tenantsDashboard.map((_tenant: any, idx) => idx),
    [tenantsDashboard],
  );

  const CollapsedTenants = () => {
    return (
      ids.length > 0 && (
        <Collapse defaultActiveKey={ids}>
          {tenantsDashboard.map((tenant: any, idx) => (
            <Collapse.Panel header={tenant.tenant_name} key={idx}>
              {hasPermission(role, ["ADMIN", "OBSERVER", "MANAGER"]) ? (
                <TenantDetails tenantData={tenant} />
              ) : null}
            </Collapse.Panel>
          ))}
        </Collapse>
      )
    );
  };

  return (
    <Default>
      <PageHeader
        className="site-page-header-responsive"
        onBack={() => window.history.back()}
        title="Dashboard"
      >
        {CollapsedTenants()}
      </PageHeader>
    </Default>
  );
};

export default Dashboard;
