import React, { useMemo, useCallback, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";

import {
  Tooltip,
  Button,
  Table,
  Modal,
  Form,
  Input,
  Row,
  Col,
  Select,
  notification,
  Tag,
  Switch,
} from "antd";
import {
  CheckOutlined,
  CloseOutlined,
  DownloadOutlined,
} from "@ant-design/icons";

import {
  downloadFile,
  setFileAttachable,
  IFile,
  setFileToIncludeInAccounting,
} from "../../../services/files";

import { UserContext } from "../../../components/auth";

import { ModalProps } from "../../../hooks/useModal";

import { ILoan, saveLoan } from "../../../services/loans";
import DatePickerMillis from "../../DatePickerMillis";
import FileSizeLabel from "../../FileSizeLabel";
import UploadDropZone from "../../UploadDropZone";

interface LoanModalProps extends ModalProps {
  loan: ILoan | null;
  dictionaries: Record<
    string,
    { content: Array<any>; loading: boolean; error: boolean }
  >;
  onAfterAction: Function;
}

const LoanModal: React.FC<LoanModalProps> = ({
  visible,
  onOk,
  onCancel,
  loan,
  dictionaries,
  onAfterAction,
}) => {
  const [form] = Form.useForm();
  const { user } = useContext(UserContext);
  const { role } = user;

  const { t } = useTranslation();

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(loan);
  }, [form, loan]);

  const {
    companies,
    thirdParties,
    dayCountConversion,
    currencies,
    entityActingAs,
    // status,
    alertPeriods,
  } = dictionaries;

  const handleFinish = useCallback(
    (value) => {
      const entity: ILoan = {
        ...loan,
        ...value,
      };

      saveLoan(entity)
        .then(() => {
          onOk(null);
          notification.success({ message: "Document saved" });
          onAfterAction();
        })
        .catch((err) => {
          console.error(err);
          notification.error({
            message:
              role === "OBSERVER"
                ? "Cannot modify record"
                : "Something went wrong",
          });
        });
    },

    [loan, onAfterAction, onOk, role],
  );

  const handleDownload = useCallback((file) => {
    downloadFile(file!).then((buffer) => {
      const url = window.URL.createObjectURL(new Blob([buffer]));
      const link = window.document.createElement("a");
      link.href = url;
      link.setAttribute("download", file!.fileName);
      window.document.body.appendChild(link);
      link.click();
      link.parentNode!.removeChild(link);
    });
  }, []);

  const handleChangeAttachmentTag = useCallback(
    (index: number, file: IFile, attachable: boolean) => {
      setFileAttachable(file, attachable).then(() => {
        form.setFieldsValue({
          files: form.getFieldValue("files").map((val: IFile, idx: number) => ({
            ...val,
            attachable: idx === index ? attachable : val.attachable,
          })),
        });
      });
    },
    [form],
  );

  const handleChangeIncludeInAccounting = useCallback(
    (index: number, file: IFile, includeInAccounting: boolean) => {
      setFileToIncludeInAccounting(file, includeInAccounting).then(() => {
        form.setFieldsValue({
          files: form.getFieldValue("files").map((val: IFile, idx: number) => ({
            ...val,
            includeInAccounting:
              idx === index ? includeInAccounting : val.includeInAccounting,
          })),
        });
      });
    },
    [form],
  );

  const fileColumns = useMemo(
    () => [
      {
        title: "File name",
        dataIndex: "fileName",
        key: "fileName",
      },
      {
        title: "File size",
        dataIndex: "fileSize",
        key: "fileSize",
        render: (size: number) => <FileSizeLabel size={size} />,
      },
      {
        title: "Created by",
        dataIndex: "createdBy",
        key: "createdBy",
      },
      {
        title: "Created at",
        dataIndex: "createdAt",
        key: "createdAt",
        render: (createdAt: string) => (
          <>{new Date(parseInt(createdAt, 10)).toLocaleDateString()}</>
        ),
      },
      {
        title: "Attach to email",
        dataIndex: "attachable",
        key: "attachable",
        shouldCellUpdate: (current: IFile, prev: IFile) => true,
        render: (value: boolean, record: IFile, index: number) => {
          return (
            <>
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                checked={value}
                // disabled={index === 0}
                onClick={(val) => handleChangeAttachmentTag(index, record, val)}
              />
            </>
          );
        },
      },
      {
        title: "Accounting document",
        dataIndex: "includeInAccounting",
        key: "includeInAccounting",
        shouldCellUpdate: (current: IFile, prev: IFile) => true,
        render: (value: boolean, record: IFile, index: number) => {
          return (
            <>
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                checked={value}
                onClick={(val) =>
                  handleChangeIncludeInAccounting(index, record, val)
                }
              />
            </>
          );
        },
      },
      {
        title: "Actions",
        dataIndex: "_actions",
        key: "_actions",
        render: (_value: string, record: ILoan) => {
          return (
            <Tooltip title="Download file">
              <Button
                type="text"
                size="small"
                icon={<DownloadOutlined />}
                onClick={() => handleDownload(record)}
              />
            </Tooltip>
          );
        },
      },
    ],
    [
      handleChangeAttachmentTag,
      handleChangeIncludeInAccounting,
      handleDownload,
    ],
  );

  return (
    <Form
      onFinish={handleFinish}
      form={form}
      id="LoanModalForm"
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 16 }}
    >
      <Modal
        title="Loan modal"
        visible={visible}
        okButtonProps={{ htmlType: "submit", form: "LoanModalForm" }}
        onCancel={onCancel}
        width={1600}
      >
        <Row>
          <Col span={12}>
            <Form.Item
              rules={[{ required: true, message: "Required Field" }]}
              label="Agreement date"
              name="documentDate"
            >
              <DatePickerMillis />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              rules={[{ required: true, message: "Required Field" }]}
              label="Concern Entity"
              name={["documentTo", "id"]}
            >
              <Select loading={companies.loading}>
                {companies.content.map(
                  (element: { id: number; name: string }) => (
                    <Select.Option value={element.id} key={element.id}>
                      {element.name}
                    </Select.Option>
                  ),
                )}
              </Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label="Entity acting as" name="entityActingAs">
              <Select>
                {entityActingAs.content.map(
                  (element: { value: string; label: string }) => (
                    <Select.Option value={element.value} key={element.value}>
                      {element.label}
                    </Select.Option>
                  ),
                )}
              </Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              // rules={[{ required: true, message: "Required Field" }]}
              label="Concern Third Party"
              name={["documentFrom", "id"]}
            >
              <Select loading={companies.loading}>
                {thirdParties.content.map(
                  (element: { id: number; name: string }) => (
                    <Select.Option value={element.id} key={element.id}>
                      {element.name}
                    </Select.Option>
                  ),
                )}
              </Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label="Purpose" name="purpose">
              <Input />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label="Other parties / Guarantee" name="otherParties">
              <Input />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label="Amount" name="amount">
              <Input />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label="CCY" name="currency">
              <Select loading={companies.loading}>
                {currencies.content.map(
                  (element: { value: string; label: string }) => (
                    <Select.Option value={element.value} key={element.value}>
                      {element.label}
                    </Select.Option>
                  ),
                )}
              </Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label="Interest rate" name="interestRate">
              <Input type="number" prefix="%" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label="Default rate" name="defaultRate">
              <Input prefix="%" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label="Day-Count Convention" name="dayCountConversion">
              <Select loading={companies.loading}>
                {dayCountConversion.content.map(
                  (element: { value: string; label: string }) => (
                    <Select.Option value={element.value} key={element.value}>
                      {element.label}
                    </Select.Option>
                  ),
                )}
              </Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label="Next Interest Date" name="nextInterestDate">
              <DatePickerMillis />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label="Next Important Date" name="nextAmortisationDate">
              <DatePickerMillis />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label="Alert Period" name="alertPeriod">
              <Select>
                {alertPeriods.content.map(
                  (element: { value: string; label: string }) => (
                    <Select.Option value={element.value} key={element.value}>
                      {element.label}
                    </Select.Option>
                  ),
                )}
              </Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label="Maturity Date" name="maturityDate">
              <DatePickerMillis />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label="Status" name="status">
              <Tag>{t(`document.status.${loan?.status}`)}</Tag>
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              label="Comments"
              name="comments"
              labelCol={{ span: 3 }}
              wrapperCol={{ span: 20 }}
            >
              <Input.TextArea rows={5} />
            </Form.Item>
          </Col>
        </Row>

        <UploadDropZone action={`/documents/loans/${loan?.id}/upload`} />

        <Form.Item
          shouldUpdate
          labelCol={{ span: 0 }}
          wrapperCol={{ span: 24 }}
        >
          {() => (
            <Table
              columns={fileColumns}
              rowKey="id"
              dataSource={form.getFieldValue("files") || []}
              className="pf-table-auto"
            />
          )}
        </Form.Item>
      </Modal>
    </Form>
  );
};

export default LoanModal;
