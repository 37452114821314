import { parseError } from "../utils/fetch";

const getDashboardDetails: () => Promise<Record<string, any>> = () =>
  fetch(`${process.env.REACT_APP_BACKEND_URL}/dashboard/`, {
    method: "GET",
    mode: "cors",
    credentials: "include",
    headers: { "Content-Type": "application/json" },
  }).then((res) => (res.ok ? res.json() : parseError(res)));

export { getDashboardDetails };
