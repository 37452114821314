import React, { useCallback, useState, useContext } from "react";
import { Collapse, PageHeader, Button } from "antd";
import { DownloadOutlined } from "@ant-design/icons";

import useRefresh from "../../../hooks/useRefresh";
import useDictionaries from "../../../hooks/useDictionaries";
import useModal from "../../../hooks/useModal";

import { getGroups } from "../../../services/groups";
import {
  getDocumentById,
  IDocument,
  exportCsv,
} from "../../../services/documents";
import { getDocumentTypes } from "../../../services/document-types";
import { getCompanies } from "../../../services/companies";
import { getEntityActingAs } from "../../../services/entity-acting-as";
import { getThirdParties } from "../../../services/third-parties";
import { getDocumentStatus } from "../../../services/docment-status";
import { getCurrencies } from "../../../services/currencies";
import { getDayCountConversion } from "../../../services/day-count-conversion";
import {
  getEmailTemplates,
  IEmailTemplate,
} from "../../../services/email-templates";
import { getContactsByType } from "../../../services/contacts";
import { getAlertPeriods } from "../../../services/alert-periods";

import { TableRowSelection } from "antd/lib/table/interface";
// import { processInvoice } from "../../../services/invoices";

import GenericDocuments from "./GenericDocuments";
import DocumentModal from "./DocumentModal";
import SendMailForm from "../../SendMailForm";

import { UserContext } from "../../../components/auth";
import { currentDateAsString, hasPermission } from "../../../utils/helpers";

const dictionaries = {
  groups: getGroups,
  companies: getCompanies,
  thirdParties: getThirdParties,
  currencies: getCurrencies,
  dayCountConversion: getDayCountConversion,
  entityActingAs: getEntityActingAs,
  documentTypes: getDocumentTypes,
  status: getDocumentStatus,
  contacts: getContactsByType("all"),
  templates: getEmailTemplates,
  alertPeriods: getAlertPeriods,
};

interface BankAccountsListProps {}

const DocumentsList: React.FC<BankAccountsListProps> = () => {
  const { user } = useContext(UserContext);
  const { role } = user;

  const [dicts] = useDictionaries(dictionaries);
  const { contacts, templates } = dicts;

  const [token, refresh] = useRefresh();

  const [selectedDocument, setSelectedDocument] = useState<IDocument | null>(
    null,
  );
  const [show, modalProps] = useModal(refresh);

  const handleRowDblClick = useCallback(
    (document: IDocument | null) => {
      getDocumentById(document!.id!).then((res: IDocument) => {
        setSelectedDocument(res);
        show();
      });
    },
    [show],
  );
  const [selectedRows, setSelectedRows] = useState<Array<React.Key>>([] as any);

  const rowSelection: TableRowSelection<IDocument> = {
    selectedRowKeys: selectedRows,
    columnTitle: <>Mail</>,
    onChange: (selectedRowKeys: React.Key[]) => {
      setSelectedRows(selectedRowKeys);
    },
  };

  const onAfterMailSent = () => {
    setSelectedRows([]);
  };

  // const handleMailSent = useCallback(() => {
  //   Promise.allSettled(
  //     selectedRows.map((id) =>
  //       processInvoice({ id: id as number }, "submit_validate"),
  //     ),
  //   )
  //     .then(() => setSelectedRows([]))
  //     .then(refresh());
  // }, [selectedRows, refresh]);

  const to = contacts.content;

  // .filter((contact: any) => {
  //   const roles = contact?.scope?.split(",");
  //   const acceptedRoles = roles.filter(
  //     (roleStr: string) => roleStr === "validation",
  //   );
  //   return acceptedRoles.length > 0;
  // });

  const cc = contacts.content;

  // .filter((contact: any) => {
  //   const roles = contact?.scope?.split(",");
  //   const acceptedRoles = roles.filter(
  //     (roleStr: string) => roleStr !== "execution",
  //   );
  //   return acceptedRoles.length > 0;
  // });

  const filteredEmailTemplates = templates.content.filter(
    (emailTemplate: IEmailTemplate) => {
      const roles = emailTemplate?.scope?.split(",") as string[];
      const acceptedRoles = roles.filter(
        (roleStr: string) => roleStr === "information",
      );
      return acceptedRoles.length > 0;
    },
  );
  // roleStr === "execution" ||

  return (
    <>
      <PageHeader
        className="site-page-header-responsive"
        onBack={() => window.history.back()}
        title="Documents"
        extra={[
          <Button
            key="2"
            icon={<DownloadOutlined />}
            onClick={() =>
              exportCsv().then((buffer) => {
                const url = window.URL.createObjectURL(new Blob([buffer]));
                const link = window.document.createElement("a");
                link.href = url;
                link.setAttribute(
                  "download",
                  `${currentDateAsString}"documents-as-csv.csv`,
                );
                window.document.body.appendChild(link);
                link.click();
                link.parentNode!.removeChild(link);
              })
            }
          >
            Download CSV
          </Button>,
        ]}
      >
        {hasPermission(role, ["ADMIN", "USER", "MANAGER"]) ? (
          <SendMailForm
            type="document"
            scope="information"
            documents={selectedRows}
            onAfterMailSent={onAfterMailSent}
            templates={filteredEmailTemplates}
            to={to}
            cc={cc}
            labelTo="Validation"
            labelCC="Information"
          />
        ) : null}

        <Collapse defaultActiveKey={["1", "2", "3"]}>
          <Collapse.Panel header="Documents New" key="1">
            <GenericDocuments
              shouldRefresh={token}
              dictionaries={dicts}
              onRowDblClick={handleRowDblClick}
              status="new"
              rowSelection={rowSelection}
            />
          </Collapse.Panel>
          <Collapse.Panel header="Documents In Force" key="2">
            <GenericDocuments
              shouldRefresh={token}
              dictionaries={dicts}
              onRowDblClick={handleRowDblClick}
              status="not_closed"
              rowSelection={rowSelection}
            />
          </Collapse.Panel>
          <Collapse.Panel header="Documents Archived" key="3">
            <GenericDocuments
              shouldRefresh={token}
              dictionaries={dicts}
              onRowDblClick={handleRowDblClick}
              status="closed"
              rowSelection={rowSelection}
            />
          </Collapse.Panel>
        </Collapse>
      </PageHeader>
      <DocumentModal
        onAfterAction={refresh}
        dictionaries={dicts}
        {...modalProps}
        document={selectedDocument}
      />
    </>
  );
};

export default DocumentsList;
