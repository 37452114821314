import React from "react";
import UsersList from "../../components/admin/users/UsersList";

import { Default } from "../../layouts";

const Users = () => {
  return (
    <Default>
      <UsersList />
    </Default>
  );
};

export default Users;
