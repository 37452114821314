export interface KycAuthenticationType {
  value: string;
  label: string;
}

const kycAuthenticationTypes = {
  content: [
    { value: "None", label: "None" },
    { value: "Signed", label: "Signed" },
    { value: "Certified", label: "Certified" },
    { value: "Apostilled", label: "Apostilled" },
  ],
};

export const getKycAuthenticationTypes: () => Promise<{
  content: Array<KycAuthenticationType>;
}> = () => Promise.resolve(kycAuthenticationTypes);
