type ParseErrorFunc = (err: Response) => Promise<Response>;

const parseError: ParseErrorFunc = (err) => {
  if (err.status === 401) {
    window.location.href = `/?code=${err.status}`;
    return Promise.reject({
      _: "Authentication token expired. Redirecting to login page",
    });
  }

  return err.json().then((body) => Promise.reject(body));
};

export { parseError };
