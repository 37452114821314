interface IUserDetails {
  user: {
    email: string;
    fullname: string;
  };
  tenant: {
    name: string;
    primaryColor: string;
    secondaryColor: string;
  };
}

const getCurrentUserDetails: () => Promise<IUserDetails | null> = () =>
  fetch(`${process.env.REACT_APP_BACKEND_URL}/auth`, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: { "Content-Type": "application/json" },
  }).then((res) => (res.ok ? res.json() : Promise.reject(res)));

const logout: () => Promise<any> = () =>
  fetch(`${process.env.REACT_APP_BACKEND_URL}/auth`, {
    method: "DELETE",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
  }).catch(() => Promise.resolve(null));

const resetPassword: (email: string) => Promise<any> = (email) =>
  fetch(`${process.env.REACT_APP_BACKEND_URL}/auth/local/reset`, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email }),
  }).then((res) => (res.ok ? Promise.resolve() : Promise.reject()));

const createNewUser: (values: any, token: string) => Promise<any> = (
  values,
  token,
) =>
  fetch(
    `${process.env.REACT_APP_BACKEND_URL}/auth/local/create-new-user/${token}`,
    {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ values }),
    },
  ).then((res) => (res.ok ? Promise.resolve() : Promise.reject()));

const reassignUser: (oken: string) => Promise<any> = (token) =>
  fetch(
    `${process.env.REACT_APP_BACKEND_URL}/auth/local/reassign-user/${token}`,
    {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    },
  ).then((res) => (res.ok ? Promise.resolve() : Promise.reject()));

const changeUserPassword: (passwords: any) => Promise<any> = (passwords) =>
  fetch(`${process.env.REACT_APP_BACKEND_URL}/auth/local/change-password`, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ passwords }),
  }).then((res) => (res.ok ? Promise.resolve() : Promise.reject()));

const resetUserPassword: (token: string, password: string) => Promise<any> = (
  token,
  password,
) =>
  fetch(`${process.env.REACT_APP_BACKEND_URL}/auth/local/reset/${token}`, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ password }),
  }).then((res) => (res.ok ? Promise.resolve() : Promise.reject()));

const resetPasswordToken: (token: string, password: string) => Promise<any> = (
  token,
  password,
) =>
  fetch(`${process.env.REACT_APP_BACKEND_URL}/auth/local/reset/${token}`, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ password }),
  }).then((res) => (res.ok ? Promise.resolve() : Promise.reject()));

export {
  getCurrentUserDetails,
  logout,
  resetPassword,
  resetPasswordToken,
  changeUserPassword,
  createNewUser,
  reassignUser,
  resetUserPassword,
};
