import UploadDropZone from "../components/UploadDropZone";
// import UploadedFiles from "../components/OrphanUploads";
import useRefresh from "../hooks/useRefresh";

import { Default } from "../layouts";

import React, { useMemo, useEffect, useCallback, useState } from "react";

import {
  Button,
  PageHeader,
  Popconfirm,
  Select,
  Table,
  Form,
  Divider,
  notification,
} from "antd";
import { DeleteOutlined } from "@ant-design/icons";
// import { grey } from "@ant-design/colors";

import useDatasource, { IDataSourceFetchFunc } from "../hooks/useDataSource";

import {
  getOrphanUploads,
  deleteOrphanUpload,
  IUpload,
  distributeOrphanUploads,
} from "../services/uploads";
import FileSizeLabel from "../components/FileSizeLabel";

const allowedValues = ["invoice", "document", "loan", "kyc"];

const Upload = () => {
  const [token, refresh] = useRefresh();

  const fetchMethod: IDataSourceFetchFunc<IUpload> = useMemo(() => {
    return getOrphanUploads;
  }, []);

  const { loading, pagination, content, handleChange, reload } =
    useDatasource<IUpload>(fetchMethod);

  const [submitButtonStatus, setSubmitButtonStatus] = useState(false);
  useEffect(() => {
    reload();
  }, [reload, token]);

  const handleDeleteFile = useCallback(
    (id) => {
      deleteOrphanUpload(id).then(() => reload());
    },
    [reload],
  );

  const columns = useMemo(
    () => [
      {
        title: "File name",
        dataIndex: "fileName",
        key: "fileName",
      },
      {
        title: "File size",
        dataIndex: "fileSize",
        key: "fileSize",
        render: (size: number) => <FileSizeLabel size={size} />,
      },
      {
        title: "Created by",
        dataIndex: "createdBy",
        key: "createdBy",
      },
      {
        title: "Created at",
        dataIndex: "createdAt",
        key: "createdAt",
        render: (createdAt: string) => (
          <>{new Date(parseInt(createdAt, 10)).toLocaleDateString()}</>
        ),
      },
      {
        title: "Category",
        dataIndex: "_1",
        key: "_1",
        width: 250,
        render: (_: any, record: Record<string, any>) => (
          <Form.Item noStyle name={`${record.id}`}>
            <Select style={{ width: 250 }}>
              <Select.Option value="invoice">Invoice</Select.Option>
              <Select.Option value="document">Document</Select.Option>
              <Select.Option value="loan">Loan</Select.Option>
              <Select.Option value="kyc">KYC</Select.Option>
            </Select>
          </Form.Item>
        ),
      },
      {
        title: "Action",
        dataIndex: "_99",
        key: "_99",
        width: 100,
        render: (_: any, record: Record<string, any>) => (
          <Popconfirm
            title="Are you sure?"
            onConfirm={() => {
              handleDeleteFile(record.id);
            }}
          >
            <Button type="text" icon={<DeleteOutlined />} />
          </Popconfirm>
        ),
      },
    ],
    [handleDeleteFile],
  );

  const handleSubmit = useCallback(
    (value: Record<number, string>) => {
      setSubmitButtonStatus(true);

      const records = Object.entries(value)
        .filter(([key, value]) => value && allowedValues.includes(value))
        .reduce((acc, current) => {
          const [key, value] = current;
          return { ...acc, [key]: value };
        }, {} as Record<number, string>);

      distributeOrphanUploads(records)
        .then(() => {
          reload();
          setSubmitButtonStatus(false);
        })
        .catch(() => notification.error({ message: "Something went wrong" }));
    },
    [reload],
  );

  return (
    <Default>
      <Form onFinish={handleSubmit}>
        <PageHeader
          className="site-page-header-responsive"
          onBack={() => window.history.back()}
          title="Upload"
          extra={[
            <Button
              disabled={submitButtonStatus}
              type="primary"
              htmlType="submit"
            >
              Submit
            </Button>,
          ]}
        >
          <UploadDropZone onUploadComplete={refresh as Function} action="" />

          <Table
            columns={columns}
            rowKey="id"
            loading={loading}
            pagination={pagination}
            dataSource={content}
            onChange={handleChange}
          />
          <Divider />
          {/* <UploadedFiles shouldRefresh={token} /> */}
        </PageHeader>
      </Form>
    </Default>
  );
};

export default Upload;
