import React from "react";
import ReactDOM from "react-dom";

import i18n from "i18next";
import backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

import reportWebVitals from "./reportWebVitals";

import App from "./App";

import "./index.scss";

i18n
  .use(backend)
  .use(initReactI18next)
  .init({
    lng: "en",
    fallbackLng: "en",
    ns: ["translation"],
    defaultNS: "translation",
    backend: {
      loadPath: "/locales/{{lng}}.json",
    },
    react: {
      useSuspense: false,
    },
  });

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root"),
);

if (process.env.NODE_ENV !== "production") {
  reportWebVitals(console.log);
}
