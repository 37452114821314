import React, { useMemo } from "react";

import { Spin, Typography } from "antd";
import { WarningOutlined } from "@ant-design/icons";

interface DictionaryLabelProps {
  dictionary: { content: Array<any>; loading: boolean; error: boolean };
  value: any;
  keyField?: string;
  labelField?: string | ((obj: any) => string);
}

const warningIcon = <WarningOutlined style={{ fontSize: 16 }} />;
const defaultKeyField = "id";
const defaultLabelField = "name";

const DictionaryLabel: React.FC<DictionaryLabelProps> = ({
  dictionary,
  value,
  keyField = defaultKeyField,
  labelField = defaultLabelField,
}) => {
  const label = useMemo(() => {
    // eslint-disable-next-line eqeqeq
    if (value == undefined) {
      return "";
    }

    // eslint-disable-next-line eqeqeq
    const val = dictionary.content.find((obj) => obj[keyField] == value);

    // eslint-disable-next-line eqeqeq
    if (val == undefined) {
      return "???";
    }

    return typeof labelField === "function" ? labelField(val) : val[labelField];
  }, [dictionary.content, keyField, labelField, value]);

  return (
    <>
      {dictionary.loading ? (
        <Spin size="small" />
      ) : dictionary.error ? (
        <Spin size="small" spinning={false} indicator={warningIcon} />
      ) : (
        <Typography.Text>{label}</Typography.Text>
      )}
    </>
  );
};

export default DictionaryLabel;
