import React from "react";
import ThirdPartiesList from "../../components/entity/ThirdPartiesList";

import { Default } from "../../layouts";

const Dashboard = () => {
  return (
    <Default>
      <ThirdPartiesList />
    </Default>
  );
};

export default Dashboard;
