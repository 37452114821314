import { criteriaQuery } from "../utils/query";
import {
  IDataSourceFetchFunc,
  IDataSourceRequest,
} from "./../hooks/useDataSource";
export interface IUpload {
  id?: number;
}

const getOrphanUploads: IDataSourceFetchFunc<IUpload> = (
  req: IDataSourceRequest,
) =>
  fetch(`${process.env.REACT_APP_BACKEND_URL}/upload?${criteriaQuery(req)}`, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: { "Content-Type": "application/json" },
  }).then((res) => (res.ok ? res.json() : Promise.reject(res)));

const deleteOrphanUpload = (id: number) =>
  fetch(`${process.env.REACT_APP_BACKEND_URL}/upload/${id}`, {
    method: "DELETE",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: { "Content-Type": "application/json" },
  }).then((res) => (res.ok ? Promise.resolve() : Promise.reject(res)));

const distributeOrphanUploads = (records: Record<number, string>) =>
  fetch(`${process.env.REACT_APP_BACKEND_URL}/upload/dist`, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    body: JSON.stringify(records),
    credentials: "include",
    headers: { "Content-Type": "application/json" },
  }).then((res) => (res.ok ? Promise.resolve() : Promise.reject(res)));

export { getOrphanUploads, deleteOrphanUpload, distributeOrphanUploads };
