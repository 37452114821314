import React, { useCallback, useEffect } from "react";

import { Modal, Form, Input, Row, Col, Select, notification } from "antd";
import { ModalProps } from "../../hooks/useModal";
import { IBank, saveBank, createBank } from "../../services/banks";

interface BankModalProps extends ModalProps {
  bank: IBank | null;
  dictionaries: Record<
    string,
    { content: Array<any>; loading: boolean; error: boolean }
  >;
}

const BankModal: React.FC<BankModalProps> = ({
  visible,
  onOk,
  onCancel,
  bank,
  dictionaries,
}) => {
  const [form] = Form.useForm();

  const { groups, countries } = dictionaries;
  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(bank);
    form.setFieldsValue({
      ...bank,
      groups: bank?.groups?.split(",").filter((v: string) => !!v),
      // tags: emailTemplate?.tags?.split(",").filter((v: string) => !!v),
    });
  }, [form, bank]);

  const handleFinish = useCallback(
    (value) => {
      const entity: IBank = {
        ...bank,
        ...value,
      };

      (entity.id ? saveBank : createBank)(entity)
        .then(() => {
          notification.success({ message: "Successfully saved" });

          onOk(null);
        })
        .catch(() => {
          notification.error({ message: "Something went wrong" });
        });
    },
    [bank, onOk],
  );

  return (
    <Form
      onFinish={handleFinish}
      form={form}
      id="BankModalForm"
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 16 }}
    >
      <Modal
        title="Bank details"
        visible={visible}
        okButtonProps={{ htmlType: "submit", form: "BankModalForm" }}
        onCancel={onCancel}
        width={1600}
      >
        <Row>
          <Col span={12}>
            <Form.Item
              rules={[{ required: true, message: "Required Field" }]}
              label="Groups"
              name="groups"
            >
              <Select mode="multiple" loading={groups.loading}>
                {groups.content.map((group: { id: number; name: string }) => (
                  <Select.Option value={group.name} key={group.id}>
                    {group.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              rules={[{ required: true, message: "Required Field" }]}
              label="Bank name"
              name="name"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              rules={[{ required: true, message: "Required Field" }]}
              label="Address"
              name="address"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              rules={[{ required: true, message: "Required Field" }]}
              label="Country"
              name="country"
            >
              <Select
                showSearch
                loading={countries.loading}
                filterOption={(input, option) =>
                  option?.children.toLowerCase().indexOf(input.toLowerCase()) >=
                    0 ||
                  option?.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {countries.content.map(
                  (country: { value: string; label: string }) => (
                    <Select.Option value={country.value} key={country.value}>
                      {country.label}
                    </Select.Option>
                  ),
                )}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              rules={[{ required: true, message: "Required Field" }]}
              label="BIC / SWIFT"
              name="bicSwift"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Co Bank Name" name="coBankAccountName">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Co BIC / SWIFT" name="coBicSwift">
              <Input />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Comment"
              name="comment"
              labelCol={{ span: 2 }}
              wrapperCol={{ span: 20 }}
            >
              <Input.TextArea rows={5} />
            </Form.Item>
          </Col>
        </Row>
      </Modal>
    </Form>
  );
};

export default BankModal;
