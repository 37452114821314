import { criteriaQuery } from "../utils/query";

import {
  IDataSourceFetchFunc,
  IDataSourceRequest,
} from "./../hooks/useDataSource";
import { IFile } from "./files";

export interface IKyc {
  id?: number;
  documentDate?: number;
  dateCreated?: number;
  purpose?: string;
  documentFrom?: { id: number; name: string };
  company?: { id: number; name: string };
  comments?: string;
  nextImportantDate?: number;
  maturityDate?: number;
  otherParties?: string;
  status?: string;
  kycCategory?: string;
  kycType?: string;
  kycAuthentication?: string;
  tags?: string;
  otherEntities?: string;
  files?: Array<IFile>;
}

export const getKycById = (id: number) =>
  fetch(`${process.env.REACT_APP_BACKEND_URL}/documents/kycs/${id}`, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: { "Content-Type": "application/json" },
  }).then((res) => (res.ok ? res.json() : Promise.reject(res)));

export const getKycs: IDataSourceFetchFunc<IKyc> = (req: IDataSourceRequest) =>
  fetch(
    `${process.env.REACT_APP_BACKEND_URL}/documents/kycs/?${criteriaQuery(
      req,
    )}`,
    {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    },
  ).then((res) => (res.ok ? res.json() : Promise.reject(res)));

export const getKycsByStatuses =
  (status: string) => (req: IDataSourceRequest) =>
    fetch(
      `${
        process.env.REACT_APP_BACKEND_URL
      }/documents/kycs/${status}?${criteriaQuery(req)}`,
      {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
      },
    ).then((res) => (res.ok ? res.json() : Promise.reject(res)));

export const saveKyc: (kyc: IKyc) => Promise<IKyc> = (kyc) =>
  fetch(`${process.env.REACT_APP_BACKEND_URL}/documents/kycs/${kyc.id}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    mode: "cors",
    credentials: "include",
    body: JSON.stringify(kyc),
  }).then((res) => (res.ok ? res.json() : Promise.reject(res)));

export const duplicateKyc: (kyc: IKyc) => Promise<IKyc> = (kyc) =>
  fetch(
    `${process.env.REACT_APP_BACKEND_URL}/documents/kycs/${kyc.id}/duplicate`,
    {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      mode: "cors",
      credentials: "include",
      body: JSON.stringify(kyc),
    },
  ).then((res) => (res.ok ? res.json() : Promise.reject(res)));

export const downloadKyc: (kyc: IKyc) => Promise<IKyc> = (kyc) =>
  fetch(
    `${process.env.REACT_APP_BACKEND_URL}/documents/kycs/${kyc.id}/download`,
    {
      method: "GET",
      mode: "cors",
      credentials: "include",
    },
  ).then((res) => (res.ok ? res.json() : Promise.reject(res)));

export const deleteKyc: (kyc: IKyc) => Promise<void> = (kyc) =>
  fetch(`${process.env.REACT_APP_BACKEND_URL}/documents/kycs/${kyc.id}`, {
    method: "DELETE",
    headers: { "Content-Type": "application/json" },
    mode: "cors",
    credentials: "include",
  }).then((res) => (res.ok ? Promise.resolve() : Promise.reject(res)));

export const exportCsv = () =>
  fetch(`${process.env.REACT_APP_BACKEND_URL}/documents/kycs/csv`, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: { "Content-Type": "application/octet-stream" },
  }).then((res) => (res.ok ? res.arrayBuffer() : Promise.reject(res)));
