import React, { useMemo, useCallback, useState } from "react";

import {
  Upload,
  message,
  Button,
  Table,
  Popconfirm,
  Tooltip,
  Divider,
  PageHeader,
} from "antd";
// import { TableRowSelection } from "antd/lib/table/interface";
import {
  DeleteOutlined,
  PlusCircleOutlined,
  DownloadOutlined,
  UploadOutlined,
} from "@ant-design/icons";

import useDatasource from "../../hooks/useDataSource";
import useModal from "../../hooks/useModal";
import useDictionaries from "../../hooks/useDictionaries";

import {
  getBanks,
  deleteBank,
  createBank,
  IBank,
  exportCsv,
} from "../../services/banks";
import { getGroups } from "../../services/groups";
import { getCountries } from "../../services/countries";

import BankModal from "./BankModal";
import DictionaryLabel from "../DictionaryLabel";
import { currentDateAsString } from "../../utils/helpers";

const dictionaries = {
  groups: getGroups,
  countries: getCountries,
};

interface BanksListProps {}

const BanksList: React.FC<BanksListProps> = () => {
  const { loading, pagination, content, handleChange, reload } =
    useDatasource<IBank>(getBanks);

  const csvProps = {
    name: "file",
    beforeUpload: (file: any) => {
      const isCsv = file.type === "text/csv";
      if (!isCsv) {
        message.error(`${file.name} is not a csv file`);
      }
      return isCsv || Upload.LIST_IGNORE;
    },
    withCredentials: true,
    action: `${process.env.REACT_APP_BACKEND_URL}/banks/csv`,
    showUploadList: false,
    maxCount: 1,
    onChange: reload,
  };

  const handleDuplicate = useCallback(
    (bank: IBank) => {
      createBank({
        ...bank,
        id: null,
        name: "<duplicate>" + bank.name,
      }).then(() => reload());
    },
    [reload],
  );
  const [dicts] = useDictionaries(dictionaries);
  const { countries } = dicts;
  const [selectedBank, setSelectedBank] = useState<IBank | null>(null);

  const [show, modalProps] = useModal(reload);

  const columns = useMemo(
    () => [
      {
        title: "Groups",
        dataIndex: "groups",
        key: "groups",
        // render: (text: any, row: any) =>
        //   row.groups
        //     ?.split(",")
        //     .filter((v: string) => !!v)
        //     .map((id: number) => (
        //       <>
        //         <DictionaryLabel dictionary={groups} value={id} />
        //         <span> </span>
        //       </>
        //     )),
      },
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        width: 350,
      },
      // {
      //   title: "Address",
      //   dataIndex: "address",
      //   key: "address",
      // },
      {
        title: "Country",
        dataIndex: "country",
        key: "country",
        render: (value: any) => (
          <DictionaryLabel
            dictionary={countries}
            value={value}
            keyField="value"
            labelField="label"
          />
        ),
      },
      {
        title: "BIC / SWIFT",
        dataIndex: "bicSwift",
        key: "bicSwift",
        width: 250,
      },
      // {
      //   title: "Co Bank Account Name",
      //   dataIndex: "coBankAccountName",
      //   key: "coBankAccountName",
      // },
      // {
      //   title: "Co BIC / SWIFT",
      //   dataIndex: "coBicSwift",
      //   key: "coBicSwift",
      // },
      // {
      //   title: "Comment",
      //   dataIndex: "comment",
      //   key: "comment",
      // },
      {
        title: "Action",
        dataIndex: "",
        key: "x",
        width: 150,
        render: (text: any, row: any) => (
          <>
            <Tooltip placement="bottomRight" title="Duplicate">
              <Popconfirm
                placement="topLeft"
                title="Are you sure about duplicating this bank list?"
                onConfirm={() => handleDuplicate(row)}
                okText="Yes"
                cancelText="No"
              >
                <Button type="text" icon={<PlusCircleOutlined />} />
              </Popconfirm>
            </Tooltip>
            <span> </span>
            <Tooltip placement="bottomRight" title="Delete">
              <Popconfirm
                placement="topLeft"
                title="Are you sure about deleting this bank?"
                onConfirm={() =>
                  deleteBank(row).then(() => {
                    reload();
                  })
                }
                okText="Yes"
                cancelText="No"
              >
                <Button type="text" icon={<DeleteOutlined />} />
              </Popconfirm>
            </Tooltip>
          </>
        ),
      },
    ],
    [countries, handleDuplicate, reload],
  );

  // const [selectedRows, setSelectedRows] = useState<Array<React.Key>>([]);
  // const rowSelection: TableRowSelection<IBank> = {
  //   selectedRowKeys: selectedRows,
  //   onChange: (selectedRowKeys: React.Key[]) => {
  //     setSelectedRows(selectedRowKeys);
  //   },
  // };

  const handleAddBank = useCallback(() => {
    setSelectedBank({} as IBank);
    show();
  }, [show]);

  return (
    <>
      <PageHeader
        className="site-page-header-responsive"
        onBack={() => window.history.back()}
        title="Banks"
        extra={[
          <Button onClick={handleAddBank} type="primary">
            New Bank
          </Button>,
          <Upload {...csvProps}>
            <Button icon={<UploadOutlined />}>Upload CSV</Button>
          </Upload>,
          <Button
            key="2"
            icon={<DownloadOutlined />}
            onClick={() =>
              exportCsv().then((buffer) => {
                const url = window.URL.createObjectURL(new Blob([buffer]));
                const link = window.document.createElement("a");
                link.href = url;
                link.setAttribute(
                  "download",
                  `${currentDateAsString}"banks-as-csv.csv`,
                );
                window.document.body.appendChild(link);
                link.click();
                link.parentNode!.removeChild(link);
              })
            }
          >
            Download CSV
          </Button>,
        ]}
      >
        <Table
          columns={columns}
          rowKey="id"
          loading={loading}
          pagination={pagination}
          dataSource={content}
          onChange={handleChange}
          // rowSelection={rowSelection}
          // scroll={{ x: 1500 }}
          onRow={(bank: IBank) => {
            return {
              onDoubleClick: (evt) => {
                evt.preventDefault();
                setSelectedBank(bank);
                show();
              },
            };
          }}
        />
        <Divider />

        <BankModal dictionaries={dicts} {...modalProps} bank={selectedBank} />
      </PageHeader>
    </>
  );
};

export default BanksList;
