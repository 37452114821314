import { Layout } from "antd";

const NotFound = () => {
  return (
    <Layout>
      <div
        style={{ textAlign: "center", height: "100vh", paddingTop: "20rem" }}
      >
        <h1>404</h1>
        <h2>Page not found</h2>
        <span>Sorry, we can't find the page you're trying to access</span>
      </div>
    </Layout>
  );
};

export default NotFound;
