import { Button, Space, Tooltip } from "antd";
import {
  BorderOutlined,
  CheckCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";

const FourEyesValidation = ({
  row,
  onStatusChange,
  statusList,
  gridType,
}: {
  row: any;
  onStatusChange: any;
  statusList: any;
  gridType: string;
}) => {
  const { id } = row;
  const currentInvoice = statusList.find((inv: any) => inv.id === id);

  return currentInvoice ? (
    <Space size="small">
      {currentInvoice.status === 0 && (
        <Tooltip placement="bottomRight" title="Validation">
          <Button
            type="text"
            aria-label="Validation"
            onClick={() => onStatusChange(row)}
            icon={<BorderOutlined />}
          />
        </Tooltip>
      )}

      {currentInvoice.status === 1 && (
        <Tooltip placement="bottomRight" title="Exclamation">
          <Button
            type="text"
            aria-label="Exclamation"
            onClick={() => onStatusChange(row)}
            style={{ color: "rgb(255, 209, 102)" }}
            icon={<ExclamationCircleOutlined />}
          />
        </Tooltip>
      )}

      {currentInvoice.status === 2 && (
        <Tooltip placement="bottomRight" title="Action">
          <Button
            type="text"
            aria-label="Action"
            style={{ color: "rgb(6, 214, 160)" }}
            onClick={() => onStatusChange(row)}
            icon={<CheckCircleOutlined />}
          />
        </Tooltip>
      )}
    </Space>
  ) : null;
};

export default FourEyesValidation;
