import React, { useCallback, useEffect } from "react";

import { Modal, Form, Input, Row, Col, notification } from "antd";
import { ModalProps } from "../../hooks/useModal";
import { IGroup, saveGroup, createGroup } from "../../services/groups";

interface GroupModalProps extends ModalProps {
  group: IGroup | null;
}

const GroupModal: React.FC<GroupModalProps> = ({
  visible,
  onOk,
  onCancel,
  group,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(group);
  }, [form, group]);

  const handleFinish = useCallback(
    (value) => {
      const entity: IGroup = {
        ...group,
        ...value,
      };

      (entity.id ? saveGroup : createGroup)(entity)
        .then(() => {
          notification.success({ message: "Successfully saved" });

          onOk(null);
        })
        .catch(() => {
          notification.error({ message: "Something went wrong" });
        });
    },
    [group, onOk],
  );

  return (
    <Form
      onFinish={handleFinish}
      form={form}
      id="GroupModalForm"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
    >
      <Modal
        title="Group modal"
        visible={visible}
        okButtonProps={{ htmlType: "submit", form: "GroupModalForm" }}
        onCancel={onCancel}
        width={500}
      >
        <Row>
          <Col span={20}>
            <Form.Item
              rules={[{ required: true, message: "Required Field" }]}
              label="Name"
              name="name"
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Modal>
    </Form>
  );
};

export default GroupModal;
