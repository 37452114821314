import { Upload } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import React from "react";

function getBase64(img: any, callback: Function) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

type ImageUploaderProps = {
  action: string;
  fieldname: string;
  currentValue?: string;
};

type ImageUploaderState = {
  loading: boolean;
  imageUrl?: string;
};

class ImageUploader extends React.Component<
  ImageUploaderProps,
  ImageUploaderState
> {
  state = {
    loading: false,
    imageUrl: undefined,
  };

  handleChange = (info: any) => {
    if (info.file.status === "uploading") {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (imageUrl: string) =>
        this.setState({
          imageUrl,
          loading: false,
        }),
      );
    }
  };

  render() {
    const { loading, imageUrl } = this.state;
    const { action, fieldname, currentValue } = this.props;

    const uploadButton = (
      <div>
        {loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    );

    return (
      <Upload
        name={fieldname}
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        action={action}
        // beforeUpload={beforeUpload}
        onChange={this.handleChange}
      >
        {currentValue || imageUrl ? (
          <img
            src={currentValue || imageUrl}
            alt="avatar"
            style={{ width: "100%" }}
          />
        ) : (
          uploadButton
        )}
      </Upload>
    );
  }
}

export default ImageUploader;
