import React, {
  useMemo,
  useState,
  useEffect,
  useCallback,
  useContext,
} from "react";

import { Button, PageHeader, Table } from "antd";
import { TableRowSelection } from "antd/lib/table/interface";
import { DownloadOutlined } from "@ant-design/icons";

import useDatasource from "../../../hooks/useDataSource";
import useModal from "../../../hooks/useModal";

import {
  getInvoiceById,
  getInvoicesByStatus,
  IInvoice,
  processInvoice,
  exportCsv,
} from "../../../services/invoices";

import InvoiceModal from "./InvoiceModal";
import useInvoicesColumns from "./useInvoicesColumns";
import SendMailForm from "../../SendMailForm";
import AuditModal from "../AuditModal";

import { UserContext } from "../../../components/auth";
import { currentDateAsString, hasPermission } from "../../../utils/helpers";
import { DictsContext } from "../../../hooks/useDictionaries";
import { IContact } from "../../../services/contacts";
import { IEmailTemplate } from "../../../services/email-templates";

interface ValidatedInvoicesListProps {
  onAfterAction: Function;
  shouldRefresh: number;
}
// interface StatusObject {
//   id: string;
//   status: number;
// }

const ValidatedInvoicesList: React.FC<ValidatedInvoicesListProps> = ({
  onAfterAction,
  shouldRefresh,
}) => {
  const { dicts } = useContext(DictsContext);
  const { user } = useContext(UserContext);

  const { role } = user;

  const { loading, pagination, content, handleChange, reload } =
    useDatasource<IInvoice>(getInvoicesByStatus("validate"));

  useEffect(() => {
    if (shouldRefresh > 0) {
      reload();
    }
  }, [reload, shouldRefresh]);

  const { contacts, templates } = dicts;

  const [selectedInvoice, setSelectedInvoice] = useState<IInvoice | null>(null);

  const [show, modalProps] = useModal(reload);

  const [showAuditModal, auditModalProps] = useModal();
  const [auditModalParams, setAuditModalParams] = useState({
    id: null,
    documentType: null,
  });

  const [statusList, setStatusList] = useState([] as object[]);

  useEffect(() => {
    if (content.length > 0) {
      setStatusList(
        content.map((invoice: IInvoice) => ({ id: invoice.id, status: 0 })),
      );
    }
  }, [content]);

  const onStatusChange = useCallback(
    (row) => {
      const { id } = row;

      const currentInvoice = statusList.find((inv: any) => inv.id === id);

      const filteredStatusList = statusList.filter((inv: any) => inv.id !== id);

      if (!currentInvoice) {
        return;
      }
      const updatedStatusList = [
        ...filteredStatusList,
        {
          ...currentInvoice,
          // @ts-ignore
          status: currentInvoice.status !== 2 ? currentInvoice.status + 1 : 0,
        },
      ];
      setStatusList(updatedStatusList);
    },
    [statusList],
  );

  const columnProps = useMemo(
    () => ({
      dictionaries: dicts,
      onPreviewDocument: (invoice: IInvoice) =>
        window.open(`/documents/invoice/preview/${invoice.id}`),
      onAfterAction,
      gridType: "invoiceStatus.validation",
      userRightsStatus: "validation",
      fourEyesScope: "validation",
      showAuditModal,
      setAuditModalParams,
      statusList,
      onStatusChange,
    }),
    [dicts, onAfterAction, onStatusChange, showAuditModal, statusList],
  );

  const columns = useInvoicesColumns(columnProps);

  const [selectedRows, setSelectedRows] = useState<Array<React.Key>>([]);

  const rowSelection: TableRowSelection<IInvoice> = {
    selectedRowKeys: selectedRows,
    columnTitle: <>Mail</>,
    onChange: (selectedRowKeys: React.Key[]) => {
      setSelectedRows(selectedRowKeys);
    },
  };
  const handleMailSent = useCallback(() => {
    Promise.allSettled(
      selectedRows.map((id) =>
        processInvoice({ id: id as number }, "submit_validate"),
      ),
    )
      .then(() => setSelectedRows([]))
      .then(reload);
  }, [selectedRows, reload]);

  const to = contacts.content.filter((contact: IContact) => {
    const roles = contact?.scope?.split(",");
    const acceptedRoles = roles.filter(
      (roleStr: string) => roleStr === "validation",
    );
    return acceptedRoles.length > 0;
  });
  const cc = contacts.content.filter((contact: IContact) => {
    const roles = contact?.scope?.split(",");
    const acceptedRoles = roles.filter(
      (roleStr: string) => roleStr !== "execution",
    );
    return acceptedRoles.length > 0;
  });

  const filteredEmailTemplates = templates.content.filter(
    (emailTemplate: IEmailTemplate) => {
      const roles = emailTemplate?.scope?.split(",") as string[];
      const acceptedRoles = roles.filter(
        (roleStr: string) => roleStr === "validation",
      );
      return acceptedRoles.length > 0;
    },
  );

  return (
    <>
      <PageHeader
        onBack={() => window.history.back()}
        title="Invoices Validation"
        extra={[
          <Button
            key="2"
            icon={<DownloadOutlined />}
            onClick={() =>
              exportCsv().then((buffer) => {
                const url = window.URL.createObjectURL(new Blob([buffer]));
                const link = window.document.createElement("a");
                link.href = url;
                link.setAttribute(
                  "download",
                  `${currentDateAsString}"invoices-as-csv.csv`,
                );
                window.document.body.appendChild(link);
                link.click();
                link.parentNode!.removeChild(link);
              })
            }
          >
            Download CSV
          </Button>,
        ]}
      >
        {hasPermission(role, ["ADMIN", "USER", "MANAGER"]) ? (
          <SendMailForm
            type="invoice"
            scope="validate"
            documents={selectedRows}
            onMailSent={handleMailSent}
            templates={filteredEmailTemplates}
            to={to}
            cc={cc}
            labelTo="Validation"
            labelCC="Information"
          />
        ) : null}
        {hasPermission(role, ["ADMIN", "USER", "MANAGER", "OBSERVER"]) ? (
          <Table
            columns={columns}
            rowKey="id"
            loading={loading}
            pagination={pagination}
            dataSource={content}
            onChange={handleChange}
            scroll={{ x: 1800 }}
            rowSelection={rowSelection}
            onRow={(record: IInvoice) => {
              return {
                onDoubleClick: (evt) => {
                  evt.preventDefault();
                  getInvoiceById(record.id!).then((res: IInvoice) => {
                    setSelectedInvoice({
                      ...res,
                    } as unknown as IInvoice);
                    show();
                  });
                },
              };
            }}
          />
        ) : null}
      </PageHeader>

      <InvoiceModal {...modalProps} invoice={selectedInvoice} />

      {auditModalParams?.id ? (
        <AuditModal {...{ ...auditModalProps, ...auditModalParams }} />
      ) : null}
    </>
  );
};

export default ValidatedInvoicesList;
