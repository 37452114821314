/* eslint-disable react/display-name */
import React from "react";

import { filterText, filterDict, filterDate } from "./TableHelper";
import DictionaryLabel from "./DictionaryLabel";

const Column = {
  text: (key, title, { filter = false, sort = true, width = 200 } = {}) => ({
    title,
    key,
    width,
    dataIndex: key.split("."),
    sorter: sort,

    ...(filter ? filterText({ title }) : {}),
  }),

  array: (key, title, { filter = false, sort = true, width = 200 } = {}) => ({
    title,
    key,
    width,
    dataIndex: key.split("."),
    ...(filter ? filterText({ title }) : {}),
    render: (value) => <>{Array.isArray(value) ? value.join(", ") : ""}</>,
  }),

  date: (key, title, { filter = false, sort = true, width = 200 } = {}) => ({
    title,
    key,
    width,
    dataIndex: key.split("."),
    sorter: sort,
    ...(filter ? filterDate({ title }) : {}),
    render: (value) =>
      value ? new Date(parseInt(value, 10)).toLocaleDateString() : "",
  }),

  dict: (
    key,
    title,
    dictionary,
    keyField,
    labelField,
    { filter = false, sort = false, width = 200 } = {},
  ) => ({
    title,
    key,
    width,
    dataIndex: key.split("."),
    sorter: sort,
    render: (value) => (
      <DictionaryLabel
        dictionary={dictionary}
        value={value}
        keyField={keyField || "id"}
        labelField={labelField || "name"}
      />
    ),
    ...(filter
      ? filterDict({
          title,
          dictionary,
          keyField,
          labelField,
        })
      : {}),
  }),

  actions: (title, actions, { width = 100 } = {}) => ({
    title,
    key: "__actions",
    width,
    dataIndex: "__actions",
    fixed: "right",
    render: (_, record) => (
      <span style={{ textAlign: "right" }}>{actions(record)}</span>
    ),
  }),
};

export default Column;
