import React, { useEffect, useState, useCallback, useRef } from "react";

import {
  Alert,
  Button,
  Col,
  Divider,
  Pagination,
  Row,
  Select,
  Spin,
} from "antd";

// @ts-ignore
import * as pdfjsLib from "pdfjs-dist/build/pdf";
// @ts-ignore
import * as pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";

import { downloadFile, IFile } from "../services/files";
import InvoiceModal from "./documents/invoices/InvoiceModal";
import useModal from "../hooks/useModal";
import { IInvoice } from "../services/invoices";
import { IDocument } from "../services/documents";
import { ILoan } from "../services/loans";
import { IKyc } from "../services/kycs";

interface PreviewDocumentProps {
  files: Array<IFile>;
  document: IInvoice | IDocument | ILoan | IKyc;
}

export interface FileChooserProps {
  selected?: IFile;
  files: Array<IFile>;
  onSelect: (file: IFile) => void;
}

const FileChooser: React.FC<FileChooserProps> = ({
  selected,
  files,
  onSelect,
}) => {
  const handleFileSelect = useCallback(
    (value: number) => {
      const selected = files.find((file) => file.id === value);
      selected && onSelect(selected);
    },
    [files, onSelect],
  );

  return (
    <Select
      onChange={handleFileSelect}
      style={{ width: "100%" }}
      value={selected ? selected.id : undefined}
    >
      {files.map((file) => (
        <Select.Option value={file.id}>
          {file.index} - {file.fileName}
        </Select.Option>
      ))}
    </Select>
  );
};

const PreviewDocument: React.FC<PreviewDocumentProps> = ({
  files,
  document,
}) => {
  pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

  const safeguard = useRef<number>();

  const canvasRef = useRef<any>();

  const [loading, setLoading] = useState<boolean>(false);

  const [pdfRef, setPdfRef] = useState<any>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [selectedFile, setSelectedFile] = useState<IFile | undefined>(
    undefined,
  );

  const [show, modalProps] = useModal();

  useEffect(() => {
    setPdfRef(null);
    setLoading(false);
    setCurrentPage(1);

    setSelectedFile(files.length > 0 ? files[0] : undefined);
  }, [files]);

  useEffect(() => {
    setLoading(true);

    safeguard.current = Date.now() + Math.random();
    const pointintime = safeguard.current;

    const { id, fileName } = selectedFile || {};

    if (!id || !fileName || !fileName.toLowerCase().endsWith(".pdf")) {
      setPdfRef(null);
      setLoading(false);
    } else {
      downloadFile(selectedFile!)
        .then((buffer) => pdfjsLib.getDocument(buffer).promise)
        .then((pdf) => {
          if (pointintime === safeguard.current) {
            setCurrentPage(1);
            setPdfRef(pdf);
            setLoading(false);
          }
        })
        .catch(() => {
          if (pointintime === safeguard.current) {
            setPdfRef(null);
            setLoading(false);
          }
        });
    }
  }, [selectedFile]);

  const handleDownload = () => {
    if (!selectedFile) {
      return;
    }

    downloadFile(selectedFile!).then((buffer) => {
      const url = window.URL.createObjectURL(new Blob([buffer]));
      const link = window.document.createElement("a");
      link.href = url;
      link.setAttribute("download", selectedFile!.fileName);
      window.document.body.appendChild(link);
      link.click();
      link.parentNode!.removeChild(link);
    });
  };

  const handlePageChange = (value: number, _pageSize?: number) => {
    if (!pdfRef) {
      return;
    }
    const newPage = Math.max(1, Math.min(pdfRef!.numPages!, value));
    setCurrentPage(newPage);
  };

  const renderPage = useCallback(
    (pageNum, pdf = pdfRef) => {
      pdf &&
        pdf.getPage(pageNum).then((page: any) => {
          if (!canvasRef) {
            return;
          }
          const viewport = page.getViewport({ scale: 1.5 });
          const canvas = canvasRef.current;

          if (!canvas) {
            return;
          }

          canvas!.height = viewport.height;
          canvas!.width = viewport.width;
          const renderContext = {
            canvasContext: canvas.getContext("2d"),
            viewport: viewport,
          };
          page.render(renderContext);
        });
    },
    [pdfRef],
  );

  useEffect(() => {
    renderPage(currentPage, pdfRef);
  }, [pdfRef, currentPage, renderPage]);

  const handleFileSelect = useCallback((file: IFile) => {
    setSelectedFile(file);
  }, []);

  const MemoizedFileChooser = React.memo(FileChooser);

  const openModal = (evt: any) => {
    evt.preventDefault();
    show();
  };

  return (
    <>
      <Row>
        <Col span={18}>
          <MemoizedFileChooser
            selected={selectedFile}
            files={files}
            onSelect={handleFileSelect}
          />
        </Col>
        {isInvoice(document) ? (
          <>
            <Col span={2} offset={2}>
              <Button type="text" key="download" onClick={openModal} block>
                Details
              </Button>
            </Col>
            <Col span={2}>
              <Button
                type="primary"
                key="download"
                onClick={handleDownload}
                block
              >
                Download
              </Button>
            </Col>
          </>
        ) : (
          <Col span={2} offset={4}>
            <Button
              type="primary"
              key="download"
              onClick={handleDownload}
              block
            >
              Download
            </Button>
          </Col>
        )}
      </Row>

      <Divider />

      <Row>
        <Col style={{ textAlign: "center" }} span={24}>
          {loading && <Spin size="large" />}
          {!loading && (
            <>
              {pdfRef && (
                <>
                  <Pagination
                    current={currentPage}
                    total={pdfRef.numPages}
                    pageSize={1}
                    onChange={handlePageChange}
                    showSizeChanger={false}
                  />

                  <canvas ref={canvasRef}></canvas>

                  <Pagination
                    current={currentPage}
                    total={pdfRef.numPages}
                    pageSize={1}
                    onChange={handlePageChange}
                    showSizeChanger={false}
                  />
                </>
              )}
              {!pdfRef && (
                <Alert
                  message="Could not display preview"
                  description="Could not display preview for this file. Please download the file and try to open it on your device"
                  type="error"
                />
              )}
            </>
          )}
        </Col>
      </Row>
      {isInvoice(document) && (
        <InvoiceModal {...modalProps} invoice={document as IInvoice} />
      )}
    </>
  );
};

function isInvoice(obj: any): obj is IInvoice {
  return obj && "vat" in obj && "amount" in obj && "payingMode" in obj;
}

export default PreviewDocument;
