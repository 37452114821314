import React, { useMemo, useEffect, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, Space, Tooltip, Table, Divider } from "antd";
import { AuditOutlined, WarningOutlined } from "@ant-design/icons";

import { DownloadOutlined } from "@ant-design/icons";
import useModal from "../../../hooks/useModal";

import useDatasource from "../../../hooks/useDataSource";
import AuditModal from "../AuditModal";

import { IDocument, getDocumentsByStatus } from "../../../services/documents";

import DictionaryLabel from "../../DictionaryLabel";

interface GenericDocumentsListProps {
  status: string;
  dictionaries: Record<string, any>;
  shouldRefresh: number;
  onRowDblClick: Function;
  rowSelection: any;
}

const GenericDocuments: React.FC<GenericDocumentsListProps> = ({
  status,
  dictionaries,
  shouldRefresh,
  onRowDblClick,
  rowSelection,
}) => {
  const { loading, pagination, content, handleChange, reload } =
    useDatasource<IDocument>(getDocumentsByStatus(status));

  const { t } = useTranslation();

  useEffect(() => {
    if (shouldRefresh > 0) {
      reload();
    }
  }, [reload, shouldRefresh]);

  const [showAuditModal, auditModalProps] = useModal();
  const [auditModalParams, setAuditModalParams] = useState({
    id: null,
    documentType: null,
  });
  const onAudit = useCallback(
    (documentId: any) => {
      //@ts-ignore
      if (showAuditModal && setAuditModalParams) {
        //@ts-ignore
        setAuditModalParams({ id: +documentId, documentType: "document" });
        showAuditModal();
      }
    },
    [showAuditModal, setAuditModalParams],
  );

  const showAlertIcon = (value: string) => {
    if (value && value.endsWith(" alert")) {
      return (
        <>
          {/* <Tooltip title="Alert"> */}
          <WarningOutlined
            style={{
              fontSize: "16px",
              color: "#f5222d",
              marginRight: ".5rem",
            }}
          />
          {/* </Tooltip> */}
          <span>{value.substring(0, value.length - 5)}</span>
        </>
      );
    }
    return value;
  };

  const columns = useMemo(
    () => [
      {
        title: "File name",
        dataIndex: ["files", 0, "fileName"],
        key: "files.0.fileName",
        width: 70,
        render: (value: string, record: IDocument) => {
          return (
            <Tooltip title={value}>
              <Button
                type="link"
                onClick={() =>
                  window.open(`/documents/document/preview/${record.id}`)
                }
              >
                <DownloadOutlined style={{ color: "black" }} />
              </Button>
            </Tooltip>
          );
        },
      },

      {
        title: "Type",
        dataIndex: "kycType",
        key: "kycType",
        render: (value: string) => (
          <DictionaryLabel
            dictionary={dictionaries.documentTypes}
            value={value}
            keyField="value"
            labelField="label"
          />
        ),
      },
      {
        title: "Concern Entity",
        dataIndex: ["documentTo", "id"],
        key: "documentTo",
        render: (value: string) => (
          <DictionaryLabel dictionary={dictionaries.companies} value={value} />
        ),
      },
      {
        title: "Concern Third Party",
        dataIndex: ["documentFrom", "id"],
        key: "documentFrom",
        render: (value: string) => (
          <DictionaryLabel
            dictionary={dictionaries.thirdParties}
            value={value}
          />
        ),
      },

      {
        title: "Purpose",
        dataIndex: "purpose",
        key: "purpose",
      },

      // {
      //   title: "Entity acting as",
      //   dataIndex: "entityActingAs",
      //   key: "entityActingAs",
      //   render: (value: string) => (
      //     <DictionaryLabel
      //       dictionary={dictionaries.entityActingAs}
      //       value={value}
      //       keyField="value"
      //       labelField="label"
      //     />
      //   ),
      // },

      // {
      //   title: "Other Parties",
      //   dataIndex: "otherParties",
      //   key: "otherParties",
      // },

      // {
      //   title: "Other Entities",
      //   dataIndex: ["otherEntities", "id"],
      //   key: "otherEntities",
      //   render: (value: string) => (
      //     <DictionaryLabel dictionary={companies} value={value} />
      //   ),
      // },
      // {
      //   title: "Date Created",
      //   key: "dateCreated",
      //   dataIndex: "dateCreated",
      //   render: (value: any) => {
      //     return value
      //       ? new Date(parseInt(value, 10)).toLocaleDateString()
      //       : "";
      //   },
      // },
      {
        title: "Next important date",
        dataIndex: "nextImportantDate",
        key: "nextImportantDate",
        render: (value: any) => {
          return value
            ? new Date(parseInt(value, 10)).toLocaleDateString()
            : "";
        },
      },
      {
        title: "Expiry date",
        dataIndex: "expiryDate",
        key: "expiryDate",
        render: (value: any) => {
          return value
            ? new Date(parseInt(value, 10)).toLocaleDateString()
            : "";
        },
      },

      {
        title: "Countdown",
        key: "_daysToExpiry",
        dataIndex: "_daysToExpiry",
        render: (value: string) => {
          return showAlertIcon(value);
        },
      },

      // {
      //   title: "Time left",
      //   key: "_daysToNextImportantDate",
      //   dataIndex: "_daysToNextImportantDate",
      // },

      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (value: string) => <span>{t(`document.status.${value}`)}</span>,
      },

      {
        title: "Actions",
        dataIndex: "actions",
        key: "actions",
        width: 100,
        render: (value: any, record: IDocument) => {
          return (
            <>
              <Space size="small">
                <Tooltip placement="bottomRight" title="Audit">
                  <Button
                    type="text"
                    aria-label="Audit"
                    onClick={() => onAudit(record.id)}
                    icon={<AuditOutlined />}
                  />
                </Tooltip>
              </Space>
            </>
          );
        },
      },
      // {
      //   title: "Comments",
      //   dataIndex: "comments",
      //   key: "comments",
      // },
      // {
      //   title: "Tags",
      //   dataIndex: "tags",
      //   key: "tags",
      // },
    ],
    [
      dictionaries.companies,
      dictionaries.documentTypes,
      dictionaries.thirdParties,
      onAudit,
      t,
    ],
  );

  return (
    <>
      <Table
        columns={columns}
        rowKey="id"
        loading={loading}
        pagination={pagination}
        dataSource={content}
        rowSelection={rowSelection}
        onChange={handleChange}
        onRow={(document: IDocument) => {
          return {
            onDoubleClick: (evt) => {
              evt.preventDefault();
              onRowDblClick(document);
            },
          };
        }}
      />
      <Divider />
      {auditModalParams?.id ? (
        <AuditModal {...{ ...auditModalProps, ...auditModalParams }} />
      ) : null}
    </>
  );
};

export default GenericDocuments;
