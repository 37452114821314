import React, { useEffect, useState } from "react";

import { Form, Input, Row, Col, PageHeader, Button, Divider } from "antd";
import ImageUploader from "./ImageUploader";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  getTenant,
  ITenant,
  updateTenant,
} from "../../services/tenant-settings";
import useRefresh from "../../hooks/useRefresh";
import { message } from "antd";
import TenantDrive from "./TenantDrive";

interface TenantSettingsProps {}

const TenantSettings: React.FC<TenantSettingsProps> = () => {
  const [form] = Form.useForm();
  const [, refresh] = useRefresh();
  const onFinish = (values: any) => {
    try {
      updateTenant(values);
      message.success("Tenant details updated");
    } catch (error) {
      message.error("Error updating tenant details");
    }
    refresh();
  };
  const [tenant, setTenant] = useState({} as ITenant);

  useEffect(() => {
    getTenant().then((tenant) => {
      form.resetFields();
      form.setFieldsValue(tenant);
      setTenant(tenant);
    });
  }, [form]);

  return (
    <>
      <Form
        form={form}
        id="EmailTemplateModalForm"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 16 }}
        onFinish={onFinish}
      >
        <PageHeader
          className="site-page-header"
          title="Tenant Settings"
          extra={[
            <Button htmlType="submit" type="primary">
              Update Tenant Details
            </Button>,
          ]}
        />
        <Row>
          <Col span={12}>
            <Form.Item
              rules={[{ required: true, message: "Required Field" }]}
              label="Name"
              name="name"
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              label="Dashboard URL"
              name="dashboardUrl"
              labelCol={{ span: 2 }}
              wrapperCol={{ span: 20 }}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item label="Address" name="address">
              <Input />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Logo" labelCol={{ span: 2 }}>
              <ImageUploader
                action={`${process.env.REACT_APP_BACKEND_URL}/tenant-settings/logo`}
                fieldname="logo"
                currentValue={tenant.logo}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              className="tenant-settings"
              label="Header"
              labelCol={{ span: 2 }}
            >
              <ImageUploader
                action={`${process.env.REACT_APP_BACKEND_URL}/tenant-settings/header`}
                fieldname="header"
                currentValue={tenant.header}
              />
              <div style={{ marginTop: "20px" }}>600px x 150px</div>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Signature"
              name="signature"
              labelCol={{ span: 2 }}
              wrapperCol={{ span: 20 }}
            >
              <ReactQuill
                theme="snow"
                style={{ height: "15vh", marginBottom: "43px" }}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Footer"
              name="footer"
              labelCol={{ span: 2 }}
              wrapperCol={{ span: 20 }}
            >
              <ReactQuill
                theme="snow"
                style={{ height: "15vh", marginBottom: "43px" }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              labelCol={{ span: 2 }}
              wrapperCol={{ span: 20 }}
              label="CC"
              name="cc"
            >
              <Input placeholder="Please use a comma to separate multiple email addresses" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Divider />
      <TenantDrive />
    </>
  );
};

export default TenantSettings;
