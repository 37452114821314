export interface IPayingMode {
  value: string;
  label: string;
}

export const getPayingModes: () => Promise<{
  content: Array<IPayingMode>;
}> = () =>
  Promise.resolve({
    content: [
      { value: "ebanking", label: "E-Banking" },
      { value: "direct_debit", label: "Direct Debit" },
      { value: "card", label: "Card" },
      { value: "email", label: "Email" },
      { value: "other", label: "Other" },
    ],
  });
