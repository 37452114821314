import UserPassword from "../../components/admin/users/UserPassword";
import UserData from "../../components/admin/users/UserData";
import { Default } from "../../layouts";
import { Divider } from "antd";

const UserSettings = () => {
  return (
    <Default>
      <UserData />
      <Divider />
      <UserPassword />
    </Default>
  );
};

export default UserSettings;
