import React from "react";
import GroupsList from "../../components/entity/GroupsList";

import { Default } from "../../layouts";

const Groups = () => {
  return (
    <Default>
      <GroupsList />
    </Default>
  );
};

export default Groups;
