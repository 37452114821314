import React, { useMemo, useCallback, useState } from "react";

import {
  Upload,
  message,
  Button,
  Table,
  Popconfirm,
  Tooltip,
  Divider,
  PageHeader,
  notification,
} from "antd";

import {
  DeleteOutlined,
  PlusCircleOutlined,
  DownloadOutlined,
  UploadOutlined,
  AuditOutlined,
} from "@ant-design/icons";

import useDatasource from "../../hooks/useDataSource";
import useModal from "../../hooks/useModal";
import useDictionaries from "../../hooks/useDictionaries";

import {
  getThirdParties,
  deleteThirdParty,
  createThirdParty,
  getThirdPartyReport,
  IThirdParty,
  exportCsv,
} from "../../services/third-parties";
import { getGroups } from "../../services/groups";
import { getCountries } from "../../services/countries";
import { getCurrencies } from "../../services/currencies";
import { getAccountTypes } from "../../services/account-types";
import { getVatTypes } from "../../services/vat-types";

import Column from "../Column";
import ThirdPartyModal from "./ThirdPartyModal";
import DictionaryLabel from "../DictionaryLabel";
import { currentDateAsString } from "../../utils/helpers";

const dictionaries = {
  groups: getGroups,
  countries: getCountries,
  currencies: getCurrencies,
  vatTypes: getVatTypes,
  accountTypes: getAccountTypes,
};

interface ThirdPartiesListProps {}
const ThirdPartiesList: React.FC<ThirdPartiesListProps> = () => {
  const { loading, pagination, content, handleChange, reload } =
    useDatasource<IThirdParty>(getThirdParties);
  const csvProps = {
    name: "file",
    beforeUpload: (file: any) => {
      const isCsv = file.type === "text/csv";
      if (!isCsv) {
        message.error(`${file.name} is not a csv file`);
      }
      return isCsv || Upload.LIST_IGNORE;
    },
    withCredentials: true,
    action: `${process.env.REACT_APP_BACKEND_URL}/third-parties/csv`,
    showUploadList: false,
    maxCount: 1,
    onChange: reload,
  };

  const handleDuplicate = useCallback(
    (thirdParty: IThirdParty) => {
      createThirdParty({
        ...thirdParty,
        id: null,
        name: "<duplicate>" + thirdParty.name,
      }).then(() => reload());
    },
    [reload],
  );
  const [dicts] = useDictionaries(dictionaries);
  const { groups, currencies, countries } = dicts;
  const [selectedThirdParty, setSelectedThirdParty] =
    useState<IThirdParty | null>(null);

  const [show, modalProps] = useModal(reload);

  const handleReport = (thirdParty: IThirdParty) => {
    getThirdPartyReport(thirdParty)
      .then((buffer) => {
        const url = window.URL.createObjectURL(new Blob([buffer]));
        const link = window.document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${currentDateAsString}"third-parties-report-as-csv.csv`,
        );

        window.document.body.appendChild(link);
        link.click();
        link.parentNode!.removeChild(link);
      })
      .catch(() => notification.error({ message: "Something went wrong" }));
  };

  const columns = useMemo(
    () => [
      {
        title: "Group",
        dataIndex: "groups",
        key: "groups",
        width: 100,
        render: (text: any, row: any) => (
          <DictionaryLabel
            dictionary={groups}
            value={text}
            keyField="name"
            labelField="name"
          />
        ),
      },
      {
        ...Column.text("name", "Name", {
          sort: true,
          filter: true,
        }),
        render: (text: any, row: any) => text.toUpperCase(),
      },
      {
        ...Column.dict("country", "Country", countries, "value", "label", {
          filter: true,
        }),
        width: 50,
        render: (text: any, record: any, index: any) => record.country,
      },
      {
        ...Column.text("corpInfo", "Corporate Information", {
          sort: false,
          filter: true,
        }),
        width: 150,
      },
      {
        ...Column.text("iban", "IBAN", {
          sort: false,
          filter: true,
        }),
      },
      {
        ...Column.dict("currency", "Currency", currencies, "value", "label", {
          filter: true,
        }),
        width: 50,
      },

      {
        title: "Action",
        dataIndex: "",
        key: "x",
        width: 150,
        render: (text: any, row: any) => (
          <>
            <Tooltip placement="bottomRight" title="Duplicate">
              <Popconfirm
                placement="topLeft"
                title="Are you sure about duplicating this third party?"
                onConfirm={() => handleDuplicate(row)}
                okText="Yes"
                cancelText="No"
              >
                <Button type="text" icon={<PlusCircleOutlined />} />
              </Popconfirm>
            </Tooltip>
            <span> </span>

            <Tooltip placement="bottomRight" title="Report">
              <Button
                type="text"
                onClick={() => handleReport(row)}
                icon={<AuditOutlined />}
              />
            </Tooltip>

            <span> </span>
            <Tooltip placement="bottomRight" title="Delete">
              <Popconfirm
                placement="topLeft"
                title="Are you sure about deleting this third party?"
                onConfirm={() =>
                  deleteThirdParty(row).then(() => {
                    reload();
                  })
                }
                okText="Yes"
                cancelText="No"
              >
                <Button type="text" icon={<DeleteOutlined />} />
              </Popconfirm>
            </Tooltip>
          </>
        ),
      },
    ],
    [countries, currencies, groups, handleDuplicate, reload],
  );

  const handleAddThirdParty = useCallback(() => {
    setSelectedThirdParty({} as IThirdParty);
    show();
  }, [show]);

  return (
    <>
      <PageHeader
        className="site-page-header-responsive"
        onBack={() => window.history.back()}
        title="Third Parties"
        extra={[
          <Button onClick={handleAddThirdParty} type="primary">
            New Third Party
          </Button>,
          <Upload {...csvProps}>
            <Button icon={<UploadOutlined />}>Upload CSV</Button>
          </Upload>,
          <Button
            key="2"
            icon={<DownloadOutlined />}
            onClick={() =>
              exportCsv().then((buffer) => {
                const url = window.URL.createObjectURL(new Blob([buffer]));
                const link = window.document.createElement("a");
                link.href = url;
                link.setAttribute(
                  "download",
                  `${currentDateAsString}"third-parties-as-csv.csv`,
                );
                window.document.body.appendChild(link);
                link.click();
                link.parentNode!.removeChild(link);
              })
            }
          >
            Download CSV
          </Button>,
        ]}
      >
        <Table
          columns={columns}
          rowKey="id"
          loading={loading}
          pagination={pagination}
          dataSource={content}
          onChange={handleChange}
          // scroll={{ x: 1500 }}
          onRow={(thirdParty: IThirdParty) => {
            return {
              onDoubleClick: (evt) => {
                evt.preventDefault();
                setSelectedThirdParty(thirdParty);
                show();
              },
            };
          }}
        />
        <Divider />

        <ThirdPartyModal
          dictionaries={dicts}
          {...modalProps}
          thirdParty={selectedThirdParty}
        />
      </PageHeader>
    </>
  );
};

export default ThirdPartiesList;
