export interface IEntityActingAs {
  value: string;
  label: string;
}

const entityActingAs = {
  content: [
    { label: "Recipent", value: "Recipent" },
    { label: "Sender", value: "Sender" },
  ],
};

export const getEntityActingAs: () => Promise<{
  content: Array<IEntityActingAs>;
}> = () => Promise.resolve(entityActingAs);
