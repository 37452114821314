import { criteriaQuery } from "./../utils/query";

import {
  IDataSourceFetchFunc,
  IDataSourceRequest,
} from "./../hooks/useDataSource";

export interface IGroup {
  id?: number;
  name?: string;
}

export interface IFile {
  id: number;
  fileName: string;
  index?: number;
  attachable?: boolean;
}

export const getGroups: IDataSourceFetchFunc<IGroup> = (
  req: IDataSourceRequest,
) =>
  fetch(`${process.env.REACT_APP_BACKEND_URL}/groups/?${criteriaQuery(req)}`, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: { "Content-Type": "application/json" },
  }).then((res) => (res.ok ? res.json() : Promise.reject(res)));

export const createGroup: (group: IGroup) => Promise<IGroup> = (group) =>
  fetch(`${process.env.REACT_APP_BACKEND_URL}/groups/`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    mode: "cors",
    credentials: "include",
    body: JSON.stringify(group),
  }).then((res) => (res.ok ? res.json() : Promise.reject(res)));

export const saveGroup: (groups: IGroup) => Promise<IGroup> = (groups) =>
  fetch(`${process.env.REACT_APP_BACKEND_URL}/groups/${groups.id}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    mode: "cors",
    credentials: "include",
    body: JSON.stringify(groups),
  }).then((res) => (res.ok ? res.json() : Promise.reject(res)));

export const deleteGroup: (group: IGroup) => Promise<void> = (group) =>
  fetch(`${process.env.REACT_APP_BACKEND_URL}/groups/${group.id}`, {
    method: "DELETE",
    headers: { "Content-Type": "application/json" },
    mode: "cors",
    credentials: "include",
  }).then((res) => (res.ok ? Promise.resolve() : Promise.reject(res)));

export const exportCsv = () =>
  fetch(`${process.env.REACT_APP_BACKEND_URL}/groups/csv`, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: { "Content-Type": "application/octet-stream" },
  }).then((res) => (res.ok ? res.arrayBuffer() : Promise.reject(res)));
