import React from "react";
import KycsList from "../../components/documents/kycs/KycsList";

import { Default } from "../../layouts";

const KYC = () => {
  return (
    <Default>
      <KycsList />
    </Default>
  );
};

export default KYC;
